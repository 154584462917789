.ce_two-img-priceB {
  &__inner {
    margin: auto;

    .ce_single_product_name {
      margin: 0;
    }
  }

  &__single {
    display: grid;
    grid-template-areas: 'holder1 holder2'
                         'info info';
    grid-template-columns: 1fr 1fr;

    &_holder {
      line-height: 0;

      a {
        line-height: 0;
      }
    }

    &_holder1 {
      grid-area: holder1;
    }

    &_holder2 {
      grid-area: holder2;
    }

    &_info {
      grid-area: info;
      text-align: right;

      &_row {
        display: grid;
        grid-template-columns: 20px auto;
        grid-gap: 10px;
        margin: 0 auto;
        justify-content: flex-end;

        .ce_single_product_name {
          margin-right: 0;
        }
      }

      &_row2 {
        display: inline-grid;
        grid-template-columns: 20px auto;
        grid-gap: 10px;
        padding-left: 0 !important;
        line-height: 1;

        @include from($desktop) {
          display: inline-grid;
        }
        @include from($desktop-large) {
        }

        &-icon {
          display: flex;
          align-items: flex-end;

          .ic-cart {
            width: 17px;
            height: 20px;

            @include from(641px) {
              width: 20px;
              height: 23px;
            }
          }
        }
      }
    }
  }

  &__holder {
    @include video_holder;
  }

  &__player {
    @include player;
  }

}

/// @include breakpoint(xmedium) ---> min 768
@include from($tablet) {
  .ce_two-img-priceB {
    &__inner {
      margin-right: 6px;
      margin-left: 6px;
    }
  }
}

/// @include breakpoint(xmedium) ---> min 1024px
@include from($desktop) {
  .ce_two-img-priceB {
    &__inner {
      margin-right: 12px;
      margin-left: 12px;
    }

    &__single {

      &_info {
        &_row {
          .ce_single_product_name {
            margin-right: 15px;
          }
        }
      }

    }

  }
}

/// @include breakpoint(large) ---> min 1200
@include from($desktop-large) {
  .ce_two-img-priceB {

    &__single {

      &_info {
        &_row {
          .ce_single_product_name {
            margin-right: 0;
          }
        }
      }
    }

  }
}

/// @include breakpoint(xmedium) ---> min 1480px
@include from($ultra-wide-screen) {
  .ce_two-img-priceB {
    &__inner {
      margin-right: 20px;
      margin-left: 20px;
    }

    &__single {


      &_info {
        &_row {
          .ce_single_product_name {
            margin-right: 15px;
          }
        }
      }
    }

  }
}



