.ce_utm_links {
  &__wrapper {
    margin-top: 20px;
    margin-bottom: 80px;
  }

  &__container {
    margin-left: 250px;
    margin-right: 250px;
  }

  &__inner {
    table, td, tr {
      vertical-align: top;
      padding: 14px 0px !important;
    }

    a {
      text-decoration: none;
    }

    p {
      margin: 0;
    }

    .link-copied {
      background: url(/assets/workfiles/project/css/icons/SVG/ic-check.svg) no-repeat center $green !important;
    }

    ul[data-utm-list="ce_utm_links__campaign-list-links"] {
      border-bottom: 1px solid $c-lightgray;
    }

    .autocomplete-navigation-utm-drop-down {
      background-color: $c-blue !important;
      color: #fff;
    }

    .formrow-wrap {
      margin-bottom: 5px;
    }

    input[data="ce_utm_links__field-medium"],
    input[data="ce_utm_links__field-source"] {
      width: 50%;
      display: none;
      height: 40px;
      position: relative;
    }

    input[data="ce_utm_links__field-url"] {
      position: relative;
    }

    .active-tag-button {
      background-color: #000 !important;
      color: #fff !important;
    }

    .red-active {
      font-weight: 700;
      color: #000;
    }

    .tag-source,
    .tag-medium {
      display: inline-block;
      margin-right: 14px;
      border: 1px solid $c-gray;
      border-radius: 5px;
      font-family: $f-text;
      background-color: #fff;
      color: #000;
      font-size: 12px;
      margin-bottom: 14px;
      padding: 10px 12px;
    }

    .combined-shape-prefix,
    .combined-shape-url {
      display: none;
      position: absolute;
      right: 1%;
      top: 19px;
      width: 32px;
      height: 32px;
      opacity: 1;
      cursor: pointer;
    }

    .combined-shape-medium,
    .combined-shape-source {
      position: absolute;
      right: 50%;
      top: 15px;
      width: 32px;
      height: 32px;
      opacity: 1;
      display: none;
      cursor: pointer;
    }

    .combined-shape-medium:before,
    .combined-shape-medium:after,
    .combined-shape-source:before,
    .combined-shape-source:after,
    .combined-shape-prefix:before,
    .combined-shape-prefix:after,
    .combined-shape-url:before,
    .combined-shape-url:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 10px;
      width: 1px;
      background-color: #000;
    }

    .combined-shape-medium:before,
    .combined-shape-source:before,
    .combined-shape-prefix:before,
    .combined-shape-url:before {
      transform: rotate(45deg);
    }

    .combined-shape-medium:after,
    .combined-shape-source:after,
    .combined-shape-prefix:after,
    .combined-shape-url:after {
      transform: rotate(-45deg);
    }

    .autocomplete-item-link {
      border-bottom: 1px solid $c-lightgray;
      width: 100%;
      padding: 11px 0 11px 54px;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__plus-sign {
    margin-top: 3px;
    border-radius: 2px;
    background-color: $c-gray;
    color: #fff;
    padding: 6px 13px;
    display: inline-block;
    font-size: 12px;
    font-weight: 100;

    &:hover {
      color: #fff;
    }
  }

  &__campaign-item {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    background: url(/assets/workfiles/project/css/icons/SVG/arrow-activeRight.svg) no-repeat center;
    background-position-x: 100%;
    line-height: 20px;
  }

  &__single-campaign {
    display: block;
  }

  &__single-campaign-title {
    padding-bottom: 15px;
    font-size: 16px;
    font-family: $f-text;
    color: #000;
    padding-right: 27px;
  }

  &__center {
    margin: 0 auto;
  }

  &__utm-fields, &__field-campaign {
    padding: 14px 40px 14px 20px;
    width: 100%;
    border: 1px solid $c-gray;

    .autocomplete-item-link {
      width: 100%;
    }
  }

  &__utm-fields__margin {
    margin-bottom: 10px;
  }

  &__nowrap-copy-clipboard {
    text-align: right;
  }

  &__form-container {
    position: relative;

    .autocomplete-item-link {
      width: 50% !important;
      padding: 7px 0 7px 20px;
    }
  }

  &__generated-url {
    display: block;
    margin-bottom: 4px;
    font-family: $f-text;
    font-size: 12px;
    color: #000;
    line-height: 16px;
    padding-right: 15px;
  }

  &__form-container-bigger {
    position: relative;
  }

  &__generated-tag {
    display: inline-block;
    margin-right: 6px;
    margin-bottom: 4px;
    font-size: 12px;
    border: 1px solid $c-gray;
    opacity: 0.5;
    padding: 2px 12px;
    border-radius: 3px;
  }

  &__coppy-clipboard-checked {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    background: url(/assets/workfiles/project/css/icons/SVG/ic-copy.svg) no-repeat center;
    background-color: #000;
    display: inline-block;
    border-radius: 2px;
  }

  &__label-utm {
    font-family: $f-text;
    font-size: 12px;
    color: $c-gray;

    &__margin {
      margin-top: 15px;
    }
  }

  &__back {
    display: inline-block;
    margin-top: 20px;
    background-color: #000;
    color: #fff !important;
    padding: 4px 20px;
    font-size: 14px;
    font-family: $f-text;
    text-decoration: none;
    margin-bottom: 17px;
    font-weight: 100;
    border-radius: 2px;
  }

  &__label-utm-big {
    display: block;
    font-family: $f-text;
    margin-top: 44px;
    font-size: 18px;
    font-weight: 700;
  }

  &__main-heading {
    margin-bottom: 20px;
    font-size: 28px;
    font-family: $f-text;
    font-weight: 600;
    line-height: 48px;

    &__padding {
      margin-top: 50px;
      margin-bottom: 0;
    }
  }

  &__second-heading {
    font-size: 28px;
    font-family: $f-text;
    font-weight: 400;
    padding-bottom: 20px;
    border-bottom: 1px solid $c-lightgray;
    margin: 0;
  }

  &__valid-url {
    color: $red;
    font-family: $f-text;
    font-size: 12px;
    display: none;
  }

  &__button-campaign {
    padding: 14px 53px;
    background-color: #000;
    cursor: pointer;
    display: block;
    margin-top: 50px;
    margin-bottom: 25px;
    color: #fff;
    font-family: $f-krub;
    font-size: 14px;
    border: none;
    font-weight: 100;
  }

  &__button-campaign:disabled {
    opacity: 0.5;
  }

  &__field-campaign {
    width: 100%;
  }

  &__name {
    font-family: $f-text;
    font-size: 12px;
    color: $c-gray;
  }

  &__note {
    color: #3DB600;
    font-size: 14px;
    font-weight: 400;
    font-family: $f-text;
    text-align: center;
  }

  &__date {
    padding-top: 11px;
    display: block;
    font-size: 11px;
    color: $c-gray;
    font-family: $f-text;
  }

  &__red-border {
    border: 1px solid $red;
    position: relative;
  }
}

.loading-utm {
  background: url(/assets/workfiles/project/css/icons/SVG/rolling-white.svg) no-repeat 50% #000;
  background-size: 19px;
  background-position-x: 25px;
}

// @include breakpoint(xmedium) ---> max 768
@include breakpoint(medium down) {
  .ce_utm_links {
    &__wrapper {
      margin-top: 80px;
    }

    &__container {
      margin: 0;
    }
  }
}

// @include breakpoint(xmedium) ---> min 1024
@include breakpoint(xxmedium) {
  .ce_utm_links {
    &__inner {
      .tag-source,
      .tag-medium {
        &:hover {
          background-color: $c-lightgray;
          cursor: pointer;
        }
      }

      .autocomplete-items .autocomplete-item-link:hover {
        background-color: $c-lightgray;
      }
    }

    &__campaign-item {
      padding: 0 10px;
    }

    &__campaign-item:hover {
      background-color: $c-lightgray;
    }

    &__plus-sign {
      &:hover {
        cursor: pointer;
      }
    }

    &__coppy-clipboard-checked:hover {
      cursor: pointer;
      background-color: $c-lightgray;
    }

    &__campaign-item {
      background-position-x: 98.5%;
    }

    &__generated-url:hover {
      text-decoration: underline !important;
    }
  }
}


