.tabs,
.prodtabs {
	&-links {
		text-align: center;
		border-bottom: 3px solid $black;
	}
}

a[class^='tabs-trigger'],
a[class^='prodtabs-trigger'] {
	display: inline-block;
	background: $white;
	font-size: 18px;
	text-transform: uppercase; 
	border: 1px solid $c-middlegray;
	padding: 20px 94px;
	text-decoration: none;
	@include transition();
	&.active-tab, &:hover {
		background: $black;
		color: $white;
		border-color: $black;
	}
	&:hover {
		text-decoration: none;
		cursor: pointer;
	}
}

div[class^='tabs-container'],
div[class^='prodtabs-container']{
	padding: 60px 0;
}

.nav-section-tabs {
	display: flex;
	flex: 1;
	margin: 0 100px;
}

.nav-section-tabs li.tabsitem-link {
	flex: 1;
}

.nav-section-tabs li.tabsitem-link a {
	background-color: #000000;
	height: 74px;
	color: #FFFFFF;
	font-family: "Open Sans";
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	text-transform: uppercase;
	align-items: center;
	justify-content: center;
	display: flex;
	border: none;
	border-radius: 0;
	margin-right: 1px;
}

.nav-section-tabs li.tabsitem-link a img {
	max-height: 22px;
	margin-right: 10px;
}

.nav.nav-section-tabs li.tabsitem-link.active a,
.nav.nav-section-tabs li.tabsitem-link:hover a,
.nav.nav-section-tabs li.tabsitem-link.active:hover a,
.nav.nav-section-tabs li.tabsitem-link.active a:focus,
.nav.nav-section-tabs li.tabsitem-link a:focus,
.nav.nav-section-tabs li.tabsitem-link a.active-tab {
	background-color: #F43493;
	color: #FFFFFF;
	position: relative;
}

.nav.nav-section-tabs li.tabsitem-link.active a,
.nav.nav-section-tabs li.tabsitem-link a:focus {
	&:after {
		content: '';
		position: absolute;
		left: calc(50% - 7px);
		bottom: 0;
		transform: rotate(45deg);
		background-color: #F43493 !important;
		bottom: -5px;
		width: 14px;
		height: 14px;
	}
}

.tabswrap-container-headline {
	color: #000000;
	font-family: "Open Sans";
	font-size: 42px;
	font-weight: 300;
	line-height: 57px;
	text-align: center;
	margin: 40px 0 56px 0;
}

@media only screen and (max-width: 1024px) {
	.page-main ul.nav-section-tabs {
		margin: 0;
	}
}

// TABS 20 Years

.tabswrap-20-years {
	a[class^='tabs-trigger'] {
		padding:20px;
	}
	.nav-section-tabs {
		width: 80%; // Fix due to flex issue
		margin:0 auto;
		@media only screen and (max-width: 1024px) {
			width: 100%; // Fix due to flex issue
			li.tabsitem-link a {
				font-size: 16px;
			}
		}
		@media only screen and (max-width: 640px) {
			li.tabsitem-link a img {
				display: none;
			}
		}
	}
}
