.ce_two_col_image_landscape {
  &__wrapper {

  }
  &__container {

  }
  &__inner {
    margin: 0 auto;
    max-width: 355px;
  }
  &__img {
    width: 100%;
    height: auto;
    line-height: 0;
  }
  &__text {
    padding: 0;
    margin-top: 10px;
    @include col_img_txt;
    p {
      margin-top: 0;
    }
  }
  &__col {
    line-height: 0;
  }
  &__link {
    line-height: 0;
  }
}


// breakpoint(small down) ---> max 375
@include breakpoint(small down) {
  .ce_two_col_image_landscape {
    
    &__inner {
      
    }
  }
}


// breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_two_col_image_landscape {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: 936px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
    }
    &__text {
      padding: 0 10px;
    }
  }
}

// breakpoint(large) ---> min 1367
@include breakpoint(xlarge) {
  .ce_two_col_image_landscape {

    &__wrapper {
      
    }
    &__container {

    }
    &__inner {
      //max-width: 1290px;
    }

  }
}
// breakpoint(large)
@include breakpoint(wide) {

  .ce_two_col_image_landscape {
    &__wrapper {

    }
    &__container {

    }
    &__inner {
        max-width: 1850px;
    }

  }
}

// breakpoint(large)
@include breakpoint(xmedium down) {
  .ce_two_col_image_landscape {
    &__col {
      margin-bottom: 10px;
    }
  }
}