.collection-list {
	img {
		width: 100%;
		margin: 15px 0;
	}
	&-smaller {
		img {
			margin: 15px auto;
		}
	}
	&-singleitem {
		position: relative;
	}
}
.collection {
	&-singleproduct {
		&-popup {
			display: none;
			align-items: center;
			justify-content: center;
			width: 200px;
			height: 200px;
			position:absolute;
			min-height:90px;
			background:$white;
			pointer-events: none;
			border-radius: 0;
			color: $white;
			background: radial-gradient(circle,rgba(0,0,0,.3) 0,rgba(0,0,0,.1) 40%,transparent 70%);
			text-align: center;
			.product-list-name {
				font-size: 14px;		
				margin-bottom: 10px;
				max-width: inherit;
				font-weight: 600;
				color: $white;
			}
			.product-single-price {
				.icon {
					display: none;
				}
				.price {
					color: $white;
					font-size: 18px;		
				}
				.old-price {
					color: $white;
					font-size: 11px;
					margin-left: 0;
				}				
			}
			@media only screen and (max-width: map-get($breakpoints, medium) - 1) {
				display: none !important;
			}			
		}		
	}	
}
//.shopbylook {
//	&-list {
//		@extend .product-list-smaller;
//	}
//	&-single {
//		@extend .product-list-single;
//		&-image {
//			width: 100%;
//			height: auto;
//			margin-bottom: 5px;
//		}
//		&-link {
//			@include border-transform($black, 0, 1);
//		}
//		&-name {
//			display: inline-block;
//			font-size: 14px;
//			font-weight: 400;
//		}
//	}
//	&-detail {
//		@include flex-justify;
//		width: 100%;
//		&-image {
//			width: 100%;
//			@include breakpoint(xmedium) {
//				width: 47%;
//				max-width: 1000px;
//			}
//			img {
//				display: block;
//				max-width: 100%;
//				height: auto;
//			}
//		}
//		.youtube-full-holder, .video-full-holder {
//			height: 300px;
//			@include breakpoint(xmedium) {
//				background: $c-lightgray;
//				height: 100%;
//			}
//		}
//		&-content {
//			width: 100%;
//			@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
//				padding: 15px 10px;
//			}
//			@include breakpoint(xmedium) {
//				padding: 60px 30px 10px;
//				width: 53%;
//			}
//			@include breakpoint(1300px) {
//				padding: $space-large 30px 10px 100px;
//			}
//		}
//	}
//	&-product {
//		&-list-single {
//			@extend .inner-row;
//			align-items: flex-end;
//			padding-bottom: 20px;
//			margin-bottom: 20px;
//			border-bottom: 1px solid $black;
//			&:hover {
//				.add-to-wl, .ic-cart {
//					opacity: 1;
//				}
//			}
//		}
//		&-image {
//			@include breakpoint(480px) {
//				width: 30%;
//			}
//			max-width: 150px;
//			img {
//			    max-width: 100%;
//				border: 1px solid $c-lightgray;
//			}
//		}
//		&-info {
//			@include breakpoint(480px) {
//				padding-left: 20px;
//				width: 70%;
//			}
//			width:100%;
//			margin-bottom: 8px;
//			max-width: 400px;
//			.add-to-wl, .ic-cart {
//				display: inline-block;
//				vertical-align: top;
//				margin-right: 15px;
//				opacity: 0.3;
//				@include transition;
//			}
//			.ic-cart {
//				width: 16px;
//				height: 18px;
//			}
//			.ic-wishlist {
//				width: 16px;
//				height: 16px;
//				vertical-align: middle;
//			}
//			.detail-link {
//				display: inline-block;
//				margin-top: 15px;
//				@include breakpoint(xxmedium) {
//					margin-top: 30px;
//				}
//			}
//		}
//		&-name {
//			display: inline-block;
//			text-transform: uppercase;
//			font-size: 14px;
//			font-weight: 400;
//		}
//		&-price {
//			.old-price {
//			    display: inline-block;
//			    margin-left: 37px;
//			    margin-bottom: 5px;
//			    font-size: 11px;
//			}
//			.price {
//				font-size: 18px;
//				font-weight: 600;
//			}
//			a {
//				text-decoration: none;
//			}
//			line-height: 1;
//		}
//		&-description {
//			font-size: 12px;
//			margin-left: 40px;
//			margin-left: 37px;
//		}
//	}
//}