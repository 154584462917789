.wishlist-toggle {
	position: relative;
}
#wishlist-preview {
	@extend #cart-preview;
	max-height: 600px;
	overflow-y: auto;
}
.small-wishlist-table {
	@extend .small-cart-table;
	margin-bottom: 40px;
	td.wishlist-prod-img {
		width: 90px;
	}
	&-content {
	}
	.prodid-label {
		display: inline-block;
		color: $c-text;
		font-size: 12px;
		margin-left: 40px;
	}
}
.wishlist-preview-wrap {
	@include indent-content;
}

.product-list-wishlist {
	@extend .inner-row;	
	.product-list-single {
		width:98%;
		margin: 0 auto 35px;
		@include breakpoint(xmedium) {
			width: 48%;
			margin: 0 4% 65px 0;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
		@include breakpoint(xxmedium) {
			width: 31.3%;
			margin: 0 3% 65px 0;
			&:nth-child(2n) {
				margin-right: 3%;
			}
			&:nth-child(4n) {
				margin-right: 3%;
			}				
			&:nth-child(3n) {
				margin-right: 0%;
			}				
		}
		@include breakpoint(1301px) {
			width: 22.7%;
			margin: 0 3% 65px 0;
			&:nth-child(3n) {
				margin-right: 3%;
			}
			&:nth-child(4n) {
				margin-right: 0;
			}
		}
		&:hover {
			.prod-rmwishlist {
				opacity: 1;
			}
		}
	}
	.product-single-info {
		// Overriding display grid
		display: inline-block !important;
		margin-bottom: 25px;
		.ic-cart {
			opacity: 1;
		}
	}
	.old-price {
		margin-left: 0;
	}
	.product-single-price {
		@include breakpoint(medium) {
			min-height: 41px;
		}
		margin-bottom: 20px;
	}
	.product-btn-cart {
		display: none;
	}
}

.product-wishlist-img {
	width: 100%;
	height: auto;
	margin-bottom: 10px;
}

.prod-rmwishlist {
	@include breakpoint(xmedium) {
		opacity: 0;
	}
	position: absolute;
	right: 10px;
	top: 10px;
	width: 51px;
	height: 51px; 
	background: $white;
	box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
	border-radius: 100%;
	color: $c-text;
	text-align: center;
	line-height: 50px;	
	@include transition();
	.icon {
		width: 16px;
		height: 16px;
		@include inline();
	}
}

.wishlist-notifyby {
	border-bottom: 1px solid $c-border;
	.customer-notifyby-input {
		width: 160px;
		max-width: 50%;
		float: right;
		margin-left: 0;
	}
	.checkbox-wrap {
		@include clearfix();		
	}
	&-checkboxes {
		display: none;
		padding-top: 8px;
		margin: 0;
		@include breakpoint(xxmedium) {
			padding-left: 20px;			
			padding-right: 20px;			
		}
	}
	&-trigger {
		display: block;
		border-top: 1px solid $c-border;
		font-size: 12px;
		padding: 11px 20px 12px;
		position: relative;
		.icon-open {
			position: absolute;
			right: 20px;
			top: 13px;
		}
	}
}

// Button containers on wish lists spacing
.wishlist-bottom-wrapper, .wishlist-empty {
	margin-bottom: 40px;
}
