html {
	// Needed for sticky footer
    overflow-y: scroll;
    position: relative;
    min-height: 100%;
}

* {
	box-sizing: border-box;
}

.clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

/* Img-responsive class  */

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
	vertical-align: top;
	border: none;
}

//@media only screen and (min-width: map-get($breakpoints, xmedium)) {
@include from($desktop){
	.page-main-container-inner {
		padding-bottom: 50px;
		padding-top: 81px;

		.page1 & {
			padding-top: 0;
		}
	}
}
// Responsive hide and show classes
@media only screen and (max-width: map-get($breakpoints, xmedium)) {
	.hide-on-mobile {
		display: none !important;
	}
}
// Responsive hide and show classes
@media only screen and (max-width: map-get($breakpoints, xxmedium)) {
	.page-main-container-inner {
		margin-left: $mobile-margin;
		margin-right: $mobile-margin;
	}
	.page1 .page-main-container-inner {
		margin-left: 0;
		margin-right: 0;
	}
}
@media only screen and (min-width: map-get($breakpoints, xmedium) + 1) {
	.show-on-mobile {
		display: none !important;
	}
}

.page-main-container-inner {
  .product-single-full {
    margin-left: 0;
  }
}

// Margin and padding classes
.margin-none-top {
	margin-top: $space-none;
}

.margin-small-top {
	margin-top: $space-small;
}

.margin-middle-top {
	margin-top: $space-medium;
}

.margin-large-top {
	margin-top: $space-large;
}

.margin-none-right {
	margin-right: $space-none;
}

.margin-small-right {
	margin-right: $space-small;
}

.margin-middle-right {
	margin-right: $space-medium;
}

.margin-large-right {
	margin-right: $space-large;
}

.margin-none-bottom {
	margin-bottom: $space-none;
}

.margin-small-bottom {
	margin-bottom: $space-small;
}

.margin-middle-bottom {
	margin-bottom: $space-medium;
}

.margin-large-bottom {
	margin-bottom: $space-large;
}

.margin-none-left {
	margin-left: $space-none !important;
}

.margin-small-left {
	margin-left: $space-small;
}

.margin-middle-left {
	margin-left: $space-medium;
}

.margin-large-left {
	margin-left: $space-large;
}

.margin-xlarge-bottom {
	margin-bottom: $space-xlarge;
}

.margin-xlarge-top {
	margin-top: $space-xlarge;
}

.padding-none-top {
	padding-top: $space-none;
}

.padding-small-top {
	padding-top: $space-small;
}

.padding-middle-top {
	padding-top: $space-medium;
}

.padding-large-top {
	padding-top: $space-large;
}

.padding-none-right {
	padding-right: $space-none;
}

.padding-small-right {
	padding-right: $space-small;
}

.padding-middle-right {
	padding-right: $space-medium;
}

.padding-large-right {
	padding-right: $space-large;
}

.padding-none-bottom {
	padding-bottom: $space-none;
}

.padding-small-bottom {
	padding-bottom: $space-small;
}

.padding-middle-bottom {
	padding-bottom: $space-medium;
}

.padding-large-bottom {
	padding-bottom: $space-large;
}

.padding-none-left {
	padding-left: $space-none;
}

.padding-small-left {
	padding-left: $space-small;
}

.padding-middle-left {
	padding-left: $space-medium;
}

.padding-large-left {
	padding-left: $space-large;
}

/* Text alignment classes */
.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-justify {
	text-align: justify;
}

.cl {
	display: block;
	clear: both;
	overflow: hidden;
	height:0px;
}

.text-sub {
	font-size: 0.825em;
	color: $c-gray;
	a {
		color: $c-gray;
	}
}
.checkout--password-reset-text {
	padding-left:5px;
}