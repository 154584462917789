.accordion {
	&-wrap {
		border-top: 1px solid $product-detail-border;
		margin-bottom: 25px;
	}
	&-container {
		display: none;
		border-bottom: 1px solid $c-gray;
		padding: 10px 0 20px;
		&.active {
			display: block;
		}
	}
	&-trigger {
      display: block;
      background: url('/assets/workfiles/project/css/icons/SVG/plus-toggle.svg') no-repeat center right;
      background-size: 15px;
      border-bottom: 1px solid $product-detail-border;
      padding: 10px 0;
      text-decoration: none;
      color: $black;
      font-size: 12px;
      font-weight: bold;

      &.active {
          background: url('/assets/workfiles/project/css/icons/SVG/minus-toggle.svg') no-repeat center right;
          background-size: 15px 1px;
          border-bottom: 0px;
      }
      &:hover {
			text-decoration: none;
			cursor: pointer;
		}
	}
	&-label {
		display: inline-block;
		width: 190px;
		margin-right: 20px;
	}
}
@include breakpoint(medium down) {
	.accordion {
		&-wrap {
			margin-top: 20px;
		}
	}
}
