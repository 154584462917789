.ce_gallery__wrapper {
  margin: 100px 20px;
  @include breakpoint(large up) {
    margin: 100px 60px;
  }
}


.ce_gallery__inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include breakpoint(medium up) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include breakpoint(large up) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include breakpoint(xxlarge up) {
    grid-template-columns: repeat(6, 1fr);
  }
  grid-gap: 20px;
}
