.parallax-item {
	@extend .img-full-holder; 
	background-color: $white !important;
}

.parallax-overalap {
	position: relative;
	&-text {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: $overlay-z-index - 1;		
		text-align: center;
		opacity: 0;
		@include transition();
		&.active {
			opacity: 1;
		}
	}
}

.image-frame {
	display: table;
	margin: 0 auto;
	background-color:$c-lightgray;
	padding: 40px;
	margin-bottom: 30px;	
	@extend .img-bckg-holder;
	&-img {
		margin-top: 500px;
		@include transition();
	}
	&.bckg-red {
		background-color:$psred;
	}
	&.bckg-black {
		background-color:$black;
	}
	&.bckg-white {
		background-color:$white;
	}
	&.bckg-purple {
		background-color:$purple;		
	}
	&.bckg-brown {
		background-color:$brown;		
	}
	&.bckg-pink {
		background-color:#F1008C;		
	}
}

.parallax-text {
	position: absolute;
	right: 10%;
	top: 30%;
	transition: linear 0.3s;
	&-wrapper {
		@extend .parallax-item;
		position: relative;
		overflow: hidden;
	}
}

.parallax-text-bottom {
	position: absolute;
	right: 10%;
	bottom: 70%;
	transition: linear 0.3s;
	&-wrapper {
		@extend .parallax-item;
		position: relative;
		overflow: hidden;
	}
}

.parallax-fadein {
	opacity: 0;
	margin-top: 100px;
	@extend .img-bckg-holder;
	@include transition();
	&-lr {
		@extend .img-bckg-holder;
		opacity: 0;	
		transition: linear 0.3s;
	}
	&-left {
		margin-left: -100%;
	}
	&-right {
		margin-right: -100%;		
	}
}

.parallax-movetop {
	margin-top: 150px;
	@include transition();
	@include xy-cell($fixwidth:true);
	@extend .img-bckg-holder;
	&-wrapper {
		@include flex;
		@extend .indent-content;
	}
}

.stars-container {
	@extend .img-bckg-holder;
}