.ce_teaser_boxes {

  &__wrapper {

  }

  &__container {

  }

  &__inner {
    & > div:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__one {
    text-align: center;
    margin: 0 auto;

    .ce_teaser_boxes__box {
      display: inline-block;
    }

    .ce_teaser_boxes__short_text {
      width: 70%;
    }

    &_box__wrapper {
      text-align: center;
      line-height: 1;
    }

    & > div:not(:last-child) {
      margin-bottom: 30px;
    }

  }

  &__two {
    display: grid;
    grid-template-columns: 375px;
    max-width: 375px;
    grid-gap: 30px;
    margin: auto;

    .ce_teaser_boxes__short_text {
      width: 60%;
    }
  }

  &__three {
    display: grid;
    grid-template-columns: 375px;
    max-width: 375px;
    grid-gap: 30px;
    margin: auto;
  }

  &__short_text {
    text-align: center;
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font: 28px/32px $f-krub;
    color: #F5F5F5;
    font-weight: 300;
    transition: opacity 0.4s;
  }

  &__box {
    position: relative;
    line-height: 0;
    overflow: hidden;
  }

  &__frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 280px;
    height: 280px;
    opacity: 0.5;
    transition: width 0.4s, height 0.4s, opacity 0.36s;

    &_layer {
      background-color: #000000;
      padding: 15px;
      background-color: hsla(0, 0%, 0%, 0.8);
      background: hsla(0, 0%, 0%, 0.8);
      height: 100%;
    }

    &_layer1 {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-top: 0;
      overflow: visible;
      font-size: 100%;
      height: 100%;
    }

  }


}

// breakpoint(small down) ---> max 480
@include breakpoint(xxsmall down) {

  .ce_teaser_boxes {

    &__wrapper {

    }

    &__container {

    }

    &__inner {

    }

    &__one {
      .ce_teaser_boxes__frame {
        &.landscape-img {
          height: 70% !important;
        }
      }
    }

    &__two {
      width: 100%;
      grid-template-columns: 1fr;
    }

    &__three {
      width: 100%;
      grid-template-columns: 1fr;
    }

    &__box {

      img {
        width: 100%;
        height: auto;
      }
    }

    &__frame {
      width: 90%;
      height: 60%;
    }

  }

}


/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {

  .ce_teaser_boxes {
    &__inner {
      & > div:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    &__one {
      & > div:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    &__two {
      grid-template-columns: 460px 460px;
      grid-gap: 40px;
      max-width: 960px;
    }

    &__three {
      grid-template-columns: 293px 293px 293px;
      grid-gap: 40px;
      max-width: 960px;
    }

    &__frame {
      position: absolute;
      top: 50%;
      left: 50%;
    }


    &__box {
      &:hover {
        .ce_teaser_boxes__frame {
          opacity: 0.5; // Change opacity on hover element
        }
      }
    }

  }
}


/// @include breakpoint(xxmedium) ---> min 1024
@include breakpoint(xxmedium) {
  .ce_teaser_boxes {

    &__wrapper {

    }

    &__container {

    }

    &__inner {

    }

    &__one {
      & > div:not(:last-child) {
        margin-bottom: 40px;
      }

      .ce_teaser_boxes__box {
        display: block;
        max-width: 460px;
        margin: 0 auto;

        &--image-height {
          padding-top: 690px;
        }

        &:hover {
          .ce_teaser_boxes__frame {
            width: 300px;
            height: 300px;
          }
        }
      }

      &_box__wrapper-small {
        .ce_teaser_boxes__box {
          max-width: 346px;

          &--image-height {
            padding-top: 519px;
          }
        }
      }

    }

    &__two {

    }

    &__three {
      .ce_teaser_boxes__box:hover {
        .ce_teaser_boxes__frame {
          width: 250px;
          height: 250px;
        }
      }
    }

    &__short_text {

      &.landscape-img {
        width: 40%;
      }
    }

    &__box {

      &--image {
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
        transition: transform 0.4s cubic-bezier(.165, .84, .44, 1);

        &-position {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow: hidden;

          &-inner {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
          }
        }

        &-height {
          padding-top: 150%;
        }
      }

      &:hover {
        .ce_teaser_boxes__frame {
          width: 300px;
          height: 300px;
        }

        .ce_teaser_boxes__box--image {
          transform: scale(1.03);
        }
      }
    }

    &__frame {
      width: 280px !important;
      height: 280px !important;
      opacity: 0.3;
    }

  }
}


/// @include breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_teaser_boxes {

    &__wrapper {

    }

    &__container {

    }

    &__inner {
      & > div:not(:last-child) {
        margin-bottom: 60px;
      }
    }

    &__one {
      .ce_teaser_boxes__box {
        max-width: 600px;

        &--image-height {
          padding-top: 900px;
        }

      }

      &_box__wrapper-small {
        .ce_teaser_boxes__box {
          max-width: 380px !important;

          &--image-height {
            padding-top: 570px;
          }
        }
      }

      & > div:not(:last-child) {
        margin-bottom: 60px;
      }

      .ce_teaser_boxes__short_text {
        width: 50%;
      }
    }

    &__two {
      grid-template-columns: 600px 600px;
      grid-gap: 60px;
      max-width: 1260px;

      .ce_teaser_boxes__short_text {
        width: 50%;
      }
    }

    &__three {
      grid-template-columns: 380px 380px 380px;
      grid-gap: 60px;
      max-width: 1260px;

      .ce_teaser_boxes__box:hover {
        .ce_teaser_boxes__frame {
          width: 300px;
          height: 300px;
        }
      }
    }

    &__short_text {
      opacity: 0;
    }

    &__box {
      .ce_teaser_boxes__frame {
        width: 300px;
        height: 300px;
        opacity: 0.3; // Initial opacity of teaser box frame on screen size over 1200
      }

      .ce_teaser_boxes__short_text {
        opacity: 1; // Initial opacity of teaser box text on screen size over 1200
      }

    }
  }
}