/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden; -webkit-transform: translate3d(0,0,0);}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:rgba(0, 0, 0, 0.8);}	
#colorbox{outline:0;}
#cboxContent{
	margin-bottom:20px; 
	padding: 0 25px;
	background:transparent;
	@include breakpoint(medium) {
		padding:0 45px; 		
	}
}
.cboxIframe{background:$white;}
#cboxError{padding:50px; border:1px solid $c-middlegray;}
#cboxLoadedContent{ 
	background:$white; 
	padding:30px 10px 40px;
	@include breakpoint(medium) {
		padding:40px 25px 40px;
	}
}
.cboxPhoto {border:1px solid $c-middlegray;}
#cboxTitle{position:absolute; bottom:15px; left:70px; color:$black; font-size: 12px; font-weight: 600;}
#cboxCurrent{
	position:absolute; 
	bottom:15px; 
	right: 40px;
	@include breakpoint(medium) {
		right:70px; 
	}
	color:$black; 
	font-size: 12px; 
	font-weight: 600;
}
#cboxLoadingGraphic{background:url(images/loading.gif) no-repeat center center;}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

#cboxSlideshow{position:absolute; top:-20px; right:90px; color:$white;}
#cboxPrevious{position:absolute; top:50%; left:0; margin-top:-20px; text-indent:-9999px;}
#cboxNext{position:absolute; top:50%; right:0; margin-top:-20px; text-indent:-9999px;}

#cboxPrevious, #cboxNext {	
	width: 22px;
	height: 40px;
	@include breakpoint(medium) {
	    width: 40px;
	    height: 40px;
		border: 1px solid $white;
	}
    background-color: transparent;
	@include arrow-icon($white);	
    border-radius: 5px;
}
#cboxPrevious {
	transform: rotate(-90deg);
}

#cboxClose{
	position:absolute; 
	right: 35px;
	top: 5px;
	@include breakpoint(medium) {
		top:10px; 
		right:60px; 
	}
	display:block; background: url('/assets/workfiles/project/css/icons/SVG/close.svg') no-repeat center; background-size: 12px; width:20px; height:20px; text-indent:-9999px;}
