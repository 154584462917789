.ce_two_col_image_landscape_button {
  &_portrait__wrapper {
    margin: 25px 0;
  }

  &__inner {
    margin: 0 auto;
    max-width: 355px;
  }

  &__img {
    width: 100%;
    height: auto;
    line-height: 0;
    transition: all 0.5s;
  }

  &__text {
    @include col_img_txt;
    padding: 0;
    margin-top: 10px;

    p {
      margin-top: 0;
    }
  }

  &__col {
    // Overflow content (headlines) is hidden
    overflow: hidden;
    line-height: 0;
    position: relative;
    transition: all 0.5s;
  }

  &__link {
    line-height: 0;
  }

  // When checkbox is selected color headlines
  &_headline-dark {
    color: $black !important;
  }

  &_headline-dark2 {
    color: $black !important;
  }

  // Headline container -> defines positioning of headline elements
  &_headline-wrapper {
    position: absolute;
    width: 100%;
    bottom: 45%;
    text-align: center;
  }

  // Class of  choosen headlines in backend
  &_headline--large {
    @include new_h1($family: $font-headline);
    line-height: 85% !important;
    color: $black;
    @include breakpoint(xxxxlarge down) {
      @include font($size: 45px,
      $line: 65px);
    }
    @include breakpoint(small down) {
      @include font($size: 35px,
      $line: 65px);
    }
  }

  &_headline--medium {
    @include new_h2($family: $font-headline);
    line-height: 85% !important;
    color: $black;
    @include breakpoint(xxxxlarge down) {
      @include font($size: 35px,
      $line: 65px);
    }
    @include breakpoint(small down) {
      @include font($size: 30px,
      $line: 65px);
    }
  }

  &_headline--small {
    @include new_h3($family: $font-headline);
    line-height: 85% !important;
    color: $black;
    @include breakpoint(xxxxlarge down) {
      @include font($size: 25px,
      $line: 65px);
    }
  }

  // When checkbox is selected headline becomes white
  &_headline-white {
    color: $white;
  }

  // Defines the edges of headlines, goes to the 90% of container width
  &_headline {
    width: 90%;
  }

  // Button positioning
  &-cta-wrapper {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
  }

  // Button CTA black -> initial
  &-cta {
    padding: 20px 30px !important;
    border: 1px solid $black;
    // breakpoint(xmedium) ---> min 768
    @include breakpoint(medium down) {
      padding: 15px 25px !important;
      font-size: 12px;
    }
  }

  // Button CTA white
  &-cta-white {
    border: 1px solid $white !important;
    color: $white !important;
    @include breakpoint(medium down) {
      color: $black !important;
      background-color: $white !important;
    }
  }
}

// breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_two_col_image_landscape_button {
    &__inner {
      max-width: 1610px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
    }

    &__text {
      padding: 0 10px;
    }

    &__col {
      transition: all 0.5s;
      // Hidden overflow  when zoom in picture
      overflow: hidden;

      &:hover {
        // Change CTA background and text
        .ce_two_col_image_landscape_button-cta {
          background: $black;
          color: $white;
        }

        .ce_two_col_image_landscape_button-cta-white {
          background: $white;
          color: $black !important;
        }

        // Zoom in img on hover column
        .ce_two_col_image_landscape_button__img {
          transform: scale(1.03);
        }
      }
    }
  }
}

// breakpoint(xxmedium) ---> min 1024
@include breakpoint(xxmedium) {
  .ce_two_col_image_landscape_button {
    &__inner {
      padding: 0 10px;
    }
  }
}

// breakpoint(wide) ---> min 1919
@include breakpoint(wide) {
  .ce_two_col_image_landscape_button {
    &__inner {
      max-width: 1850px;
    }
  }
}

// breakpoint(xmedium) ---> max 769
@include breakpoint(xmedium down) {
  .ce_two_col_image_landscape_button {
    &__col {
      margin-bottom: 10px;
    }
  }
}

// breakpoint(medium) ---> max 641
@include breakpoint(medium down) {
  // Change CTA background and text
  .ce_two_col_image_landscape_button__col .ce_two_col_image_landscape_button-cta {
    background: $black;
    color: $white;
  }
}