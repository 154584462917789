.ce_landscape_mapping {
	&__wrapper {
		@include mobile_mapping;
	}
	&__inner {
		max-width: 375px;
		margin: auto;
	}
	&__singleitem {
		&_desktop {
			display: none;
		}
		&_tablet {
			display: none;
		}
		&_mobile {
			display: block;
		}
	}
}


/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
	.ce_landscape_mapping {

		&__wrapper {

		}
		&__container {

		}
		&__inner {
			max-width: 1024px;
		}
		&__singleitem {
			&_desktop {
				display: none;
			}
			&_tablet {
				display: block;
			}
			&_mobile {
				display: none;
			}
		}
	}
}


/// @include breakpoint(large) ---> min 1200
@include breakpoint(xxmedium) {
	.ce_landscape_mapping {

		&__wrapper {

		}
		&__container {

		}
		&__inner {
			max-width: 1080px;
		}
		&__singleitem {
			&_desktop {
				display: block;
			}
			&_tablet {
				display: none;
			}
			&_mobile {
				display: none;
			}
		}
	}
}

.mappedimage-ce-singleitem-popup {
  .product-single-info-popup {
    padding: 15px;
    background-color: rgba(255, 255, 255, .8);
    border-radius: 4px;

    .product-single-price {
      flex-direction: column;

      .product-single-name {
        color: $black;
      }

      .price {
        color: $black;
      }

      .old-price {
        color: $black;
      }
    }
  }
}


