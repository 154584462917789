.checkout-data {
  &-entry-wrap {
    //margin-left: 10px;
    //margin-right: 10px;
    max-width: 950px;
    margin: 80px auto;
    padding: 0 10px;
    @include breakpoint(xmedium) {
      margin: 80px auto;
      padding: 0;
    }
    @include breakpoint(xlarge) {
      margin: 80px auto;
      padding: 0;
    }
  }

  &-entry {
    margin: 0 !important;
    justify-content: space-between;
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;

    &:before, &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 5px;
    }

    &:before {
      border-bottom: 1px solid $c-middlegray;
      border-top: 1px solid $c-middlegray;
      left: 0;
      top: 0;
    }

    &:after {
      border-bottom: 1px solid $c-middlegray;
      bottom: 0;
      left: 0;
    }

    @include breakpoint(medium) {
      .button-wrap {
        text-align: right;
      }
    }
  }

  &-h {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 25px;
  }
}

label[for=paymenttype],
label[for=shippingtype] {
  @include breakpoint(990px) {
    min-width: 110px;
    vertical-align: middle;
  }
}

.hide-wrap {
  display: none;
}

.payment_shipment_wrapper {
  position: relative;
  @include breakpoint(xmedium) {
    text-align: right;
  }
}

// Adding position sticky for right column cart form
.payment_shipment_container {
  position: sticky;
  top: 10px;
  @include breakpoint(xmedium) {
    display: inline-block;
    text-align: left;
  }

  .alert {
    padding: 3px;
  }

  // Expand textarea over full width
  textarea {
    width: 100%;
  }
}

.cart-overview {
  &-description {
    margin: 0;
    justify-content: space-between;
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 5px;
      border-bottom: 1px solid $c-middlegray;
      border-top: 1px solid $c-middlegray;
      left: 0;
      top: 0;
    }

    h3 {
      font-weight: 600;
      @include breakpoint(xmedium) {
        margin-bottom: 25px;
      }
    }
  }

  .cart-table .cart-increase-decrease-quantity {
    margin-top: 5px;
  }
}

.cart-validate {
  @extend .inner-row;
  justify-content: space-between;
  padding: 25px 0;
  position: relative;
  @include breakpoint(medium) {
    .button-wrap {
      text-align: right;
    }
  }

  h3 {
    font-weight: 600;
    @include breakpoint(xmedium) {
      margin-bottom: 25px;
    }
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 5px;
    border-bottom: 1px solid $c-middlegray;
    bottom: 0;
    left: 0;
  }
}

.cart-validate-paymentdata,
.cart-validate-invoicedata,
.cart-validate-shippingdata {
  @include breakpoint(large) {
    width: 400px;
    max-width: 100%;
  }
  margin-bottom: 35px;

  td, th {
    text-align: left;
    font-size: 13px;
    vertical-align: top;
    padding: 2px 8px;
  }

  td {
    font-weight: 600;
  }

  td:first-child {
    padding-left: 0;
    font-weight: 400;
  }
}

.send-order-description {
  @include breakpoint(large) {
    width: 400px;
  }
  max-width: 100%;
}

.cart-validate-headline {
  font-weight: 600;
  @include breakpoint(xmedium) {
    margin-bottom: 25px;
  }
}

/*** INVOICE - DEPOSIT SLIP ***/
.uplatnica {
  background: url("/assets/img/project/bckg/uplatnica@2x.jpg") no-repeat;
  background-size: 670px 303px;
  font-size: 12px;
  width: 670px;
  height: 310px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.uplatnica-left {
  float: left;
  width: 270px;
  margin: 45px 0 0 30px;
}

.uplatnica-uplatilac {
  height: 46px;
}

.uplatnica-svrha {
  height: 46px;
  margin-top: 20px;
}

.uplatnica-primalac {
  height: 46px;
  margin-top: 20px;
}

.uplatnica-right {
  float: right;
  width: 285px;
  margin: 50px 10px 0 0;
}

.uplatnica-sifra {
  float: left;
  height: 20px;
  width: 40px;
  margin-right: 15px;
}

.uplatnica-valuta {
  float: left;
  height: 20px;
  width: 40px;
  margin-right: 20px;
  text-transform: uppercase;
}

.uplatnica-iznos {
  float: left;
  height: 20px;
  width: 165px;
  text-align: right;
}

.uplatnica-racun {
  height: 36px;
  margin-top: 40px;
}

.uplatnica-model {
  float: left;
  margin-top: 7px;
  height: 20px;
  width: 40px;
  margin-right: 15px;
}

.uplatnica-poziv {
  float: left;
  margin-top: 7px;
  height: 20px;
  width: 230px;
}

.uplatnica-desktop,
.uplatnica-tablet {
  display: none;
}

@include breakpoint(930px) {
  .uplatnica-desktop {
    display: block;
  }
}

@media only screen and (max-width: 930px) {
  .uplatnica-tablet {
    display: block;
  }
}

.formrow-wrap--checkout-password,
.formrow-wrap--checkout-registration {
  margin-top:-28px; // Move block up
}

.checkout-asguest {
  font-weight: 700;
  position: relative;
  text-underline-offset: 6px;

  &:after {
    content: ' ';
    position: absolute;
    right: -30px;
    width: 20px;
    height: 20px;
    background: url('/assets/workfiles/project/css/icons/SVG/arrow-cart.svg') no-repeat center;
    background-size: 100%;
    top: -1px;
  }
}
