
.ce_teaser_discount {
  &__wrapper {
    background: $greybackground;
    @include intend-fullwidth;
  }
  &__container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &__inner {
    //max-width: 374px;
    margin: auto;
    @media only screen and (max-width: map-get($breakpoints, xxmedium)) {
      margin-left: $mobile-margin;
      margin-right: $mobile-margin;
    }
  }
  &__discount {
    color: #000000;
    font-family: $font-headline;
    font-size: 40px;
    font-weight: bold;
    line-height: 46px;
    margin-bottom: 10px;
    position: relative;
    &_number {
      border-bottom: 4px solid #000000;
      position: absolute;
      bottom: 0px;
      transition: $animation;
    }
  }
  &__leadtext {
    color: #000000;
    font-family: $f-krub;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
  }
  &__readmore {
    color: #000000;
    font-family: $f-krub;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
  }
}


// breakpoint(small down) ---> max 375
@include breakpoint(small down){
  .ce_teaser_discount {
    &__discount {
      font-size: 32px;
      line-height: 38px;
    }
  }
}

/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_teaser_discount {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: 615px;

    }
    &__link_holder {
      align-self: center;
      text-align: right;
    }
    &__leadtext {
      margin-bottom: 0;
    }

  }
}


/// @include breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_teaser_discount {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      display: grid;
      grid-template-columns: 1fr 230px;
      grid-gap: 20px;
    }

  }
}