.quote-text-wrap {
	width: 100%;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
.quote-text {
	position: relative;
	padding: 0 60px;
	margin-bottom: 20px;
}
.quotes-left, .quotes-right {
	@extend .icon;
	position: absolute;
	color: $black;
}
.quotes-left {
	top: 0;
	left: 0;
}
.quotes-right {
	bottom: 15px;
	right: 0;
	transform: rotate(180deg);
}

.text-cta {
	width: 100%;
	max-width: 500px;
}
.text-wrap {
	margin-bottom: 20px;
}

.text-cta-right {
	@extend .text-cta;
	text-align: right;
}

.text-cta-center {
	@extend .text-cta;
	text-align: center;
}

.text-headline {
	max-width: 500px;
	&.full {
		max-width: 100%;
	}	
}

.quote-headline  {
	max-width: 580px;
	h1 {
		margin-bottom: 15px;
	}
	&.full {
		display: inline-block;
		max-width: 100%;
	}
	position: relative;
	padding: 0 40px;
	.quotes-left {
		top: 10px;
	}
}

.text-line-left {
	max-width: 550px;
	.text-wrap {
		padding-left: 25px;
	}
}

.line-left {
	border-left: 3px solid $black;
	padding-left: 25px;
	h1 {
		margin-bottom: 15px;
	}
}

.text-header-line {
	max-width: 550px;
	h1 {
		margin-bottom: 15px;
	}
}
.hor-line-left {
	@include breakpoint(medium) {
		padding-left: 130px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 10px;
			width: 87px;
			border-bottom: 3px solid $black;
		}
	}
	.btn {
		margin-top: 20px;
	}
}

.text-image-left {
	max-width: 1024px;
	@extend .inner-row;	
	margin-left: auto;
	margin-right: auto;
	justify-content:center;
	.ce-h, .text-wrap {
		margin-bottom: 25px;
	}
}
.text-image-right {
	@extend .text-image-left;
}

.text-image-left-img {
	@include breakpoint(medium) {
		@include xy-cell(5);
	} 
}
.text-image-right-img {
	@extend .text-image-left-img;
	@include breakpoint(medium) {
		order: 2;
	}
}

.text-image-left-text {
	@include breakpoint(medium) {
		@include xy-cell(7);
	} 
}

.text-image-right-text{
	@extend .text-image-left-text;
	@extend .text-right;
	@include breakpoint(medium) {
		order: 1;
	}	
}

.text-image-above {
	.ce-h, .text-wrap {
		margin-bottom: 20px;
	}
	&.text-center {
		margin-left: auto;
		margin-right: auto;
		img {
			margin-left: auto;
			margin-right: auto;
		}
		.text-image-above-text {
			margin: 0 auto;
		}
	}
}

.text-image-above-img {
	margin-bottom: 15px;
}

.image-box-hover-wrap {
	width: 400px;
	max-width: 100%;
	height: 300px;
	overflow: hidden;
}
.image-box-hover {
	width: 400px;
	max-width: 100%;
	height: 300px;
	background-color: $c-lightgray;
	background-size: cover;
	background-position: center center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transform: scale(1,1);
    @include transition(0.4s);
	&:hover {
	    transform: scale(1.2,1.2);
		.image-box-text {
			opacity: 1;		
		}		
	}
	@include breakpoint(xmedium down) {
		transform: scale(1.2,1.2);
		.image-box-text {
			opacity: 1 !important;
		}
		a {
			text-decoration: none;
		}
	}
}

.image-box-text {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	opacity: 0;
	@include transition;
	transform: scale(0.8);
	position: relative;
	text-align: center;
	width: 240px;
	height: 230px;
	color: $white;
	padding: 15px 20px 40px;
	border-radius: 8px;
	text-align: center;
	line-height: 1.5;
	.ce-h, h1, h2, h3, h4, h5, h6 {
		color: $white;	
		margin-bottom: 0;
	}
	p a {
		color: $white;	
	}
	&.bckg-black {
		background: rgba(0,0,0,0.8);
	}
	&.bckg-red {
		background: rgba(202, 92, 87, 0.8);
	}
	&.bckg-brown {
		background: rgba(146, 73, 55, 0.8);
	}
	&.bckg-pink {
		background: rgba(241, 0, 140, 0.8)
	}
	.btn-arrow-white {
		@include horizontal-center-content;
		bottom: -13px;		
		cursor: pointer;
	}
}

.image-box-portrait-wrap {
	width: 400px;
	max-width: 100%;
	height: 580px;
	overflow: hidden;
}

.image-box-hover-portrait {
	@extend .image-box-hover;
	height: 580px;
	.image-box-text-portrait {
		@extend .image-box-text;
		width: 340px;
		height: 330px;
		padding: 15px 30px 40px;
	}
}

.image-box-hover-right {
	@extend .image-box-hover;
	align-items: flex-end;
	text-align: left;
}

.image-box-text-right {
	@extend .image-box-text;
	text-align: left;
	align-items: start;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	.btn-arrow-transp {
		margin: 20px 0 0;
		cursor: pointer;
	}	
}

.action-banner {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: $overlay-z-index;
	text-align: center;
	a {
		text-decoration: none;		
	}
	&.bckg-red {
		background: $psred;
	}
	&.bckg-black {
		background: $black;
	}
	&.bckg-white {
		background: $white;
	}
	&.bckg-purple {
		background: $purple;
	}
	&.bckg-brown {
		background: $brown;
	}
	&.bckg-pink {
		background: $action-pink;
	}
	&.bckg-gray {
		background: $action-gray;
	}
	&:hover {
		&.bckg-red {
			background: lighten($psred, 10%);
		}
		&.bckg-black {
			background: lighten($black, 10%);
		}
		&.bckg-white {
			background: darken($white, 10%);
		}
		&.bckg-purple {
			background: lighten($purple, 10%);
		}
		&.bckg-brown {
			background: lighten($brown, 10%);
		}
		&.bckg-pink {
			background: lighten($action-pink, 10%);
		}
		&.bckg-gray {
			background: lighten($action-gray, 10%);
		}
	}
}
.action-banner-link {
	display: block;
	width: 100%;
	font-size: 14px;
	padding: 10px;
	text-decoration: none;
	&:visited,
	&:hover,
	&:active {
		text-decoration: none;
		&.white-text {
			color: $white;
		}
		&.black-text {
			color: $black;
		}
		&.red-text {
			color: $red;
		}
		&.purple-text {
			color: $purple;
		}
		&.brown-text {
			color: $brown;
		}
		&.pink-text {
			color: $action-pink;
		}
		&.brown-gray {
			color: $action-gray;
		}
	
	}
}
