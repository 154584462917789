.page1 {
	margin: 0 !important;
	padding: 0;
	.page-footer, .page-header {
		display: none;
	}
	.page-main {
		padding: 0;
	}
}
.homepage-intro {
	@extend .img-full-holder;
	height: 100vh !important;
	padding: 40px;
	@include breakpoint(xxxlarge) {
		padding: 250px 0 0 250px;
	}

    margin: 0 0 -50px 0 !important;

	&.dark-image a {
		color: $white;
		&:hover {
			border-bottom: 2px solid $white;
		}
	}
	h3 {
		position: relative;
		z-index: 10;
	}
	ul {
		position: relative;
		z-index: 10;
		list-style-type: none;
		padding-left: 0;
		display: inline-block;
		margin-top: 40px;

		li {
			padding: 5px 0;
			a {
				text-decoration: none;
				text-transform: uppercase;
				padding: 5px 0;
				@include transition();
				&:hover {
					font-weight: 600;
					border-bottom: 2px solid $black;
				}
			}
		}
	}
}
.hp-logo {
	@extend .img-responsive;
	margin-left: -13px;
    margin-bottom: 30px;
	max-width: 50%;
	position: relative;
	z-index: 100;
}