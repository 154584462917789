.newsletter-teaser{
	&-landscape {
		.formrow-wrap {
			margin: 0;
		}
		@extend .inner-row;
		// Positioning

		// Display & Box Model
		border: 5px solid $light-gray;
		padding: 15px 15px 40px 15px;
		max-width: 930px;
		margin: 40px auto;
		background-image: url(/assets/img/project/bckg/nl-large-2.png) ,url(/assets/img/project/bckg/nl-large-1.png),url(/assets/img/project/bckg/nl-large-3.png);
		background-repeat: no-repeat;
		background-position: left bottom, right bottom, right top;
		background-size: 15%, 21.5%, 50%;
		@include breakpoint(medium) {
			background-position: left bottom, right bottom, 80% top;
			background-size: 15%, 21.5%, 30%;
		}
		@include breakpoint(xxmedium) {
			background-position: left bottom, right bottom, 82% top;
			background-size: 7%, 21.5%, 20%;
		}
		// Color

		// Text

		// Other
		@include breakpoint(medium) {
			border: 5px solid $light-gray;
			padding: 25px 0px 45px 40px;
		}
		@include breakpoint(xxmedium) {
			padding: 35px 70px 45px 50px;
		}
		@include breakpoint(large) {
			padding-left: 80px;
		}
		&-description {
			@include breakpoint(xxmedium) {
				@include xy-cell(5);
			}
		}

	}
	&-title {
		// Positioning

		// Display & Box Model
		margin: 0;

		// Color

		// Text
		font-size: 28px;
		line-height: 40px;
		font-family: $f-special;
		color: $black;

		// Other
		span {
			display: block;
			font-size: 40px;
		}
	}

	&-text {
		// Positioning

		// Display & Box Model
		margin-top: 5px;
		// Color
		color: $black;

		// Text
		font-size: 12px;
		line-height: 18px;

		// Other
	}
	&-form-wrap {
		@include xy-cell(12, $gutters: 0);
		// Positioning

		// Display & Box Model
		display: inline-block;


		// Color

		// Text

		// Other
		.newsletter-email {
			// Positioning

			// Display & Box Model
			height: 50px;
			width: 100%;

			// Color

			// Text
			font-size: 26px;
			font-weight: 300;

			// Other
			&::placeholder {
				font-style: italic;
				font-size: 26px;
				font-weight: 300;
			}
			@include breakpoint(xmedium) {
				width: 400px;
			}
			@include breakpoint(xxmedium) {
				// Positioning

				// Display & Box Model
				padding-left: 30px;

				// Color

				// Text
				font-size: 28px;

				// Other
			}
		}

		.btn {
			// Positioning

			// Display & Box Model
			width: auto;
			margin-top: 0;
			// Color

			// Text

			// Other
			cursor: pointer;
		}
		@include breakpoint(xxmedium) {
			@include xy-cell(7);
			padding-left: 30px;
			margin-top: 30px;
		}
		@include breakpoint(large) {
			padding-left: 70px;
		}
	}
	&-form-desc {
		// Positioning

		// Display & Box Model
		margin: 20px 0 0 0 ;
		// Color

		// Text
		font-size: 10px;
		line-height: 20px;
		text-transform: uppercase;

	}
	&-portrait,
	&-popup {
		background-image: url(/assets/img/project/bckg/nl-small-1.png), url(/assets/img/project/bckg/nl-small-2.png);
		background-repeat: no-repeat;
		background-position: left bottom, right top;
		.formrow-wrap {
			margin: 0;
		}
		br {
			display: none;
		}
	}
	// PORTRAIT
	&-portrait {

		@extend .inner-row;

		// Positioning

		// Display & Box Model
		display: inline-block;
		padding: 20px 20px 70px;
		border: 5px solid $light-gray;
		width: 100%;
		max-width: 340px;
		margin: 0 auto;
		background-size: 30%, 40%;
		// Color

		// Text

		// Other
		&-form-wrap {
			width: 100%;
			margin-top: 20px;
			.newsletter-email {
				width: 100%;
				max-width: 245px;
				font-weight: 300;
				font-size: 20px;
				&::placeholder {
					font-style: italic;
					font-size: 20px;
				}
			}
		}
		.btn {
			width: auto;
			cursor: pointer;
		}
		&-description {
			width: 100%;
		}
		@include breakpoint(xxmedium) {
			padding: 35px 20px 65px 40px;
		}
	}
	&-popup {

		// Positioning

		// Display & Box Model
		display: block;
		@include breakpoint(xmedium) {
			float: left;
			padding-left: 50px;
		}
		padding: 25px 30px 32px 30px;
		width: 100%;
		@include breakpoint(xmedium) {
			width: 440px;
		}
		background-size: 15%, 30%;

		// Color

		// Text

		// Other
		
		&-wrapper {
			// Positioning

			// Display & Box Model
			display: block;
			margin-bottom: 20px;
			border-bottom: 2px solid $c-darkgray;
			@include breakpoint(xmedium) {
				margin-bottom: 0;
				display: inline-block;
				border-bottom: 0px;
			}
			background-color: $c-lightgray;
			// Color

			// Text

			// Other
			@include clearfix();
		}
		&-image {
			display: none;
			float: left;
			@include breakpoint(xmedium) {
				display: block;
			}
		}
		&-form-wrap {
			width: 100%;
			margin-top: 20px;
			.newsletter-email {
				// Positioning

				// Display & Box Model
				height: 50px;
				width: 100%;
				max-width: 330px;

				// Color

				// Text
				font-weight: 300;
				font-size: 14px;
				line-height: 17px;

				// Other
				&::placeholder {
					font-style: normal;
				}
			}
		}
		.btn {
			width: auto;
			cursor: pointer;
		}
		.newsletter-teaser-text {
			display: inline-block;
			max-width: 310px;
		}
	}
	&-landscape {
		.customer-notifyby {
			margin-bottom: 0;
			margin-top: 15px;
			@media only screen and (max-width: map-get($breakpoints, xxmedium) - 1) {
				margin-bottom: 20px;
			}
			@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
				margin-bottom: 0;
			}
		}
		.customer-notifyby-input {
			@include breakpoint(1150px) {
				width: 120px;
				padding-right: 20px;
			}
		}
		.checkbox-label {
			margin-right: 8px;
		}
	}
	&-portrait {
		.customer-notifyby {
			margin-bottom: 0;
			&-input {
				width: 245px;
			}
		}
	}

}
.newsletter-toggle {
	text-decoration: none;
	display: block;
	float: right;
	margin-top: 0;
	@include breakpoint(xmedium) {
		float: none;
		@include inline;
		margin-top: 0;
	}
	.icon-open {
		margin-right: 15px;
		margin-bottom: 2px;
	}
	&:hover {
		text-decoration: none;
		cursor: pointer;
		color: $white;
	}
	@media screen and (max-width: 1100px) and (min-width: 768px) {
		//margin-bottom: 15px;
	}
}
.newsletter-footer {
	display: none;
	@include breakpoint(xmedium) {
		position: absolute;
		right: 0;
		bottom: $footer-closed-tablet;
		height: 360px;
		overflow: hidden;	
		z-index: 9;
		.footer-open & {
			bottom: $footer-height-tablet;
		}		
	}
	@include breakpoint(900px) {
		bottom: $footer-closed;
		.footer-open & {
			bottom: $footer-height;
		}
	}
}