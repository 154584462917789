.ce_text_default_decor {

  &__wrapper {
    @include txt-ce-margin;
  }
  &__container {

  }
  &__inner {
    max-width: 375px;
    margin: auto;
  }
  &__content {
    padding-top: 1px;
    @include new_default;
    h1 {
      @include new_h1 ($family: $font-headline);
      @include  headline-decor ($width: 132px, $top: -25px);
      margin: 15px auto;
      margin-top: 80px;
    }
    h2 {
      @include new_h2 ($family: $font-headline);
      @include  headline-decor ($width: 80px, $top: -15px);
      margin: 15px auto;
      margin-top: 60px;
    }
    h3 {
      @include new_h3 ($family: $font-headline);
      @include  headline-decor ($width: 80px, $top: -15px);
      margin: 15px auto;
      margin-top: 60px;
    }
    a {
      @include font($size: 14px,
      $weight: bold,
      $color: #000000,
      $line: 18px);
    }
  }

}

// @include breakpoint(xxmedium) --> max 360
@include breakpoint(small down) {
  .ce_text_default_decor {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: unset;
    }
    &__content {

    }

  }
}

/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_text_default_decor {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: $txt-maxwidth-tablet;
    }
    &__content {

    }

  }
}

/// @include breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_text_default_decor {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: $txt-maxwidth-desktop;
    }
    &__content {

    }

  }
}



