.ce_image_discount_cities {

  &__wrapper {

  }
  &__container {

  }
  &__inner {

  }
  &__headline {
    position: relative;
    padding-top: 30px;
    grid-area: headline;
    @include font($family: $font-headline,
                  $weight: 700,
                  $color: #000000,
                  $size: 48px,
                  $line: 46px);
  }
  &__content {
    @include bodytext;
    @include font($size: 16px, $line: 26px);
    margin-bottom: 30px;
  }
  &__readmore {
    width: 88px;
    @include readmore;
  }
  &__info {
    &_holder {
      grid-area: info;
      position: relative;
    }
    &_border {
      position: absolute;
      top: 0;
      left: 0;
      height: 4px;
      width: 117px;
      background-color: #000000;
      transition: all 0.7s ease-in-out;
    }
  }
  &__cities {
    position: relative;
    @include bodytext;
    @include font($size: 12px, $line: 20px);
    padding-left: 30px;
    &_border {
      position: absolute;
      top: 37px;
      bottom: 2px;
      left: 0;
      width: 1px;
      background-color: #D8D8D8;
    }
    &_map {
      position: absolute;
      top: -3px;
      left: -8px;
      width: 18px;
      height: 25px;
      background: url(/assets/img/project/ic-map-new.svg) no-repeat;
    }
    &_holder {
      margin-top: 40px;
      padding-left: 10px;
    }
  }
  &__image_holder {
    text-align: right;
    margin: 40px 0 0 0;
    padding-right: 0px;
    img {
      width: 100%;
      height: auto;
    }
  }

}


/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_image_discount_cities {

    &__container {
      margin-left: 250px;
      margin-right: 10px;
    }
    &__headline {
      margin-bottom: 0;
      margin: 0 20px 0 0;
      font-size: 60px;
      line-height: 74px;
    }
    &__cities {
      &_holder {
        margin: 0 20px 0 10px;
        padding-left: 0;
      }
    }
    &__info {
      &_holder {
        margin: 0 20px 0 0;
        padding-top: 0px;
      }
    }
    &__image_holder {
      padding-right: 20px;
    }

  }
}

/// @include breakpoint(large) ---> min 1024
@media only screen and (min-width: map-get($breakpoints, xxmedium))  {

  .ce_image_discount_cities {
    &__headline {
      font-size: 70px;
      line-height: 74px;
    }
  }
}


