@import "./constants";
/* --------------------------------- MIXINS --------------------------------- */

// Foundation Mixins

/// Iterates through breakpoints defined in `$breakpoint-classes` and prints the CSS inside the mixin at each breakpoint's media query. Use this with the grid, or any other component that has responsive classes.
///
/// @param {Boolean} $small [true] - If `false`, the mixin will skip the `small` breakpoint. Use this with components that don't prefix classes with `small-`, only `medium-` and up.
/// @param {Boolean} $auto-insert-breakpoints [true] - If `false`, the mixin will iterate over breakpoints without doing the media query itself. Useful for more complex media query generation as in the margin grid.
@mixin -zf-each-breakpoint($small: true, $auto-insert-breakpoints: true) {
	$list: $breakpoint-classes;

	@if not $small {
		$list: sl-remove($list, $-zf-zero-breakpoint);
	}

	@each $name in $list {
		$-zf-size: $name !global;

		@if $auto-insert-breakpoints {
			@include breakpoint($name) {
				@content;
			}
		} @else {
			@content;
		}
	}
}

/// Generate the `@content` passed to the mixin with a value `$-zf-bp-value` related to a breakpoint, depending on the `$name` parameter:
/// - For a single value, `$-zf-bp-value` is this value.
/// - For a breakpoint name, `$-zf-bp-value` is the corresponding breakpoint value in `$map`.
/// - For "auto", `$-zf-bp-value` is the corresponding breakpoint value in `$map` and is passed to `@content`, which is made responsive for each breakpoint of `$map`.
/// @param {Number|Array|Keyword} $name [auto] - Single value, breakpoint name, or list of breakpoint names to use. "auto" by default.
/// @param {Number|Map} $map - Map of breakpoints and values or single value to use.
@mixin -zf-breakpoint-value(
	$name: auto,
	$map: null
) {
	@if $name == auto and type-of($map) == 'map' {
		// "auto"
		@each $k, $v in $map {
			@include breakpoint($k) {
				@include -zf-breakpoint-value($v, $map) {
					@content;
				}
			}
		}
	} @else {
		// breakpoint name
		@if type-of($name) == 'string' {
			$name: -zf-get-bp-val($map, $name);
		}

		// breakpoint value
		$-zf-bp-value: $name !global;
		@content;
	}
}

// Mixins - Typography

@mixin h1 {
    margin: 0;
    color: $c-headlines;;
    @include breakpoint(xmedium) {
	    margin-bottom: 40px;
		font-size: 42px;
	}
	font-size: 35px;
	margin-bottom: 25px;
    font-weight: 300;
    line-height: 1.2;
}

@mixin h2 {
    margin: 0;
    @include breakpoint(xmedium) {
	    margin-bottom: 15px;
		font-size: 28px;
    }
    font-size:25px;
    margin-bottom: 25px;
    color: $c-headlines;
    font-weight: 300;
    line-height: 1.2;
}

@mixin h3 {
    margin: 0;
    margin-bottom: 15px;
    color: $c-headlines;
    @include breakpoint(xmedium) {
	    font-size: 22px;
	}
	font-size:18px;
    font-weight: 300;
    line-height: 1.2;
}

@mixin h4 {
    margin: 0;
    margin-bottom: 15px;
    color: $c-headlines;
    @include breakpoint(xmedium) {
	    font-size: 20px;
	}
	font-size:16px;
    font-weight: 400;
    line-height: 1.2;
}

@mixin h5 {
    margin: 0;
    margin-bottom: 15px;
	color: $c-headlines;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
}

@mixin h6 {
    margin: 0;
    margin-bottom: 15px;
    color: $c-headlines;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
}

/* --------------------------------- MIXINS --------------------------------- */
@mixin inline($align: middle) {
	display: inline-block;
	vertical-align: $align;	
}
@mixin flex-justify {
	display: flex;
	justify-content:space-between;
	flex-direction:row;
	flex-flow: row wrap;		
}
// wrapers for centered content
@mixin absolute-center-content {
	transform:translate(-50%,-50%);
	position:absolute;
	top:50%;
	left:50%;
}

@mixin vertical-center-content {
	transform:translateY(-50%);
	position:absolute;
	top:50%;
}

@mixin horizontal-center-content {
	transform:translateX(-50%);
	position:absolute;
	left:50%;
}

@mixin transition($speed: 0.3s) {
	transition: all $speed ease;
	backface-visibility: hidden;
}

@mixin clearfix {
	 &:before,
	 &:after {
	    content: " "; /* 1 */
	    display: table; /* 2 */
	 }
	 
	 &:after {
		clear: both;
	 }
}
@mixin indent-content {
	@include breakpoint(xmedium) {
		margin-left: $headernav-width; //header width
	}
	//margin-left: 10px;
	//margin-right: 10px;
	//@extend .margin-middle-bottom;
	.indent-content & {
		//margin-left: 0;
		//margin-right: 0;
	}
}
@mixin intend-fullwidth {
	@media only screen and (max-width: map-get($breakpoints, xxmedium)) {
		margin-left: -$mobile-margin;
		margin-right: -$mobile-margin;
	}
}
@mixin triangle-right($color) {
	content: '';
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: -10px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background: $color;
}
@mixin triangle-left($color) {
	content: '';
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: -10px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background: $color;
}
@mixin border-transform($color, $scale, $hoverscale) {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: -8px;
		left: 0;
		width: 100%;
		height: 1px;	
		border-bottom: 1px solid $color;
		transform: scaleX($scale);  
		transition: transform 150ms ease-in-out;
	}
	&:hover {
		&:after {
			transform: scaleX($hoverscale);
		}
	}	
}
// SVG icons mixins
@function encodecolor($string) {
	@if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string:unquote("#{$hex}");
    }
    $string: '%23' + $string;
	@return $string;
}

//Arrows icon mixin used for slider and lightbox
@mixin arrow-icon($icon-color) {
	background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8"%3F%3E%3Csvg width="20px" height="11px" viewBox="0 0 20 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"%3E%3C!-- Generator: Sketch 48.2 %2847327%29 - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Earrow-20%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"%3E%3Cg id="arrow-20" fill="' + encodecolor($icon-color) + '" fill-rule="nonzero"%3E%3Cpolygon id="Rectangle-9" transform="translate%289.814971, 9.915476%29 rotate%28-315.000000%29 translate%28-9.814971, -9.915476%29 " points="4.08400077 4.18450583 4.08400077 16.6464466 3.08400077 16.6464466 3.08400077 3.18450583 16.5459415 3.18450583 16.5459415 4.18450583"%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
	background-size: 20px 11px;	
	transform: rotate(90deg);
	background-position: center 12px;
	background-repeat: no-repeat;
}

@mixin line-after($width: 100px, $background: $black) {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom:0;
		left: 0;
		width: $width;
		max-width: 100%;
		height: 3px;
		background: $background;
	}
}
@mixin line-before($width: 100px, $background: $black) {
	position: relative;
	padding-top: 20px;
	&:after {
		content: '';
		position: absolute;
		top:0;
		left: 0;
		width: $width;
		max-width: 100%;
		height: 4px;
		background: $background;
	}
}


//**********************************************************************
// Media queries mixins
//**********************************************************************

// Media queries
@mixin until($width) {
  // until ($tablet), exception for mobile query
  @if ($width == 768) {
    @media (max-width: $width - 1) {
      @content;
    }
  }
  @if ($width != 768) {
    @media (max-width: $width) {
      @content;
    }
  }
}

@mixin from($width) {
  // from ($tablet), exception for tablet query
  @if ($width == 768) {
    @media (min-width: $width) {
      @content;
    }
  }
  @if ($width != 768) {
    @media (min-width: $width + 1) {
      @content;
    }
  }
}

@mixin between($from, $to) {
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}

// from 768px to 1024px
@mixin tablet() {
  @include between($tablet, $desktop) {
    @content;
  }
}

// from 1025px to 1200px
@mixin desktop() {
  @include between($desktop + 1, $desktop-large) {
    @content;
  }
}

// from 1201px to 1366px
@mixin desktopLarge() {
  @include between($desktop-large + 1, $wide-screen) {
    @content;
  }
}

// from 1367px
@mixin widescreen() {
  @include between($wide-screen + 1, $ultra-wide-screen) {
    @content;
  }
}

// from 1440px
@mixin ultraWidescreen() {
  @include from($ultra-wide-screen) {
    @content;
  }
}