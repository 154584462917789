.ce_two_images1 {
  &__wrapper {

  }
  &__container {

  }
  &__inner {
    position: relative;
    width: 375px;
    height: 350px;
    margin: auto;
  }
  &__link {

  }
  &__img {
    &_large {

      &_holder {
        width: 210px;
        height: 290px;
        position: absolute;
        left: -20px;
        top: 25px;
        opacity: 0;
        transition: $animation;
      }
    }
    &_small {

      &_holder {
        position: absolute;
        top: 25px;
        right: 0;
        width: 157px;
        height: 217px;
        opacity: 0;
        transition: $animation;
      }
    }
  }

}


// breakpoint(small down) ---> max 480
@include breakpoint(xxsmall down) {

  .ce_two_images1 {
    &__inner {
      width: 100%;
      max-width: 370px;
    }
    &__img {
      &_large {
        width: 100%;
        height: auto;
        &_holder {
          max-width: 210px;
          width: 56%;
          transition: $animationMobile;
        }
      }
      &_small {
        width: 100%;
        height: auto;
        &_holder {
          max-width: 157px;
          width: 42%;
          transition: $animationMobile;
        }
      }
    }
  }
}


// breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_two_images1 {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      width: 863px;
      height: 720px;
    }
    &__link {

    }
    &__img {
      &_large {

        &_holder {
          width: 480px;
          height: 668px;
        }
      }
      &_small {

        &_holder {
          right: 5px;
          width: 360px;
          height: 500px;
        }
      }
    }

  }
}

// breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_two_images1 {

    &__wrapper {
      height: 900px;
    }
    &__container {

    }
    &__inner {
      position: relative;
      width: 1079px;
      height: 900px;
      margin: auto;
    }
    &__link {

    }
    &__img {
      &_large {

        &_holder {
          width: 602px;
          height: 835px;
        }
      }
      &_small {

        &_holder {
          top: 80px;
          right: 9px;
          width: 450px;
          height: 625px;
        }
      }
    }

  }
}