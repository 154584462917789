.inner-row {
	@include xy-grid();
	@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
		@include xy-grid-layout($n: 1, $selector: '*', $gutter-position: top bottom, $vertical: true);
	}
	.grid-container-padded & {
		@include xy-gutters($negative: true); // Need if there's grid-container-padded above
	}	
}

@for $maxCol from 1 through 5 {
	@for $i from 1 through $maxCol {
		.c-#{$maxCol}-#{$i}-inner {
			@include xy-cell();
			@include breakpoint(xmedium) {
				@if $maxCol < 5 {
					@include xy-cell(12/$maxCol);
				} @else {
					@include xy-cell(auto);
				}
			}
			.fixed-column-width & {
				@include xy-cell($fixwidth:true);
				@include breakpoint(xmedium) {
					@if $maxCol < 5 {
						@include xy-cell(12/$maxCol, $fixwidth:true);
					} @else {
						@include xy-cell(auto, $fixwidth:true);
					}
				}
			}
		}
	}
}

.inner-one-col,
.inner-two-col,
.inner-three-col,
.inner-four-col,
.inner-five-col {
	@extend .inner-row;	
	@include breakpoint(xmedium) {
		margin-left: $headernav-width; //header width
	}
	margin-left: 10px;
	margin-right: 10px;
	.indent-content, 
	.product-list, 
	.blog-list,
	.inner-one-col,
	.inner-two-col,
	.inner-three-col,
	.inner-four-col,
	.inner-five-col {
		margin-left: 0;
		margin-right: 0;
	}
	.indent-content & {
		margin-left: 0;
		margin-right: 0;
	}
	.center-content & {
		justify-content: center;
	}
	.strech-content & {
		justify-content: space-around;
	}
}

.inner-two-col-right {
	@extend .inner-row;
	@include breakpoint(xmedium) {
		margin-left: $headernav-width; //header width
	}
	margin-left: 10px;
	margin-right: 10px;
	justify-content: space-around;
	.center-content & {
		justify-content: center;
	}
	.indent-content, 
	.product-list, 
	.blog-list,
	.inner-one-col,
	.inner-two-col,
	.inner-three-col,
	.inner-four-col,
	.inner-five-col {
		margin-left: 0;
		margin-right: 0;
	}
	.two-col-right-main,
	.two-col-right-side  {
		width: 100%;
	}
	@include breakpoint(large) {
		.two-col-right-main  {
			width: 73%;
		}
		.two-col-right-side  {
			width: 25%;
		}
	}
}

.narrow-wrapper {
	@extend .inner-row;
	@include until($desktop) {
		margin-top: $top-margin !important;
	}
	@include breakpoint(xmedium) {
		margin-left: $headernav-width; //header width + 100px
	}
	@include breakpoint(xxmedium) {
		max-width: 60%;
	}
	@include breakpoint(large) {
		margin-left: 350px;
		max-width: 50%;
	}
	margin-left: 10px;
	margin-right: 10px;
	
	.indent-content, 
	.product-list, 
	.blog-list,
	.inner-one-col,
	.inner-two-col,
	.inner-three-col,
	.inner-four-col,
	.inner-five-col {
		margin-left: 0;
		margin-right: 0;
	}
	&-inner {
		flex: 0 0 auto;
		max-width: 100%;
		.fixed-column-width & {
			width: 100%;
		}
	}
}

.inner-one-col-indent {
	@extend .inner-one-col;	
	@include breakpoint(large) {
		margin-left: 350px;
	}
}