.login-page {
	@extend .inner-row;

	@include until($desktop) {
		margin-top: $top-margin !important;
	}
	@include breakpoint(xmedium) {
		margin-left: 250px;
	}
	@media only screen and (max-width: map-get($breakpoints, xxmedium)) {
		margin-left: -$mobile-margin;
		margin-right: -$mobile-margin;
	}
}
.login-form-wrapper {
	width: 100%;
	padding: 0 20px;
	@include breakpoint(xxmedium) {
		width: 49%;
		padding-right: 40px;
	}
	@include breakpoint(1700px) {
		width: 40%;
	}
	.btn {
		display: inline-block;
	}
	.forgot-password-link {
		@include inline(bottom);
		font-size: 14px;
		color: $c-text;
		margin-top: 10px;
		@include breakpoint(375px) {
			margin-left: 15px;
		}
// 		@include breakpoint(xxmedium) {
			float: right;
// 		}
	}
}
.login-form-h {
	margin-bottom: 35px;
}
.login-button-wrap {
	max-width: 400px;
}
.login-create-acc {
	width: 100%;
	padding: 30px 15px 50px;
	background: $c-lightgray;
	@include breakpoint(xmedium) {
		padding-left: 30px;
		margin-top: 50px;
	}
	@include breakpoint(xxmedium) {
		width: 49%;
		margin-top: 0;
	}
	@include breakpoint(xxlarge) {
		padding: 70px 10px 70px 100px;
	}
	@include breakpoint(1700px) {
		width: 60%;
	}
	&-inner {
		@include breakpoint(xxlarge) {
			max-width: 70%;
		}
	}
	&-h {
		font-size: 42px;
	}
	&-text {
		margin-bottom: 40px;
		a {
			color: $black;
			font-weight: bold;
		}
	}
	.login-create-acc-button {
		font-size: 14px;
		text-transform: uppercase;
		padding: 15px 40px;
	}
}

.felogin-message {
	margin-bottom: 15px;
}