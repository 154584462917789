#store-id {
	display: none;
}


.storemap {
	width: 100%;
	margin-bottom: 20px;
	// check mainbottom.js file
	// Footer height is calculated dynamically, but added here as static value
	height: calc(100vh - 52px);
	@include breakpoint(medium) {
		//margin-bottom: 97px;
	}
	@include breakpoint(xxmedium down) {
		margin-bottom: 10px;
	}
}

.store-locations {
	@media only screen and (max-width: map-get($breakpoints, xxmedium)) {
		margin-left: -$mobile-margin;
		margin-right: -$mobile-margin;
	}
	&-list {
		@extend .inner-row;
		justify-content: center;		
		max-width: 1280px;
		margin: 0 auto;
		//.store-locations-item {
		//	@include xy-cell(6, $fixwidth:true);
		//	margin-bottom: 10px;
		//	text-align: center;
		//	@include breakpoint(xmedium) {
		//		@include xy-cell(4, $fixwidth:true);
		//	}
		//	@include breakpoint(xxmedium) {
		//		@include xy-cell(3, $fixwidth:true);
		//	}
		//	@include breakpoint(1280px) {
		//		margin-bottom: 50px;
		//	}
		//	.store-locations-link {
		//		display: inline-block;
		//		text-align: left;
		//	}
		//}
	}
	&-loyalty {
		position: relative;
		.store-locations-list {
			@include breakpoint(xmedium) {
				margin-left: 240px;
			}
			@include breakpoint(large) {
				margin-left: 330px;
			}
		}
		#store-filter {
			position: relative;
			background: none;
			padding-top: 40px;
			h1 {
				display: none;
			}
		}
	}
	&-loyalty-text {
		position: absolute;
		top: 0;
		left: 0;

		@include breakpoint(xmedium) {
			padding: 50px 100px 0;
		}
		width: 100%;
		background: linear-gradient(0deg,hsla(0,0%,100%,0.1),hsla(0,0%,100%,.76));
		padding: 100px 10px 0;
		z-index: $overlay-z-index - 1;
		pointer-events: none;
		text-align: center;
		h1 {
			margin-bottom: 10px;
		}
	}
	&-name {
		font-size: 14px;
		font-weight: 600;
		line-height: 1.5;
		max-width: 250px;
	}
	&-address {
		font-weight: 300;
		color: $c-gray;
	}
	&-link {
		text-decoration: none;
	}
	&-filter {
		width: 100%;
		//h1 {
		//	@include breakpoint(xmedium) {
		//		display: none;
		//	}
		//}
		//@include breakpoint(xmedium) {
		position: absolute;
		top:0;
		//}
		text-align: center;
		background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.76) 100%);
		padding-top: 100px;
		z-index: $overlay-z-index - 1;
		pointer-events: none;
		@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
			h1 {
				display: none;
			}
		}
	}
	#location-country, #location-city {
		pointer-events: auto;
		max-width: 250px;
		margin: 0 auto 15px;
		text-align: center;
		select, .select2-container {
			text-align: left;
		}
		@include breakpoint(1300px) {
			max-width: 100%;
		}
		@include breakpoint(950px) {
			text-align: left;
			display: inline-block;
			margin: 0 5px 15px;
		}
	}
}

.storedetailmap {
	@extend .storemap;
	@media only screen and (max-width: map-get($breakpoints, xxmedium) - 1) {
		height: 60vh;
	}
}

.store-location {
	@media only screen and (max-width: map-get($breakpoints, xxmedium)) {
		margin-left: -$mobile-margin;
		margin-right: -$mobile-margin;
	}
	width: 100vw;
	&-detail {
		position: relative;
	}
	&-map {
		width: 100%;
	}
	&-detail-data {
		@include breakpoint(xxmedium) {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 400px;
			z-index: 8;
		}
		padding: 47px 40px 30px 40px;
		height: 100%;
		background: rgba(255,255,255,1);

		h4 {
			font-size: 16px;
			font-weight: 600;
			line-height: 22px;
			span {
				font-weight: 300;
				color:$detail-gray;
			}
		}
	}
	&-phone {
		font-size: 14px;
		font-weight: 300;
		color: $detail-gray;
		line-height: 1.2;
	}
	&-hours {
		border-top: 1px solid $c-border;
		padding-top: 20px;
		margin-top: 20px;
		line-height: 1.4;
		p {
			color:$detail-gray;
			font-weight: 300;
			margin: 0;
		}
	}
	&-name {
		margin-bottom: 20px;
		font-size: 32px;
	}

}

.stores-list-modal {
	display: block;
	@include absolute-center-content;
	top: 45%;
	width:90%;
	height: 65%; // 400px;
	overflow-y: auto;
	border: 0px;
	z-index: 9;
	background: $white;
	background: $white url('/assets/img/project/loading-detail-content.gif') no-repeat center;
	pointer-events: auto;
	&.loaded {
		background: $white;
	}
	&.is-outlet {
		.store-locations-list-modal.fra {
			//display: none !important;
		}
	}
	@include breakpoint(xmedium) {
		width: 800px;
		height: 620px;
	}
	&-wrapper {
		display: none;
		content: '';
	    position: fixed;
	    top: 0;
	    left: 0;
	    //pointer-events: none;
	    width: 100%;
	    height: 100%;
	    background: rgba(0,0,0,0.7);
	    z-index: 10;
	}
	//box-shadow: -50px 0 100px 1000px rgba(0,0,0,0.7);
	.store-locations {
		font-size: 10px;
		&-list {
			display: block !important;
			//justify-content: center;
		}		
		&-item {
			padding: 0 30px;
			border-bottom: 5px solid #ececec;
			display: grid;
			grid-template-columns: 1fr 125px 1fr;
			grid-gap: 10px;
			max-width: unset !important;
			margin: 0 !important;
			@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
				grid-template-columns: 1fr;
				grid-gap: 0;
                padding: 10px;
			}
			&.mpo {
				grid-template-columns: 1fr 1fr 1fr;
				@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
					grid-template-columns: 1fr;
					padding: 10px;
				}
				.store-location-phone {
					// display: none;
				}
			}
			@include breakpoint(xmedium) {
				@include xy-cell(6);			
				margin: 0 10px 50px;			
			}
			@include xy-cell(12);
			&.stock-unavailable {
				opacity: 0.5;
			}
			img {
				margin: 0 auto;
			}

			.additionalSize {
				display: none;
			}
		}
		&-name {
			position: relative;
			font-size: 16px;	
			max-width: 100%;
			@media only screen and (max-width: map-get($breakpoints, xmedium) - 1){
				margin: 5px 0;
			}
		}
		&-address {
			font-size: 14px;
		}
	}
	.store-location-phone {
		//min-height: 30px;
		align-self: center;
		@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
			padding-left: 70px;
			margin: 5px 0;
		}
		a {
			color: $black;
		}
	}
	.product-name {
		background: $c-lightgray;
		padding: 10px;
		border-bottom: 1px solid $c-border;
		@include breakpoint(xmedium) {
			padding: 20px 30px;
		}
		h3 {
			font-size: 26px;
		}
	}
	.store-location-modal {
		padding: 20px 10px 35px;
		@include breakpoint(xmedium) {
			padding: 20px 30px 35px;
		}
		@include breakpoint(xmedium) {
			@include flex-justify;
			align-items: center;
		}
		&-head {
			position: relative;			
			.icon-close {
				position: absolute;
				top: 20px;
				right: 20px;
			}
		}
		&-or {
			text-align: center;
			display: table;
			margin: 10px auto 0;
			@include breakpoint(xmedium) {
				@include inline(bottom);
				line-height: 1;
			}
		}
	}
	.stock-available-text, .stock-unavailable-text {
		//display: table;
		margin: 0 auto 0px;
		font-size: 12px;
		clear: both;
		text-align: right;
		@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
			display: block;
			text-align: left;
		}
		p {
			margin: 0;
		}
	}
	.stock-available-text {
		display: none;
	}
	.stock-unavailable-text {
		@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
			margin-left: 10px;
		}
	}
	.stock-available-text {
		margin-right: -10px;
		@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
			margin-right: 0px;
		}
	}
	.storemodal-inacive-size {
		color: $c-gray !important;
	}
	.product-sizeselect {
		background: transparent;
		color: $black;
		margin-bottom: 0;
		&:hover , &.product-singleSize-radio-selected {
			//border: 3px solid $psgreen;
			//outline: 1px solid transparent;
		}		
		.has-not-stock &, &.storemodal-inacive-size {
			//border: 3px solid $c-lightgray;
			//outline: 1px solid transparent;
		}
	}
	.has-not-stock {
		.storemodal-acive-size {
			color: inherit !important;
			&:before {
				content: none !important;
			}
		}
	}

	.store-location-city-modal {
		border: none;
		border-radius: 0;		
		padding-left: 0;
		width: 290px !important;
		max-width: 45%;
		background: url('/assets/workfiles/project/css/icons/SVG/plus-toggle.svg') no-repeat;
		background-size: 13px;
		border-bottom: 1px solid $c-gray;
		font-size: 16px;
		font-weight: 600;
		color: $black;
	}
	.store-location-size-modal {
		@extend .store-location-city-modal;
	}
	.select2-container {
		width: 290px !important;
		max-width: 45%;
		@media only screen and (max-width: map-get($breakpoints, xmedium)) {
			max-width: 100%;
			display: block;
			margin: 0 auto;
		}
	}
	.select2-container--default .select2-selection--single {
		border: none;
		border-radius: 0;		
		border-bottom: 1px solid $c-gray;		
		.select2-selection__rendered {
			padding-left: 0;
			font-size: 16px;
			font-weight: 600;
			color: $black;		
		}
		.select2-selection__arrow {
			top: 2px;
			right: 0;
			b {
				background: url('/assets/workfiles/project/css/icons/SVG/plus-toggle.svg') no-repeat;
				background-size: 13px;
				width: 13px;
				height: 13px;
			}
		}
	}
}

.store-detail-back {
	display: inline-block;
	margin-top:20px;
}

.store-marker {
	&-item {
		padding: 10px 30px 25px 30px;
		font-size: 14px;
		font-weight: 400;
		width: 270px; // Fix also iwOuter.css({'width': '400px'}); in StoreMap.html
		.store-location-hours {
			padding-top: 0;
			margin-top: 20px;
		}
	}
	&-link {
		text-decoration: none;
	}
	&-name {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 3px;
	}
	&-phone {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.2;
		color: $black;
	}
	&-mail, &-mail a {
		color: $c-blue;
	}
	&-address {
		margin-top: 0;
	}	
}

.gm-style-iw {
	width: 200px !important;
	top: 25px !important;
	left: 5px !important;
	border-radius: 4px;
	overflow: visible !important;
	.store-locations-name {
		padding: 0 10px;
	}
	a:hover {
	   cursor: pointer;
	}
	@include breakpoint(375px) {
		width: 280px !important;
		left: 25px !important;
	}
	@media only screen and (max-width: 374px) {
		img {
			display: none !important;
		}
	}
}




.store-locations {
	&-divider {
		height: 5px;
		background-color: #ECECEC;
		margin: 0;
	}
	&-info {
		color: #ffffff;
		text-align: center;
		padding: 10px 40px;
		background-color: #4a4a4a;
		span {
			font-weight: bold;
		}
	}
	&-wrapper {
		display: table;
		height: 100%;
		@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
			padding-left: 60px;
		}
		&-cell {
			display: table-cell;
			vertical-align: middle;
		}
	}
	&-link {
		display: grid !important;
		grid-template-columns: 50px 1fr;
		grid-gap: 20px;
	}
	&-logo-black {
		align-self: center;
		height: 50px;
		width: 50px;
		background: url(/assets/workfiles/project/css/icons/SVG/ps-logo-black.svg) no-repeat 0;
	}
	&-logo-white {
		align-self: center;
		height: 50px;
		width: 50px;
		background: url(/assets/workfiles/project/css/icons/SVG/ps-logo-white.svg) no-repeat 0;
	}
}