// social teasers
div[class^='social-teaser-'] {
	width: 520px;
	max-width: 100%;
	border: 5px solid $c-middlegray;
	padding: 5px 5px 10px 85px;
	margin-bottom: 20px;
	@include breakpoint(xxmedium) {
		min-height: 230px;
		margin-bottom: 50px;
	}
	position: relative;
	background: $white;
	.social-feed-wrap {
		width: 100%;
		overflow: hidden;
	}
}
div.social-teaser-wrap {
	padding: 20px 30px 10px;
	.formrow-wrap {
		margin-bottom: 20px;
	}
	&:before, &:after {
		display: none;
	}
}
h3[class^='social-teaser-head-'] {
	display: block;
	transform: rotateZ(-90deg);
	position: absolute;
	top: 90px;
	left: -30px;
	font-family: $f-special;
	font-weight: 400;
	margin-bottom: 0;
	@include breakpoint(xmedium) {
		top: 90px;
		left: -52px;
		font-size: 30px;
	}
	a {
		line-height: 32px;
	}
	&:hover {
		opacity: 0.8;
	}
	.icon {
		transform: rotateZ(90deg);	
		width: 32px;
		height: 32px;
		margin-left: 20px;
		vertical-align: top;
	}
	.ic-Facebook {
		color: $fb-btn-blue;
	}
	.ic-Instagram {
		color: $in-btn-blue;
	}
	.ic-Pinterest {
		color: $pin-btn-blue;
		
	}
}
.social-teaser-h {
	font-family: $f-special;
	font-weight: 400;
	@include breakpoint(xxmedium) {
		font-size: 36px;
		&.social-teaser-nl-h {
			margin-bottom: 35px;			
		}
	}		
}

#instafeed {
	img {
		width: 33.3%;
	}
}
iframe {
	max-width: 100%;
}