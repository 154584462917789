
.asRange {
	position: relative;
	width: auto;
	height: 1px;
	margin-bottom: 80px;
	margin-left: 7px;
	margin-right: 7px;

	background-color: $black;

	.asRange-tip {
		position: absolute;
		top: auto;
		bottom: -30px;
		left: -7px;

		width: 95px;
		height: 20px;
		line-height: 20px;

		margin-left: 0;

		border: none;
		background-color: transparent;

		color: $black;
		font-size: 14px;
		text-align: left;

		transition: opacity 0.3s ease-in-out 0s;
		border-radius: 3px;
		font-family: $f-special;

		&:before {
			display: none;
		}

		&--max {
			// Positioning
			left: auto;
			right: -7px;

			// Display & Box Model

			// Color

			// Text
			text-align: right;

			// Other
		}
	}

	.asRange-pointer {
		position: absolute;
		left: 30%;

		width: 14px;
		height: 14px;

		margin-left: -7px;
		margin-top: -7px;

		background-color: $black;
		//opacity: 0;
		z-index: 2;
		border-radius: 15px;
		cursor: pointer;

		&:before {
			display: none;
		}

		&:after {
			display: none;
		}

		&.start {
			left: 0%;
			margin-left: 4px;
		}

		&.stop {
			left: 100%;
			margin-left: -12px;
		}

		.asRange-tip {
			position: absolute;
			top: auto;
			bottom: -30px;
			left: 0;

			width: 95px;
			height: 20px;
			line-height: 20px;

			margin-left: 0;

			border: none;
			background-color: transparent;

			color: $black;
			font-size: 14px;
			text-align: left;

			transition: opacity 0.3s ease-in-out 0s;
			border-radius: 3px;
			font-family: $f-special;

			&:before {
				display: none;
			}

			.asRange-pointer-2 & {
				// Positioning
				left: auto;
				right: 0;

				// Display & Box Model

				// Color

				// Text

				// Other
			}
		}
	}

	.asRange-selected {
		position: absolute;
		left: 30%;
		width: 40%;
		top: -1px;
		height: 2px;

		background-color: $black;
		z-index: 1;
		border-radius: 9px;
	}

	.asRange-scale {
		display: none;
	}
}

.asRange-scale {
	position: relative;
	width: 331px;
	height: 8px;

	background-color: #cfcdc7;
	border-radius: 8px;

	.asRange-pointer {
		position: absolute;
		left: 30%;

		width: 15px;
		height: 15px;

		margin-left: -4px;

		background-color: $black;
		//opacity: 0;
		z-index: 2;
		border-radius: 9px;

		//&:before {
		//	position: absolute;
		//	top: -4px;
		//	right: -4px;
		//	bottom: -4px;
		//	left: -4px;
		//	content: '';
		//	background: #6ba1ad;
		//	border-radius: inherit;
		//}

		&:after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			content: '';
			background: #fff;
			border-radius: inherit;
		}

		&.start {
			left: 0%;
			margin-left: 4px;
		}

		&.stop {
			left: 100%;
			margin-left: -12px;
		}

		.asRange-tip {
			position: absolute;
			top: -33px;
			left: 0;

			width: 36px;
			height: 20px;
			line-height: 20px;

			margin-left: -15px;

			border: 1px solid #5d5c58;
			background-color: #5d5c58;

			color: #fff;
			font-size: 12px;
			text-align: center;

			transition: opacity 0.3s ease-in-out 0s;
			border-radius: 3px;

			&:before {
				position: absolute;
				bottom: -3px;
				left: 50%;
				content: '';
				display: inline-block;
				width: 6px;
				height: 6px;
				margin-left: -3px;
				background-color: #5d5c58;
				transform: rotate(-45deg);
			}
		}
	}

	.asRange-selected {
		position: absolute;
		left: 30%;
		width: 40%;
		height: 8px;

		background-color: #7ebdcb;
		z-index: 1;
		border-radius: 9px;
	}

	.asRange-scale {
		position: absolute;
		left: 0;
		bottom: -22px;

		width: 100%;
		height: 20px;
		margin: 0;
		padding: 0;

		list-style: none;
		background: url("../image/scale.png") no-repeat 0 center transparent;

		li {
			position: absolute;
			top: 18px;

			width: 30px;
			height: 20px;
			margin: 0;
			padding: 0;
			margin-left: -15px;

			text-align: center;

			&:first-child {
				left: 0%;
			}
			&:nth-child(2) {
				left: 33.3%;
			}
			&:nth-child(3) {
				left: 66.6%;
			}
			&:last-child {
				left: 100%;
			}
		}
	}
}
