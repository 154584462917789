.products-list-slider {
  margin: 40px 0;
}

.products-list-slider--no-margin {
  @include from($tablet) {
    margin-left: -250px; // Move intented CE margin to left
  }
}

.products-list-slider--inner {
  .slick-next, .slick-prev {
    top: 35%;
    z-index: 1;
    background-repeat: no-repeat;

    @include from(360px) {
      top: 40%;
    }

    @include from($desktop-large) {
      top: 37%;
    }

    &.slick-disabled {
      opacity: 0.2 !important;
    }
  }

  .slick-next {
    right: -20px;
    background: sassvg("arrow-right") no-repeat center;
    background-size: 14px;
    opacity: 0.8;

    @include from($desktop) {
      right: 20px;
    }

    &:hover {
      outline: none;
      opacity: 1;
    }
  }

  .slick-prev {
    left: -20px;
    background: sassvg("arrow-left") no-repeat center;
    background-size: 14px;
    opacity: 0.8;

    @include from($desktop) {
      left: 20px;
    }

    &:hover {
      outline: none;
      opacity: 1;
    }
  }

  .icon.ic-cart {
    width: 21px;
    height: 21px;
  }
}

.products-list-slider--items {
  padding: 0 10px;

  @include from($desktop) {
    padding: 0 50px;
  }
}

.products-list-slider--item {
  padding: 0 10px;

  .product-list-base--item-price {
    @include between(360px, $xmobile) {
      font-size: 15px; // Reduce font because of two lines
    }
  }
  .product-list-base--item-data {
    padding-top: 10px;
  }
}