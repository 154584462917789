// Product list

$view-switch: 1200px;

.product-list {
  @extend .inner-row;
  @extend .indent-content;

  &-single {
    position: relative;
    width: 98%;

    // Added because of product-single-switches
    height: calc(100% - 1.25rem);

    margin: 20px auto !important;
    @include breakpoint(xmedium) {
      width: 48%;
      margin: 0 2% 10px 0;
    }
    @include breakpoint(xxmedium) {
      width: 31.3%;
      margin: 0 2% 15px 0;
      .prod-shopbylook {
        position: relative;
        margin-top: 20px;
        display: inline-block;
      }
    }

    &:hover {
      .product-single-switches,
      .add-to-wl, .ic-cart {
        opacity: 1;
      }

      .slick-next, .slick-prev {
        opacity: 1;

        &.slick-disabled {
          opacity: 0.2;
        }
      }

      .product-single-slider {
        &:after {
          opacity: 1;
        }
      }
    }

    a {
      text-decoration: none;
    }
  }

  &-markers {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    span {
      display: block !important;
      margin-right: 0 !important;
      margin-bottom: 7px !important;
    }
  }

  &-twocols {
    @extend .product-list;

    .product-list-single {
      position: relative;
      width: 48%;
      //margin: 0 2% 55px 0;
    }
  }

  &-smaller {
    @extend .product-list;

    .product-list-single {
      position: relative;
      width: 48%;
      margin: 0 2% 35px 0;
      @include breakpoint(xmedium) {
        width: 48%;
        //margin: 0 2% 65px 0;
      }
      @include breakpoint(xxmedium) {
        width: 31.3%;
        //margin: 0 2% 65px 0;
      }
      @include breakpoint(xlarge) {
        width: 23%;
        //margin: 0 2% 65px 0;
      }
    }

    span[class^='prod-marker'] {
      @include breakpoint(xmedium) {
        width: 30px;
        height: 30px;
        margin-right: 7px;
        font-size: 23px;
        line-height: 30px;
      }
    }

    span[class^='prod-marker'] img {
      width: 30px;
      height: 30px;
    }
  }

  &-smaller-four-in-row {
    @extend .product-list;

    .product-list-single {
      position: relative;
      width: 48%;
      margin: 0 2% 35px 0;
      @include breakpoint(xmedium) {
        width: 48%;
        //margin: 0 2% 65px 0;
      }
      @include breakpoint(xxmedium) {
        width: 23%;
        //margin: 0 2% 65px 0;
      }
      @include breakpoint(xlarge) {
        width: 23%;
        //margin: 0 2% 65px 0;
      }
    }

    span[class^='prod-marker'] {
      @include breakpoint(xmedium) {
        width: 30px;
        height: 30px;
        //margin-right: 7px;
        font-size: 23px;
        line-height: 30px;
      }
    }

    span[class^='prod-marker'] img {
      width: 30px;
      height: 30px;
    }
  }

  &-indexedsearch {
    @extend .product-list-smaller;

    .product-list-single {
      position: relative;
      width: 98%;
      margin: 0 auto 25px;
      @include breakpoint(xxmedium) {
        width: 48%;
        margin: 0 2% 35px 0;
      }
      @include breakpoint(xxxlarge) {
        width: 30.3%;
        margin: 0 3% 65px 0;
      }
    }

    .product-list-base--item-data-bottom {
      grid-template-columns: 25px auto;
    }

    .product-list-base--item-name-link {
      font-size: 16px;
    }
  }

  &-headersearch {
    @extend .product-list-smaller;
    margin: 0 !important;

    .product-single-switches {
      //display: none;
    }
  }

  &-name {
    display: inline-block;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 300;
    margin-right: 120px;
    margin-bottom: 0;
    @media only screen and (max-width: map-get($breakpoints, xxmedium)) {
      //margin-bottom: 10px;
      min-height: 38px;
      margin-right: 0;
      //margin-bottom: 0;
      font-size: 16px;
    }
    @media only screen and (max-width: map-get($breakpoints, xmedium)) {
      font-size: 14px;
      min-height: 33px;
    }


    .product-list-smaller &,
    .product-list-smaller-four-in-row & {
      font-size: 14px;
      //margin-bottom: 10px;
      max-width: 79%;
    }

    .product-list-headersearch & {
      font-size: 12px;
      min-height: 20px;
    }
  }

  &-threecols {
    @media only screen and (min-width: map-get($breakpoints, xxxlarge)) {
      .product-list-name {
        max-width: 47%;
      }
    }

    .product-list-name {
      min-height: 42px;
      @media only screen and (max-width: map-get($breakpoints, medium)) {
        min-height: 32px;
      }
    }
  }

  &-smaller {
    @media only screen and (min-width: map-get($breakpoints, large)) and (max-width: map-get($breakpoints, xlarge)) {
      .product-list-name {
        max-width: 50%;
      }
    }
    @media only screen and (min-width: map-get($breakpoints, xxxlarge)) {
      .product-list-name {
        max-width: 49%;
      }
    }
  }
}

.product-single {
  display: none;

  &.active {
    display: block;
  }

  &-image {
    max-width: 100%;
    height: auto;
  }

  &-switches {
    //display: none;
    width: 100%;
    position: absolute;
    z-index: 1;
    @include breakpoint(xmedium) {
      //opacity: 0;
    }
    text-align: center;
    @include transition;

    &.loaded {
      display: block;
    }
  }

  &-switch {
    display: inline-block;
    vertical-align: middle;
  }

  &-slider {
    margin-bottom: 10px;

    .slick-next, .slick-prev {
      background-repeat: no-repeat;
      top: 45%;
      @include breakpoint(xmedium) {
        opacity: 0;
      }

      &.slick-disabled {
        opacity: 0;
      }
    }

    .slick-next {
      right: 5px;
      background-image: url(/assets/workfiles/project/css/icons/SVG/arrow-activeR.svg);

      &:hover {
        outline: none;
        opacity: 1;
        background-image: url(/assets/workfiles/project/css/icons/SVG/arrow-hoverR.svg);
      }
    }

    .slick-prev {
      left: 5px;
      background-image: url(/assets/workfiles/project/css/icons/SVG/arrow-activeL.svg);

      &:hover {
        outline: none;
        opacity: 1;
        background-image: url(/assets/workfiles/project/css/icons/SVG/arrow-hoverL.svg);
      }
    }

    .product-single-image {
      width: 100%;
    }
  }

  &-price {
    display: flex;
    min-height: 37px;
    align-items: flex-end;
    line-height: 1;
    grid-column-start: 1;
    grid-column-end: 3;

    .price {
      font-size: 22px;
      font-weight: 600;

      .product-list-smaller &,
      .product-list-smaller-four-in-row & {
        font-size: 18px;
      }

      @media only screen and (max-width: map-get($breakpoints, xxmedium)) {
        font-size: 18px;
      }
    }

    .old-price {
      display: inline-block;
      margin-left: 36px;
      margin-bottom: 5px;

      .product-list-smaller &,
      .product-list-smaller-four-in-row & {
        font-size: 11px;
        margin-left: 32px;
      }
    }
  }

  &-info {
    position: relative;
    display: grid;
    grid-gap: 10px 15px;
    grid-template-columns: 20px 1fr;

    .product-list-twocols & {
      @media only screen and (min-width: map-get($breakpoints, xxmedium)) {
        min-height: 80px;
      }
    }

    .product-list-smaller-four-in-row & {
      @media only screen and (min-width: map-get($breakpoints, xxmedium)) {
        min-height: 83px;
      }
    }

    .add-to-wl {
      display: inline-block;
      vertical-align: top;
      opacity: 0.3;
      @include transition;

      .product-list-smaller &,
      .product-list-smaller-four-in-row {
        @media only screen and (max-width: 450px) {
          display: none;
        }
      }
    }

    .ic-cart {
      display: inline-block;
      vertical-align: top;
      margin-right: 15px;
      opacity: 0.3;
      width: 20px;
      height: 23px;
      @include transition;
      @media only screen and (max-width: map-get($breakpoints, xxmedium)) {
        width: 17px;
        height: 20px;
        margin-top: -3px;
        margin-right: 19px;
      }

      .product-list-smaller &,
      .product-list-smaller-four-in-row {
        width: 16px;
        height: 18px;
        @media only screen and (max-width: 450px) {
          display: none;
        }
      }
    }

    .prod-link-wishlist .ic-wishlist {
      width: 20px;
      height: 20px;
      @media only screen and (max-width: map-get($breakpoints, xxmedium)) {
        width: 18px;
        height: 18px;
      }

      .product-list-smaller &,
      .product-list-smaller-four-in-row {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
    }

  }

  &__permanent-discount {
    margin: -10px 0 0 0;
    padding: 0 0 20px 0;
    color: $actionred;
  }

}

.old-price {
  text-decoration: line-through;
  color: $c-text;
}

// Product detail
.product-detail--modal {
  position: fixed;
  top: 0;
  bottom: 50px;
  left: 0;
  right: 0;
  padding: 0 20px;
  background: white;
  z-index: 3;
  overflow: auto;

  @include from($tablet) {
    padding: 0;
  }
  @include from($desktop) {
    top: 81px
  }

  .accordion__trigger_icon {
    top: 13px;
    width: 15px;
    height: 15px;
    background: url('/assets/workfiles/project/css/icons/SVG/plus-toggle.svg') no-repeat center right;
    background-size: contain;
  }

  .accordion__trigger_active .accordion__trigger_icon {
    background: url('/assets/workfiles/project/css/icons/SVG/minus-toggle.svg') no-repeat center right;
    background-size: contain;
    transform: none;
  }

  .product-detail--accordion-title {
    margin-bottom: 10px;
    margin-top: 10px;
    color: #000;
    font-size: 12px;
    font-weight: 700;
  }

  .product-list-base--item {
    opacity: 1;
  }

  .product-list-base--item-stickers-item {
    width: 30px;
    height: 30px;
    &--multibase {
      display: grid;
      place-items: center;
      background: $actionred;
      color: $white;
      font-size: 18px;
      font-weight: 300;
    }
  }

  .product-list-six-cols--item-wrap {
    width: 100%;
  }
}

.overflow {
  overflow: hidden;
}

.product-detail {
  @include flex-justify;
  @include breakpoint($view-switch) {
    grid-template-columns: minmax(auto, 1000px) minmax(480px, 1fr) !important;
  }
  @include breakpoint(768px) {
    display: grid;
    grid-template-columns: minmax(auto, 800px) minmax(480px, 1fr);
  }

  @include breakpoint(xmedium) {
    margin-bottom: 60px;
  }

  @include from($product-detail-full-image-breakpoint) {
    grid-template-columns: minmax(auto, 1170px) minmax(480px, 1fr) !important
  }

  opacity: 0;

  &.show-modal {
    opacity: 1;
    transition: opacity 0.4s;
  }


  .product-size {
    margin-left: -9px;

    .product-singleSize-radio {
      display: none;
      vertical-align: top;
      margin-right: 10px;
      @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
        margin-bottom: 0px;
      }

      &.has-not-stock {
        pointer-events: none;
      }

      .additionalSize {
        display: block;
        width: 100%;
        font-size: 12px;
        color: $c-middlegray;
        text-align: center;
      }
    }
  }

  .article-is-temporarily-soldout-message,
  .article-is-soldout-message {
    //display: none;
    font-size: 15px;
    margin-bottom: 15px;
    margin-right: 50px;
    width: 100%;
  }

  .article-is-temporarily-soldout-message {
    margin-top: 20px;
    font-size: 12px;
  }

  .article-is-soldout-message {
    margin: 30px 0;
  }

  .product-detail-price {
    display: grid;
    grid-template-columns: auto 1px 1fr;
    grid-gap: 10px;
    padding: 30px 0 10px 0;

    @include breakpoint($view-switch) {
      grid-gap: 60px;
    }

    .price {
      font-weight: 600;
      font-size: 22px;
      @media only screen and (max-width: map-get($breakpoints, xsmall)) {
        margin-right: 20px;
      }
      @media only screen and (min-width: map-get($breakpoints, xmedium)) and (max-width: map-get($breakpoints, large)) {
        margin-right: 20px;
      }
      white-space: nowrap;
    }

    // Color price do dark red if price is permanent discounted
    .price-permanent-discount {
      display: flex;
      align-items: flex-end;
      color: $psred !important;
    }

    .no-margin {
      margin: 0;
    }
  }

  .product-detail-links {
    display: none;
  }

  .addProductToCart {
    display: none;

    &.loading {
    }
  }

  .product-btn-cart {
    padding: 13px 70px;
  }

  .prod-marker-collection img {
    height: 40px;
  }

  &-slider {
    // 		background: $c-lightgray;
    overflow: hidden;

    &.product-detail-hide-until-desktop-large {
      display: none;
    }

    @include breakpoint($view-switch) {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      margin-bottom: 50px;
    }
    // 		display: none;
    &.slick-initialized {
      // 			display: block;
      margin-bottom: 0;
      width: 100%;
      height: 65vh;
    }
  }

  &-content {
    width: 100%;
    padding: 0 0 30px;
    //background: url('/assets/img/project/loading-detail-content.gif') no-repeat top center;

    .accordion-container {
      font-size: 12px;
    }
  }

  &-fixed {
    //display: none;
    background: $white;
    position: sticky;
    top: 0;
    padding: 15px 10px;
    z-index: 1;

    @include breakpoint(1401px) {
      padding: 85px 40px 10px 80px !important;
    }
    @include breakpoint(1200px) {
      padding: 85px 40px 10px 65px;
    }

    @include breakpoint(769px) {
      padding: 60px 40px 10px 65px;
      margin-bottom: 90px;
    }


  }

  &-img {
    display: block;
    max-width: 100%;
    height: auto;
    z-index: 1;
  }

  &-video {
    width: 100%;
  }

  &-name {
    display: flex;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;

    @include breakpoint($view-switch) {
      margin-left: -45px;
    }
  }

  &-info {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0 30px;
    //line-height: 1.2;

    &-label {
      color: $black;
      font-size: 12px;
      font-weight: bold;
    }

    &-text {
      color: $c-text;
      font-size: 12px;
    }
  }

  &-markers {
    text-align: right;

    span[class^='prod-marker'] {
      width: 40px;
      height: 40px;
      margin-bottom: 5px;
      line-height: 40px;
      font-size: 30px;
    }
  }

  &-price {
    .old-price {
      display: block;
      font-size: 12px;
    }

    &-container {
      display: grid;
      align-content: space-between;
      line-height: 1.7;
    }
  }

  &-addtocart {

    display: flex;
    justify-content: space-between;
    @include breakpoint(990px) {
      @include clearfix;
    }

    @include breakpoint($view-switch) {
      margin-bottom: 40px;
      padding-bottom: 30px;
    }

    @include breakpoint(xxmedium) {
      padding: 30px 0 40px;
    }

    @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
      display: block;
    }


    margin-top: 20px;
    margin-bottom: 25px;
    padding: 5px 0 10px;
    border-top: 1px solid $product-detail-border;
    border-bottom: 1px solid $product-detail-border;
  }

  &-sizes {
    position: relative;
    //display: none;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 15px;
    border: 1px solid transparent;

    @include breakpoint(1090px) {
      float: left;
    }
    @include breakpoint($view-switch) {
      margin-top: 5px;
    }

    > p {
      margin-top: 0;
    }

    > p {
      margin-top: 0;
    }

    .error-nosizeselected {
      display: inline-block;
      padding: 0 5px;
      margin-left: -14px;
      box-shadow: none;
      border: 1px solid #a80012;
      border-radius: 2px;
      @include breakpoint(xmedium) {
        //margin-left: -13px;
      }

      .product-btn-cart {
        background-color: $red;
      }
    }

    &-label {
      display: flex;
      align-items: flex-end;
      font-weight: 650;
      color: $black;

      @include breakpoint($view-switch) {
        font-size: 12px;
      }
    }
  }

  &-links {
    margin-top: 20px;
    @include breakpoint(1090px) {
      float: right;
    }
  }

  &-colors {
    a[class^='color-dot'] {
      display: block;
    }
  }

  &-color-single {
    @include inline(top);
    margin-right: 7px;
    margin-bottom: 7px;

    img {
      margin: 0 auto 5px;
    }

    .color-active img {
      border: 3px solid $black;
    }

    .price {
      color: #000000;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.7;
    }

    .old-price {
      display: inline-block;
      font-size: 12px;
      line-height: 1.7;
      min-height: 20px;
    }

    .product-price {
      line-height: 1.1;
      max-width: 120px;
    }
  }

  &-tabs {
    position: relative;
    z-index: $overlay-z-index - 1;
    @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
      display: none;
    }

    .product-list-smaller,
    .product-list-smaller-four-in-row {
      margin-left: 0;
      justify-content: center;
    }
  }

  &-share {
    @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
      text-align: center;
    }
  }

  &-description {
    p {
      margin-top: 0;
      font-size: 16px;
      line-height: 28px;
      font-weight: 300;
    }
    a {
      text-decoration: none;
      border-bottom: 1px solid;
      font-weight: 600;
      display: inline-block;
    }
  }

  .link-wishlist-detail {
    margin-right: 20px;

    @include breakpoint($view-switch) {
      margin-right: 15px;
    }
  }
  //&-images {
  //  @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
  //    width: 100%;
  //    min-height: 65vh;
  //  }
  //}
}

.sizes-update-notice {
  display: none;
  position: absolute;
  top: -30px;
  left: -250px;
  width: 215px;
  padding: 15px;
  background: $c-text;
  color: $white;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 7px;
  // Display triangle on desktop screen sizes
  @media only screen and (min-width: map-get($breakpoints, xmedium)) {
    &:after {
      @include triangle-right($c-text);
    }
  }

  &-mobile {
    //@extend .product-detail-info-text;
    @extend .show-on-mobile;
    margin: 30px 0 15px 0;
    color: #A2A2A2;
    font-size: 12px;
    line-height: 22px;
  }
}

.price {
  font-size: 20px;
  color: $black;
}

.sizes {
  &-info-trigger {
    color: #4A4A40;
    font-size: 12px;
    padding: 1px 16px;
    margin-left: 40px;
    box-sizing: border-box;
    border: 1px solid #979797;
    border-radius: 4px;
  }

  &-info-trigger-link {
    text-decoration: none;
  }
}

span[class^='prod-marker'] {
  @include inline;
  width: 30px;
  height: 30px; // Change also for discount and collection
  margin-right: 7px;
  font-size: 23px;
  line-height: 30px;
  @include breakpoint(1002px) {
    width: 50px;
    height: 50px; // Change also for discount and collection
    margin-right: 14px;
    font-size: 37px;
    line-height: 50px;
  }
  color: $white;
  text-transform: uppercase;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }
}

.prod-marker {
  &-action {
    background: $actionred;
  }

  &-discount {
    background: $actionred;
    font-size: 24px;
    font-weight: 100;
    display: inline-block;

    &-holder {
      display: inline-block;
    }

    &-text {
      display: inline-block;
      color: #A80313;
      font-size: 12px;
      line-height: 17px;
    }

    &-box {
      text-align: center;
      line-height: 0;
      @media only screen and (max-width: map-get($breakpoints, xmedium)) {
        text-align: right;
      }
    }
  }

  &-new {
    background: $newblack;
  }

  &-discount img,
  &-collection img {
    // Images can be uploaded via action or collection, so set width to auto and height to default settings
    width: auto;
    height: 30px;

    @include breakpoint(1000px) {
      height: 50px;
    }
  }
  &-single--multi-discount {
    display: grid;
    place-items: center;
    background: $actionred;
    color: $white;
    font-size: 18px!important;
    font-weight: 300;
  }
}

.multi-discount-notice {
  font-weight: 600;
  color: $actionred;
}

.product {
  &-size {
    border: 1px solid transparent;

    &-wrapper {
      position: relative;
      padding-bottom: 50px;

      @include breakpoint($view-switch) {
        padding-bottom: 40px;
      }
    }
  }

  &-sizeselect {
    @include inline;
    width: auto;
    border: none;
    font-size: 14px;
    color: $c-text;
    padding: 10px 9px;
    margin-bottom: 0;
    @include transition(0.1s);
    cursor: pointer;
    border-radius: 4px;
    line-height: 1;

    @include breakpoint(1024px) {
      padding: 8px 7px;
    }

    &.product-singleSize-radio-selected {
      background: $c-text;
      color: $white;
      font-weight: 300;
    }

    .has-not-stock &, &.storemodal-inacive-size {
      color: $c-gray;
      position: relative;

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: url('/assets/workfiles/project/css/icons/SVG/x-sizes.svg') no-repeat center;
        background-size: 18px 20px;
        color: $c-gray;
      }
    }
  }

  &-btn-cart {
    @extend .btn-large;
    background: $c-text;
    margin-right: 10px;
    margin-bottom: 20px;

    border-radius: 4px;
    font-size: 14px;
    text-transform: none;

    @include breakpoint($view-switch) {
      //margin-bottom: 25px;
    }

    .ic-cart-top {
      margin-right: 5px;
      background: sassvg("ic-cart-top", #fff) no-repeat center;
      vertical-align: text-top;
    }

    &.loading {
      .ic-cart {
        background: url('/assets/workfiles/project/css/icons/SVG/rolling-white.svg') no-repeat center;
        background-size: 19px;
      }
    }
  }

  &-btn-call {
    display: inline-flex;
    min-height: 31px;
    padding: 4px 0 4px 30px;
    align-items: center;
    background: sassvg("ic-phone-call") no-repeat left center;
    text-decoration: none;
    color: $c-text;
    font-weight: 300;

    &:hover {
      text-decoration: underline;
    }
  }

  &-viewed,
  &-recommended,
  &-related {
    &-headline {
      font-family: $f-special;
      position: relative;
      margin-bottom: 40px;
      color: $black;
      font-size: 28px;
      @include indent-content;
      @include line-before;
    }
  }

  &-available-stock {
    position: absolute;
    left: 0;
    bottom: 15px;

    @include breakpoint($view-switch) {
      bottom: 7px;
    }
    font-size: 12px;
    color: $c-placeholder;
  }
}

#product-viewed-list,
.product-recommended-list,
.product-related-list {
  width: 100%;
  @include clearfix;
  margin-bottom: 50px;
}

#product-viewed-list,
.product-recommended-list {
  @media only screen and (min-width: 768px) {
    width: 98%;
  }
}

.checkout-additional-product-list__grid {
  width: 100% !important;
  border-bottom: 1px solid #ccc;
}

.product-detail--additional-list .product-list-smaller {
  display: flex;
  justify-content: space-between;

  .product-list-single {
    width: auto;
    max-width: 48%;
    margin: 0 0 20px 0 !important;

    // Remove SBL Button
    .product-list-name {
      width: 100% !important;
      max-width: 100%;
    }

    .prod-shopbylook {
      display: none;
    }
  }

  @media only screen and (min-width: 661px) and (max-width: 1279px) {
    .product-list-single {
      max-width: 30%;
    }
  }
  @media only screen and (min-width: 1280px) {
    .product-list-single {
      max-width: 15%;
    }
  }
  @media only screen and (max-width: 450px) {
    .old-price {
      margin-left: 0;
    }
  }
}

.product-detail--additional-list-container {
  @include from($tablet) {
    margin-left: 250px;
  }
}

.product-detail--additional-list {
  .cart-detailview & {
    //border-bottom: 1px solid $c-border;
  }
}

// don't delete
a[class^='prod-link'] {
  //display: block;
  //  width: 150px;
  //  font-size: 11px;
  //  margin-bottom: 20px;
  //  @include border-transform($c-gray, 1, 1.1);
  //  text-decoration: none;
  //
  //  .icon {
  //    width: 16px;
  //    text-align: center;
  //    margin-right: 15px;
  //    vertical-align: middle;
  //  }
  //
  //  @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
  //    margin: 0 auto 20px;
  //  }
}

.prod-link-tutorial {
  &.link-responsive {
    display: none !important;
  }

  @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
    display: none !important;
    &.link-responsive {
      display: block !important;
    }
  }
}

.prod-link {
  &-wishlist {
    .ic-wishlist,
    .ic-wishlist-filled {
      width: 16px;
      height: 16px;
    }

    &.active {
      .icon {
        background: #fff url('/assets/workfiles/project/css/icons/SVG/wishlist-filled.svg') no-repeat center;
        background-size: 100%;

        use {
          display: none;
        }
      }
    }

    &.loading {
      .icon {
        background: #fff url('/assets/workfiles/project/css/icons/SVG/rolling.svg') no-repeat center;
        background-size: 19px;

        use {
          display: none;
        }
      }
    }

    &-remove {
      display: none;
    }
  }
}

.prod-shopbylook {
  position: absolute;
  right: 0;
  padding: 2px 15px;
  border: 1px solid #999;
  border-radius: 5px;
  transition: all 200ms;
  top: -2px;
  @media only screen and (max-width: map-get($breakpoints, xxmedium)) {
    display: inline-block;
    position: relative;
    margin-top: 10px;
    grid-column-start: 1;
    grid-column-end: 3;
    max-width: 120px;
  }

  &:hover {
    border: 1px solid #333;
    border-radius: 1px;
  }

  .product-list-twocols & {
    @media only screen and (min-width: map-get($breakpoints, xxmedium)) {
      position: absolute;
      right: 0;
      top: -2px;
      margin-top: 0;
    }
  }

  .product-list-threecols & {
    @media only screen and (min-width: map-get($breakpoints, xxxlarge)) {
      position: absolute;
      right: 0;
      top: -2px;
      margin-top: 0;
    }
  }

  .product-list-smaller &,
  .product-list-smaller-four-in-row {
    padding: 2px 20px;
    font-size: 12px;
    @media only screen and (min-width: map-get($breakpoints, large)) and (max-width: map-get($breakpoints, xlarge)) {
      position: absolute;
      right: 0;
      top: -2px;
      margin-top: 0;
    }
    @media only screen and (min-width: map-get($breakpoints, xxxlarge)) {
      position: absolute;
      right: 0;
      top: -2px;
      margin-top: 0;
    }
  }
}

.product-list-smaller-four-in-row {
  .prod-shopbylook {
    @media only screen and (min-width: map-get($breakpoints, xxmedium)) {
      display: inline-block;
      position: relative;
      margin-top: 10px;
      grid-column-start: 1;
      grid-column-end: 3;
      max-width: 120px;
    }
  }

  .product-list-name {
    @media only screen and (min-width: map-get($breakpoints, xxmedium)) {
      min-height: 35px;
      margin-right: 0;
      max-width: 100%;
    }
  }

  .product-single-price {
    @media only screen and (min-width: map-get($breakpoints, xxmedium)) {
      min-height: 41px;
    }
  }
}

.product-composition-table {
  width: auto;
}

.maintenance-icon {
  width: 50px;
  height: 50px;
  @include inline;
  //background: url("/assets/img/project/sprite/sprite-materials.png") no-repeat;
}

//.maintenance {
//  &-0 {
//    background-position: 0px 0px !important;
//  }
//
//  &-1 {
//    background-position: 0px -50px;
//  }
//
//  &-2 {
//    background-position: 0px -100px;
//  }
//
//  &-3 {
//    background-position: 0px -150px;
//  }
//
//  &-4 {
//    background-position: 0px -200px;
//  }
//
//  &-5 {
//    background-position: 0px -250px;
//  }
//
//  &-6 {
//    background-position: 0px -300px;
//  }
//
//  &-7 {
//    background-position: 0px -350px;
//  }
//
//  &-8 {
//    background-position: 0px -400px;
//  }
//
//  &-A {
//    background-position: 0px -450px;
//  }
//
//  &-B {
//    background-position: 0px -500px;
//  }
//
//  &-C {
//    background-position: 0px -550px;
//  }
//
//  &-D {
//    background-position: 0px -600px;
//  }
//
//  &-E {
//    background-position: 0px -650px;
//  }
//
//  &-F {
//    background-position: 0px -700px;
//  }
//
//  &-G {
//    background-position: 0px -750px;
//  }
//
//  &-H {
//    background-position: 0px -800px;
//  }
//
//  &-I {
//    background-position: 0px -850px;
//  }
//
//  &-J {
//    background-position: 0px -900px;
//  }
//
//  &-K {
//    background-position: 0px -950px;
//  }
//
//  &-L {
//    background-position: 0px -1000px;
//  }
//
//  &-M {
//    background-position: 0px -1050px;
//  }
//
//  &-N {
//    background-position: 0px -1100px;
//  }
//
//  &-O {
//    background-position: 0px -1150px;
//  }
//
//  &-P {
//    background-position: 0px -1200px;
//  }
//
//  &-Q {
//    background-position: 0px -1250px;
//  }
//
//  &-R {
//    background-position: 0px -1300px;
//  }
//
//  &-S {
//    background-position: 0px -1350px;
//  }
//
//  &-T {
//    background-position: 0px -1400px;
//  }
//
//  &-U {
//    background-position: 0px -1450px;
//  }
//
//  &-V {
//    background-position: 0px -1500px;
//  }
//
//  &-W {
//    background-position: 0px -1550px;
//  }
//
//  &-X {
//    background-position: 0px -1600px;
//  }
//
//  &-a {
//    background-position: 0px -1650px;
//  }
//
//  &-b {
//    background-position: 0px -1700px;
//  }
//
//  &-c {
//    background-position: 0px -1750px;
//  }
//
//  &-d {
//    background-position: 0px -1800px;
//  }
//
//  &-e {
//    background-position: 0px -1850px;
//  }
//
//  &-f {
//    background-position: 0px -1900px;
//  }
//
//  &-g {
//    background-position: 0px -1950px;
//  }
//
//  &-h {
//    background-position: 0px -2000px;
//  }
//
//  &-i {
//    background-position: 0px -2050px;
//  }
//
//  &-j {
//    background-position: 0px -2100px;
//  }
//
//  &-k {
//    background-position: 0px -2150px;
//  }
//
//  &-l {
//    background-position: 0px -2200px;
//  }
//
//  &-m {
//    background-position: 0px -2250px;
//  }
//
//  &-n {
//    background-position: 0px -2300px;
//  }
//
//  &-o {
//    background-position: 0px -2350px;
//  }
//
//  &-p {
//    background-position: 0px -2400px;
//  }
//
//  &-q {
//    background-position: 0px -2450px;
//  }
//
//  &-r {
//    background-position: 0px -2500px;
//  }
//}

.product-filter {
  display: none;
  text-align: center;
  padding: 0 10px 10px;
  background: $white;

  @media only screen and (max-width: map-get($breakpoints, medium)) {
    height: 300px;
    overflow-y: auto;
  }

  &-colors {
    span[class^='color-dot'] {
      width: 20px;
      height: 20px;
      margin: 3px;
    }

    .checked span[class^='color-dot'] {
      width: 23px;
      height: 23px;
      margin: 0;
      border: 3px solid $black;
    }
  }

  &-sizes, &-prices, &-colors {
    @include inline(top);
    width: 100%;
    margin-top: 15px;
    @include breakpoint(xmedium) {
      width: 49%;
      margin-bottom: 0;
    }
    @include breakpoint(xxmedium) {
      width: 33%;
    }
  }

  &-inline {
    @include inline(top);
    width: 90%;
    max-width: 450px;
    margin: 0 4%;
    text-align: center;
  }

  h3 {
    @include inline(top);
    font-size: 16px;
    text-transform: uppercase;
    text-align: left;
    font-weight: 400;
  }

  &-wrapper {
    display: none;
    position: fixed;
    left: 0;
    bottom: 0;
    margin-bottom: $footer-closed;
    width: 100%;
    z-index: 9;
    @include transition();
    //overflow: hidden;

  }

  &-trigger {
    display: block;
    text-align: center;
    font-size: 28px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 10px 0;
    height: 50px;
    line-height: 1;
    text-decoration: none;
    box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, .2);
    background: $white;
    opacity: 0.6;
    @include transition();

    &.open, &:hover {
      opacity: 1;
    }

    .icon-open {
      margin-right: 15px;
      margin-bottom: 5px;
    }
  }

  .product-singleColor,
  .product-singleSize {
    @include inline();

    label {
      cursor: pointer;
    }
  }

  .product-singleSize {
    margin: 0 7px;

    .ic-checkbox {
      display: block;
      margin: 0 0 2px;
    }

    .checkbox-single {
      text-align: center;
    }

    .checkbox-label {
      margin-left: 0;
    }
  }
}

.price-slider-wrap {
  height: 35px;
  padding: 8px 10px 15px 10px;
  width: 100%;
}

.productGroupPager {
  clear: both;
  display: block;
  width: 100%;
  margin: 30px 0;
  padding: 10px;
  background: #eee;
  color: #555;
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    background: #bbb;
    letter-spacing: 8px;
    transition: all 0.3s;
  }
}

.product-single-full {
  @extend .indent-content;
  margin-bottom: 35px;
  @include breakpoint(880px) {
    margin-bottom: 100px;
  }

  &-img {
    display: block;
    width: 100%;
    height: auto;
  }

  &-image {
    position: relative;
  }

  .product-single-info {
    &:hover {
      .add-to-wl, .ic-cart {
        opacity: 1;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .video {
    @media only screen and (max-width: map-get($breakpoints, xxmedium) - 1) {
      &-iframe, &-element {
        height: 400px;
      }
    }
    @media only screen and (max-width: map-get($breakpoints, medium) - 1) {
      &-iframe, &-element {
        height: 270px;
      }
    }
  }

  &-view-1 {
    @extend .inner-row;
    align-items: flex-end;

    .product-single-full-image {
      margin-bottom: 10px;
      @include breakpoint(880px) {
        width: 69%;
        margin-bottom: 0;
        margin-right: 2%;
      }
      @include breakpoint(large) {
        width: 79%;
      }
    }

    .product-single-info {
      width: 100%;
      @include breakpoint(880px) {
        width: 29%;
      }
      @include breakpoint(large) {
        width: 19%;
      }
      padding-bottom: 20px;
      border-bottom: 1px solid $c-border;
    }
  }

  &-view-2 {
    position: relative;

    .product-single-full-image {
      margin-bottom: 10px;
      @include breakpoint(880px) {
        width: 69%;
        margin-bottom: 0;
      }
      @include breakpoint(large) {
        width: 79%;
      }
    }

    .product-single-info {
      @include breakpoint(880px) {
        position: absolute;
        bottom: 14%;
        left: 69%;
        background: $white;
        padding: 23px 25px;
        margin-left: -60px;
      }
      @include breakpoint(large) {
        left: 79%;
      }
    }
  }

  &-view-3 {
    position: relative;

    .product-single-full-image {
      margin-bottom: 10px;
      @include breakpoint(880px) {
        margin-bottom: 0;
      }
    }

    .product-single-info {
      @include breakpoint(880px) {
        position: absolute;
        bottom: 66px;
        left: 66px;
      }

      .add-to-wl, .ic-cart {
        opacity: 1;
      }
    }
  }
}

.singleproductimage-singleitem-popup {
  @extend .collection-singleproduct-popup;
}

.filter-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fbfbfb;
  background-image: url('/assets/img/project/loading-detail.gif');
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
  display: none;
}

.cartdiscount-notice--container {
  margin: 0;
}

.cartdiscount-notice {
  display: block;
  color: $psred;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1.7;

  @include breakpoint(480) {
    font-size: 11px;
  }
}

.cartdiscount-notice-price {
  color: $psred;
  font-size: 20px;
  white-space: nowrap;
  font-weight: 600;
  line-height: 1.7;
}

.imageslider {
  opacity: 0;
  background: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  overflow: auto;
  text-align: center;

  img {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }

  &-large {
    cursor: zoom-out;
  }

  .imageslider-close {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &-thumbnails {
    width: 80px;
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    height: 100vh;
    flex-direction: row;

    img {
      margin: 2px 5px;
      width: 100%;
      height: auto;
      border: 3px solid rgba(255, 255, 255, 0.9);
    }
  }

  @media only screen and (max-width: map-get($breakpoints, medium) - 1) {
    &-thumbnails {
      display: none;
    }
  }
}

.material-property {
  &-container {
    margin-bottom: 5px;
    padding-top: 5px;
    border-top: 1px solid #ddd;

    &-content {
      width: 80%;
    }
  }


  &-identifier {
    display: flex;
    padding: 5px 0;
    margin: 5px 0;

    &:last-child {
    }

    &-title {
      display: inline-block;
      font-weight: 700;
      width: 20%;
      @media only screen and (max-width: map-get($breakpoints, xxmedium) - 1) {
        width: 40%;
      }
      @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
        width: 20%;
      }
    }

    &-material {
      display: inline-block;
      width: 20%;
      @media only screen and (max-width: map-get($breakpoints, xxmedium) - 1) {
        width: 50%;
      }
      @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
        width: 30%;
      }
    }

    &-value {
      display: inline-block;
      width: 10%;
      text-align: right;
    }
  }
}

.productsize-property {
  &-container {
    margin-bottom: 5px;
    padding-top: 5px;
    border-top: 1px solid #ddd;

    &-dimension-unit {
      color: #4A4A4A;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
    }
  }

  &-identifier {
    padding: 0;
  }

  &-identifier {
    &:last-child {
    }

    &-title {
      display: inline-block;
      font-weight: 700;
      width: 10%;
    }

    &-productsize {
      display: inline-block;
      width: 40%;
    }

    &-value {
      display: inline-block;
      width: 10%;
      text-align: right;
    }
  }
}

.table-sizes {
  margin-bottom: 10px !important;

  tr {
    border-bottom: 1px solid #E7E7E7;

    &:last-child {
      border-bottom: none;
    }
  }

  th {
    padding: 7px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    text-align: center;
  }

  td {
    position: relative;
    padding: 7px;
    color: #000000;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    border-bottom: none;
  }

  &-label {
    color: #4A4A4A !important;
    font-size: 11px !important;
    line-height: 15px !important;
    text-align: left !important;
    padding-left: 0 !important;

    &-header {
      padding-left: 0 !important;
      text-align: left !important;
    }
  }
}

.no-border-top {
  border-top: none !important;
}

.no-border-bottom {
  border-bottom: none !important;
}

#cboxContent {
  padding: 45px;
}

#cboxLoadedContent {
  padding: 25px;
}

#cboxClose {
  top: 10px;
  right: 20px;
  background-size: 16px;
  filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(94deg) brightness(101%) contrast(101%);
}

.cboxPhoto {
  //width: auto !important;
  //height: auto !important;
  border: none;
}

// Bugfix, on switching background color switch icon color, when user click on header icon (ex. login, search, cart)
.switch-icon-color {
  fill: $c-darkgray !important;
  filter: invert(0%) sepia(0%) saturate(11%) hue-rotate(348deg) brightness(101%) contrast(102%) !important;
}

// Overriding styles in search results
.search-results-wrap {
  .add-to-wl, .ic-cart {
    margin-right: 10px !important;
    display: inline-block !important;
  }

  .product-list-name {
    margin: 0 !important;

    .product-list-smaller &,
    .product-list-smaller-four-in-row & {
      max-width: 100%;
    }
  }

  .product-single {
    &-info {
      display: inline-block !important;
    }
  }

  .prod-shopbylook {
    position: relative !important;
    top: 0 !important;
    margin-top: 20px !important;
  }
}

.product-detail-outerwrap {

  .product-list-smaller {
    @extend .product-list;
    justify-content: unset;
    margin: 0 -10px 35px -10px;

    @media only screen and (min-width: map-get($breakpoints, xmedium)) {
      margin: 0 -10px 0 240px;
    }

    .product-list-single {
      position: relative;
      width: 50%;
      padding: 0 10px;
      max-width: unset !important;
      @media only screen and (min-width: map-get($breakpoints, medium)) {
        width: 33.3%;
      }
      @media only screen and (min-width: map-get($breakpoints, xlarge)) {
        width: 16.6%;
      }
    }
  }
}

.product-detail {
  .info-box-wrap {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    justify-content: space-between;
    margin-bottom: 20px;

    @include breakpoint($view-switch) {
      margin-bottom: 11px;
    }
  }

  .accordion-wrap.product-detail-hide-until-desktop-large {
    margin-left: 250px;
    margin-top: 70px
  }

  .slick-dots {
    z-index: 1;
  }
}

.product-detail--phone-shop {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;

  // from 480
  @include breakpoint(480px) {
    grid-gap: 40px;
    grid-template-columns: auto 1fr;
  }
}

.product-detail--phone {
  text-align: left;
}

.product-detail--search-store {
  text-align: left;

  &-link {
    display: inline-flex;
    min-height: 31px;
    padding: 4px 0 4px 40px;
    background: sassvg("ic-pin-store") no-repeat left center;
    align-items: center;
    text-decoration: none;
    color: $c-text;
    font-weight: 300;

    &:hover {
      text-decoration: underline;
      color: $c-text;
    }
  }
}

.product-detail-row-space {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  justify-content: space-between;
}

.product-size-hidden,
.product-size-mapper {
  display: none;
}

.product-detail-info-list {
  margin-top: 30px;
  margin-bottom: 5px;

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
}

.product-detail-info-single {
  color: #A2A2A2;
  font-size: 12px;
  line-height: 22px;
}

.product-detail-infos-container {
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid $product-detail-border;

  &.product-detail-hide-until-desktop-large {
    margin-left: 250px;
    margin-top: 50px;
    margin-bottom: 50px;
    border-bottom: none;
  }
}

.product-detail-title {
  margin-bottom: 25px;
  color: $black;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;

  @include breakpoint($view-switch) {
    font-size: 12px;
  }
}

.product-detail-product-colors {
  margin-bottom: 40px;
}

.product-detail-price-divider {
  background: $product-detail-border;
}

.product-detail-video-container {
  position: relative;
  line-height: 0;
  @include breakpoint(768px) {
    width: 100%;
    padding-top: 140%; // proportion
    overflow: hidden;
  }
}

.product-detail-hide-until-desktop-large {
  display: none;

  @include breakpoint($view-switch) {
    display: block;
  }
}

.product-detail-hide-from-desktop-large {
  display: block;

  @include breakpoint($view-switch) {
    display: none;
  }
}

.article-is-temporarily-soldout-message {
  .product-detail--search-store-link {
    margin-top: 10px;
  }
}

// Image zoom
figure.zoom {
  background-position: 50% 50%;
  position: relative;
  margin: 0;
  overflow: hidden;
  cursor: zoom-in;

  & img:hover {
    opacity: 0;
  }

  img {
    transition: opacity .5s;
    display: block;
    width: 100%;
  }

}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  height: 47px;
  width: 30px;
  background: sassvg("ic-play", $white) no-repeat center;
  cursor: pointer;
}

.displayInlineBlock {
  display: inline-block !important;
}


.search-autocomplete-box {

  .product-list-base--item-data-top {
    margin-bottom: 5px !important;
  }

  .product-list-base--item-name {
    font-size: 14px !important;
  }

  .product-list-base--item-price {
    font-size: 14px !important;
  }

  .product-list-base--item-name {
    min-height: 22px !important;
    overflow: hidden;
  }
}

.product-list-indexedsearch {

  .product-list-base--item {
    margin-bottom: 60px !important;
  }

  .product-list-base--item-data-top {
    margin-bottom: 5px !important;
  }

  .product-list-base--item-name-link {
    font-size: 14px !important;
  }

  .product-list-base--item-price {
    font-size: 18px !important;
  }

  .product-list-base--item-name {
    min-height: 22px !important;
    overflow: hidden;
  }
}

@include until($tablet) {
  .product-detail-slider {
    border: 1px solid #f2f2f2;
    width: 100%;
    overflow: hidden;
  }
  .product-detail-images {
    min-width: 100% !important;
    min-height: 65vh !important;
    //border: 1px solid red !important;
  }
  .product-detail-slider {
    min-width: 100% !important;
    min-height: 65vh !important;
    //border: 1px solid green !important;
    .slick-list {
      min-width: 100% !important;
      min-height: 65vh !important;
      //border: 1px solid blue !important;
      .slick-track {
        min-width: 100% !important;
        min-height: 65vh !important;
        //border: 1px solid orange !important;
        .slick-slide {
          min-width: 100% !important;
          min-height: 65vh !important;
          //border: 1px solid yellow !important;
        }
      }
    }
  }
}


//.lang-rs,
.lang-me,
.lang-ba
//.lang-mk,
{
  .price--has-discount {
    display: inline-block;
    padding: 0 5px;
    // background: #ffe729;
    background: $actionred;
    color: $white !important;
  }
}

.product-list-base--item-price-multi-discount {
  display: inline-block;
  margin-right:10px;
}