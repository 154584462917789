.ce_text_two_col {

  &__wrapper {
    @include txt-ce-margin;
  }
  &__container {

  }
  &__inner {
    max-width: 374px;
    margin: auto;
  }
  &__headline {
    @include new_h2 ($family: $f-krub);
    line-height: 54px;
  }
  &__subheadline {
    @include new_h3 ($family: $f-krub);
    margin-top: 20px;
  }
  &__content {
    @include new_default;
    &_holder {
      display: grid;
      grid-template-columns: 1fr;
    }
    &_col1 {

    }
    &_col2 {

    }
    @include new_default;
    h1 {
      @include new_h1 ($family: $f-krub);
    }
    h2 {
      @include new_h2 ($family: $f-krub);
    }
    h3 {
      @include new_h3 ($family: $f-krub);
    }
    a {
      @include font($size: 14px,
      $weight: bold,
      $color: #000000,
      $line: 18px);
    }
  }

}


// breakpoint(small down) ---> max 480
@include breakpoint(xxsmall down) {
  .ce_text_two_col {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: unset;
    }
    &__headline {

    }
    &__subheadline {

    }
    &__content1 {

    }
    &__content2 {

    }

  }
}


// breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_text_two_col {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: $txt-maxwidth-tablet;

    }
    &__headline {

    }
    &__subheadline {

    }
    &__content {
      &_holder {
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
      }
      &_col1 {

      }
      &_col2 {

      }
    }

  }
}

// breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_text_two_col {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: $txt-maxwidth-desktop;
    }
    &__headline {

    }
    &__subheadline {

    }
    &__content {
      &_holder {

      }
      &_col1 {

      }
      &_col2 {

      }
    }

  }
}



