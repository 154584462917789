/* Arrows */

.slick-prev,
.slick-next {
	appearance: none;
    position: absolute;
    display: block;
    z-index: 8;
    width: 36px;
    height: 36px;
    cursor: pointer;
    background-color: transparent;
    //@include arrow-icon($black);
    //background-image: url(/assets/workfiles/project/css/icons/SVG/arrow-activeR.svg);
    color: transparent;
    top: 40%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    @include transition;
    opacity: 0.5;
    .one-item-slider & {
	    border: 1px solid $black;
	    border-radius: 5px;
    }
    &:hover {
        outline: none;
        opacity: 1;
    }    
    &.slick-disabled {
        opacity: 0.2;
    }
}
.slick-prev {
	left: 0;
    transform: translate(0, -50%);
    .one-item-slider &,
    .video-slider & {
	    left: 5px;
	    @include breakpoint(xxmedium) {
			left: -50px;
		}
    }	
}
.slick-next {
	right: 0;
    .one-item-slider & ,
    .video-slider & {
	    right: 5px;
		@include breakpoint(xxmedium) {
			right: -50px;
		}
    }
	
}


/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
    z-index: 3;
	.one-item-slider & {
		position: absolute;
		bottom: 55px;
		left: 20px;
	}
	.slider-full-holder &,
	.product-detail-slider & {
	    @include vertical-center-content;
		right: 15px;
	    @include breakpoint(xmedium) {
		    right: 30px;
		}
	}
	.blog-list-image-slider & {
		position: absolute;
		right: 10px;
		bottom: 15px;
	}
    li {
        position: relative;
        height: 30px;
	    width: 30px;
        @include breakpoint(xmedium) {
	        height: 20px;
	        width: 20px;
	    }
        padding: 0;
        margin: 0 5px;
        cursor: pointer;
        display: inline-block;
        .slider-full-holder & ,
		.product-detail-slider & {
	        display: block;
			margin: 20px 0;
        }
        button {
	        appearance: none;
            background: transparent;
            display: block;
            height: 30px;
            width: 30px;
            @include breakpoint(xmedium) {
	            height: 18px;
	            width: 18px;
	        }
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            border:1px solid $white;
          opacity: 0.3;
            //box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
            border-radius: 100%;
            &:hover, &:focus {
              opacity: 1;
            	//background: $white;
            }

        }
        &.slick-active button {
              opacity: 1;
	        //background: $white;
        }
    }
}

.slick-vertical .slick-slide {
	border: none;
	&.img-full-holder {
		@include breakpoint(xmedium) {
			height: calc(100vh - 52px); //footer-height
		}
		height: 80vh;
	}
}
