.ce_three_col_image_portrait {
  &__wrapper {

  }
  &__container {

  }
  &__inner {
    margin: 0 auto;
    max-width: 355px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  &__img {
    width: 100%;
    height: auto;
    line-height: 0;
  }
  &__text {
    padding: 0;
    margin-top: 10px;
    @include col_img_txt;
    p {
      margin-top: 0;
    }
  }
  &__col {
    line-height: 0;
  }
  &__link {
    line-height: 0;
  }
}


// breakpoint(small down) ---> max 375
@include breakpoint(small down) {
  .ce_three_col_image_portrait {
    
    &__inner {
      
    }
  }
}


// breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_three_col_image_portrait {

    &__wrapper {

    }
    &__container {

    }
    &__inner {

    }
    &__text {
      padding: 0 10px;
    }
  }
}

// breakpoint(large) ---> min 1024
@media only screen and (min-width: map-get($breakpoints, xxmedium) - 1)  {
  .ce_three_col_image_portrait {

    &__wrapper {
      
    }
    &__container {

    }
    &__inner {
      max-width: 965px;
      grid-template-columns: 1fr 1fr 1fr;
    }

  }
}

// breakpoint(large) ---> min 1920
@include breakpoint(wide) {
  .ce_three_col_image_portrait {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: 1850px;
    }

  }
}