$header-inner-left-margin: 30px;
$header-links-padding-mobile: 7px 0;
$header-links-padding: 5px 0;

.header-buttons {
  display: grid;
  align-items: center;
}

.header-right {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $black;
  //height: 60px;
  z-index: 10;
  text-align: center;

  //@include breakpoint(xmedium) {
  //  position: relative;
  //  bottom: unset;
  //  background: transparent;
  //  z-index: $overlay-z-index + 1;
  //}

  @include from($desktop) {
    position: relative;
    bottom: unset;
    background: transparent;
    z-index: $overlay-z-index + 1;
    text-align: left;
  }

  .hide-header-icons & {
    display: none;
  }

}

.loading-title {
  display: none;

  @include from($desktop) {
    display: block;
    min-height: 46px;
    max-width: $wide-screen;
    margin: auto;
    padding: 10px 0;
  }
}

//.lang-mk .header-links-list {
//  display: none;
//}

//.lang-mk .product-detail-addtocart {
//  display: none;
//}

.header-links-item {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  @include until($desktop) {
    //vertical-align: top;
    a {
      width: 68px;
      color: $white;
      text-align: center;
      text-decoration: none;
    }
    .icon {
      display: block;
      margin: 0 auto;
    }
  }

  //.ic-search-top {
  //  @include from($desktop) {
  //    position: absolute;
  //    left: 20px;
  //    top: 50%;
  //    transform: translateY(-50%);
  //  }
  //}

  .search-toggle {
    @include from($desktop) {
      padding: 0;
      margin-right: 0;
    }

    &:hover {
      @include from($desktop) {
        border-bottom: 2px solid transparent;
      }
    }
  }
}

.ic-search-top-btn {
  display: block;
  margin: auto;
  border: none;
  background: transparent;
  cursor: pointer;
  pointer-events: none;

  @include from($desktop) {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    pointer-events: unset;
  }
}

.header-links-item a {
  cursor: pointer;
  display: block;

  padding: 8px 0 8px;
  margin: 1px 5px 0;
  border-bottom: 2px solid transparent;
  @include transition;

  @include from($desktop) {
    padding: 9px 13px 6px;
    margin: 0 5px 0 8px;
  }

  &:hover, &.active {
    border-bottom: 2px solid $black;
  }

  &.open {
    background: $white;
    color: $black;
  }

  .text {
    display: inline-block;
    font-size: 10px;
    line-height: 1.2;
    text-transform: uppercase;
    @include from($desktop) {
      display: none;
    }
  }

  &.cart-toggle {

    &.hide-on-mobile {
      display: none !important;
      @include from($desktop) {
        display: block !important;
      }
    }

    &.show-on-mobile {
      display: block !important;
      @include from($desktop) {
        display: none !important;
      }
    }
  }

}

.header-search {
  display: none;
  position: fixed;
  top: auto;
  bottom: 65px;
  z-index: 10;
  right: 0;
  background: $white;
  width: 100%;
  padding: 15px 35px 15px 15px;
  @include from($desktop) {
    z-index: $overlay-z-index + 1;
    padding: 22px 20px 26px 20px;
    bottom: auto;
    top: 81px;
    left: 0;
    right: 0;
  }

  &-form {
    position: relative;

    @include from($desktop) {
      display: none;
    }

    a.icon-close {
      position: absolute;
      right: -20px;
      @include breakpoint(xmedium) {
        right: -30px;
      }
    }


    &-input {
      width: 100%;
      @include breakpoint(xmedium) {
        font-size: 28px;
        height: 50px;
      }
      border-radius: 0px;
      border: none;
      border-bottom: 1px solid $c-border;
      padding: 0 35px 16px 0;

      &::-webkit-input-placeholder {
        @include breakpoint(xmedium) {
          font-size: 28px;
        }
        color: $black;
      }
    }

    .btn-search {
      position: absolute;
      right: 0;
      top: 0;
    }
  }


  .product-list-three-cols--item-wrap {
    @include from($wide-screen) {
      max-width: $wide-screen + 40px;
      margin: auto;
    }

    .product-list-base--item {
      @include from($desktop) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @include from($desktop-large) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      @include from($wide-screen) {
        flex: 0 0 16.6%;
        max-width: 16.6%;
      }
    }
  }
}

.icon-close-search-desktop-container {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;

  @include from($wide-screen) {
    max-width: $wide-screen;
    margin: auto;
  }
}

.icon-close-search-desktop {
  display: none;

  @include from($desktop) {
    display: block;
    line-height: 46px;
  }
}

.page-header {
  position: relative;
  z-index: 9;
}

.header-container {
  position: fixed;
  top: 0;
  left: 20px;
  right: 20px;

  @include from($desktop) {
    right: 0;
    left: 0;
  }
}

.header-container--wrapper {

  @include from($desktop) {
    border-bottom: 1px solid $nav-border-rgba;
    background: $white;
  }
}

.header-container--inner {
  @include from($desktop) {
    display: grid;
    grid-template-columns: 80px 1fr auto;
    grid-gap: 40px;
    max-width: $wide-screen;
    margin: 0 20px;
  }

  @include from($wide-screen) {
    margin: auto;
    grid-gap: 60px;
  }
}

.header-nav {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 64px;
  overflow-x: hidden;
  padding: 80px 12px 30px 30px;
  background: $white;
  min-width: 300px;
  z-index: 6;

  @include from($desktop) {
    position: unset;
    top: unset;
    right: unset;
    bottom: unset;
    display: grid;
    grid-template-columns: 1fr;
    min-width: unset;
    padding: 0;
    overflow: unset;
  }

  &.nav-mobile-open {
    display: block;
    margin-top: 30px;
  }

  img {
    display: none;
    @include from($desktop) {
      max-width: 363px;
      display: inline-block;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      margin-bottom: 1px;

      @include from($desktop) {
        margin-bottom: 0;
      }

      a {
        position: relative;
        display: inline-block;
        color: $c-text;
        font-size: 12px;
      }

      &.item-has-sub {
        & > a {
          // Used for mobile navigation
          // If has submenu then make space for triangle icon
          margin-right: 50px;
          @include from($desktop) {
            margin-right: 0;
            &:after {
              display: none;
            }
          }
        }

        //& > input[type=checkbox]:checked + a {
        //  color: purple
        //}
      }
    }
  }

  input[type=checkbox] {
    @include from($desktop) {
      display: none;
    }
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;

    &:checked + label {

    }

    // Opens sub menu on mobile
    &:checked ~ .header-nav-children-wrapper {
      display: block;
      opacity: 1;
      visibility: visible;
      height: auto;
      transition: 0.2s all;
      @include from($desktop) {
        display: none;
      }
    }
  }

  @include from($desktop) {
    margin: auto 0;
    padding: 0;
    border-top: none;
    border-bottom: none;
  }
}

.header-nav-children--right {
  display: none;
  @include from($desktop) {
    display: grid;
    grid-template-columns: 200px 200px;
    grid-gap: 20px;
    line-height: 0;
  }
  @include from($desktop-large) {
    grid-template-columns: 280px 280px;
  }
}

.header-nav-children--left-nav {
  display: grid;
  grid-template-columns: 1fr;
  @include from($desktop) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
}

// creates hamburger icon
@include hamburgerV2(
        $class: "mob-menu",
        $right: 20px
);

/*************** STYLE **************/
.header--logo {
  width: 60px;
  height: 60px;
  margin-top: 10px;

  @include from($desktop) {
    margin-top: unset;
    width: 80px;
    height: 80px;
  }

  &-link {
    z-index: 7;
    line-height: 0;
  }
}

.header-nav-children-wrapper {
  display: none;
  margin-top: 10px;
  z-index: 1;
  background-color: $white;
  @include from($desktop) {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    margin-top: unset;
  }
}

.header-nav-children-wrapper-inner {
  @include from($desktop) {
    margin-top: 20px;
    padding-top: 30px;
    border-top: 1px solid $nav-border-rgba;
  }


}

.header-nav-children-wrapper-position {
  max-width: $wide-screen;
  @include from($wide-screen) {
    margin-left: auto;
    margin-right: auto;
  }
}

.header-nav-children-wrapper-grid {
  @include from($desktop) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
    margin-left: 150px; // logo width + grid gap
    margin-right: 20px;
    padding-bottom: 30px;
    max-width: $wide-screen;
  }
  @include from($desktop-large) {
    margin-left: 160px;
  }

  @include from($wide-screen) {
    margin-right: 0;
  }

}

.header-nav-children--right-teaser {

  &:hover {
    .header-nav-children--right-teaser-cta {
      background-color: $black;
      color: $white;
      transition: 0.5s all;
    }

    .header-nav-children--right-teaser-cta.teasers-cta-white {
      background-color: $white;
      color: $black;
      transition: 0.5s all;
    }
  }

  &-image {
    width: 100%;
    height: auto;

    &-container {
      position: relative;
      line-height: 0;
    }
  }

  &-cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 20px;
    border: 1px solid $black;
    border-radius: 3px;
    white-space: nowrap;
    color: $black;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    transition: 0.5s all;

    &.teasers-cta-white {
      border: 1px solid $white;
      color: white;
    }
  }
}

.header-nav-children--left {
  @include from($desktop) {
    display: grid;
    grid-gap: 10px;
  }
}

.header-nav-children--left-special {
  margin-left: -30px;
  margin-right: -20px;
  background-color: $c-lightergray;

  @include from($desktop) {
    display: grid;
    margin-left: -$header-inner-left-margin;
    margin-right: 0;
    align-content: end;
    background-color: unset;
  }

  &-list {
    margin-left: $header-inner-left-margin !important;
    margin-top: 30px !important;
    padding: 25px 0 !important;

    @include from($desktop) {
      margin-left: 0 !important;
      margin-top: 0 !important;
      padding-left: $header-inner-left-margin !important;
      background-color: $c-lightergray;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include desktop() {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
  }

  &-item {
    //@extend .header-nav-item-level-1;
  }

  &-link {
    padding: $header-links-padding-mobile;

    @include from($desktop) {
      padding: $header-links-padding;
    }

    &:hover {
      text-decoration: underline !important;
      color: $black
    }
  }
}

.header-search-desktop-input {
  display: none;
  width: 200px;
  padding-left: 40px;
  border-radius: 2px;
  background-color: $c-lightergray;
  border: none;

  @include from($desktop) {
    display: initial;
  }

  @include from($wide-screen) {
    width: 250px;
  }

  &::placeholder {
    color: $c-text;
    font-size: 12px;
    font-style: italic;
  }
}

/****** Navigation - root level ********/
// ul 0
.header-nav-list-level-0 {
  & > li:not(:last-child) {
    margin-bottom: 15px;
  }

  @include from($desktop) {
    display: flex;
    align-items: center;
    & > li:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

// li 0
.header-nav-item-level-0 {
  position: relative;

  @include from($desktop) {
    position: initial !important;
    text-align: left;
    padding: 10px;

    &:hover {
      .header-nav-link-level-0 {
        &:after {
          position: absolute;
          bottom: -28px;
          display: block;
          content: '';
          height: 2px;
          width: 100%;
          background-color: $c-middlegray;
          z-index: 2;
        }
      }
    }

    //&.header-nav-item-level-0-active {
    .header-nav-link-level-0.header-nav-link-level-0-active {
      color: $black;

      &:after {
        position: absolute;
        bottom: -28px;
        display: block;
        content: '';
        height: 2px;
        width: 100%;
        background-color: $black;
        z-index: 2;
      }
    }
    //}

    & > .header-nav-children-wrapper {
      //padding-top: 20px;
    }
  }

  @include from($desktop-large) {
    padding: 10px 20px;
  }

  &:hover {
    & > a {
      color: $black;
    }
  }

  &-image {
    @include from($desktop) {
      display: block;
    }
  }

  &.item-has-sub {
    &:hover {
      @include from($desktop) {
        & .header-nav-children-wrapper {
          display: block !important;
        }

      }
    }
  }

}

// a 0
.header-nav-link-level-0 {
  @include until($desktop) {
    font-size: 14px !important;
  }
  @include from($desktop) {
    margin-right: 0;
    white-space: nowrap;
    color: $c-text;
    font-size: 12px;
  }

  &-active {

  }

  &-current {
    text-decoration: underline;
  }

}


/****** Navigation - Level 1 ********/
// li 1
.header-nav-item-level-1 {

  //&:hover {
  //  .header-nav-link-level-1 {
  //    color: $black;
  //    text-decoration: underline;
  //  }
  //}
}

.header-nav-item-level-1-triangle {
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  background: sassvg("arrow-20", $c-text-mobile) no-repeat center;
  background-size: 15px;
  transform: rotateX(180deg);
  cursor: pointer;
  transition: 0.2s all;

  @include from($desktop) {
    display: none;
  }
}

.header-nav-item-level-1-checkbox:checked + .header-nav-item-level-1-triangle {
  background: sassvg("arrow-20", $black) no-repeat center;
  background-size: 15px;
  transform: rotateX(0deg);
  transition: 0.2s all;
}

.header-nav-item-level-1-checkbox:checked + div + a {
  color: $black;
}

// a 1
.header-nav-link-level-1 {
  padding: $header-links-padding-mobile;
  @include from($desktop) {
    padding: $header-links-padding;
  }

  @include until($desktop) {
    font-size: 13px !important;
    color: $c-text !important;
  }

  &:hover {
    text-decoration: underline !important;
    color: $black
  }

  &.header-nav-link-level-1-active {
    //color: $black;
    text-decoration: underline;
    //text-decoration-color: $black;
  }
}

.nav-color {
  &-grey {
    a {
      color: #A2A2A2 !important;
    }

    &:hover {
      filter: unset !important;
    }
  }

  &-red {
    a {
      color: #CF1515 !important;
    }

    &:hover {
      filter: unset !important;
    }
  }

  &-pink {
    a {
      color: #FF4DAB !important;
    }

    &:hover {
      filter: unset !important;
    }
  }

  &-black-fill {
    a {
      transition: all 0.3s ease;
      background-color: #000;
      padding: 2px 3px 0 3px;
      color: #ffffff !important;
    }

    &.nav-arrow.navlevel-trigger:hover {
      filter: none;
    }

    &:hover {
      a {
        transition: all 0.3s ease;
      }
    }
  }

  &-grey-fill {
    a {
      transition: all 0.3s ease;
      background-color: #A2A2A2;
      padding: 2px 3px 0 3px;
      color: #ffffff !important;
    }

    &.nav-arrow.navlevel-trigger:hover {
      filter: none;
    }

    &:hover {
      a {
        transition: all 0.3s ease;
      }
    }
  }

  &-red-fill {
    a {
      transition: all 0.3s ease;
      background-color: #CF1515;
      padding: 2px 3px 0px 3px;
      color: #ffffff !important;
    }

    &.nav-arrow.navlevel-trigger:hover {
      filter: none;
    }

    &:hover {
      a {
        transition: all 0.3s ease;
      }
    }
  }

  &-pink-fill {
    a {
      transition: all 0.3s ease;
      background-color: #FF4DAB;
      padding: 2px 3px 0 3px;
      color: #ffffff !important;

    }

    &.nav-arrow.navlevel-trigger:hover {
      filter: none;
    }

    &:hover {
      a {
        transition: all 0.3s ease;
      }
    }
  }
}