footer {
  // Needed for sticky footer
  left: 0;
  bottom: 0;
  position: relative;
  width: 100%;
  background: $black;
  @include transition;
  border-top: 2px solid $c-darkgray;

  .footer--region-selector--link,
  .footer--region-selector--label {
    color: $white;
  }

  .footer-social-link {
    .icon {
      fill: $white;
    }
  }

  @include from($desktop) {
    z-index: 9;
    position: fixed;
    margin-bottom: 0;
    height: $footer-closed-tablet;
    .footer-nav-list {
      display: none !important;
    }
    &.footer-open {
      height: $footer-height-tablet;

      .footer-nav-list {
        display: block !important;
      }
    }
    //}
    //@include breakpoint(900px) {
    height: $footer-closed;
    .footer-nav-list {
      display: block;
    }
    &.footer-open {
      height: $footer-height;
    }
  }
}

.at-image-sharing-tool {
  z-index: 8 !important;
}

.newsletter-toggle {
  color: $white;
  text-transform: uppercase;
}

.footer-container {
  @extend .inner-row;
  position: relative;
  width: 100%;
  padding: 0 20px 40px 20px;
  @include breakpoint(xmedium) {
    justify-content: space-between;
    max-width: 97%;
    margin: 0 auto;
    padding: 0 20px 40px 20px;
  }

}

.footer-left {
  margin-left: 0;
  @include breakpoint(xxmedium) {
    max-width: 680px;
  }
  @include breakpoint(900px) {
    max-width: 620px;
  }
  @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
    width: 100%;
    margin-top: 20px;
  }
}

.footer-right {
  @include breakpoint(946px down) {
    max-width: 240px !important;
  }
  @include breakpoint(769px down) {
    max-width: 100% !important;
  }
  @include breakpoint(769px up) {
    text-align: right;
  }
  @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  position: relative;

  .footer-payment {
    display: inline-flex;

    @include breakpoint(1024px down) {
      //display: flex;
      //float: right;
    }
    @include breakpoint(1420px down) {
      position: relative;
      //left: -15px;
    }
    @include breakpoint(large) {
      //left: -330px;
    }
    @include breakpoint(medium down) {
      //display: flex;
      //order: 3;
      //flex-direction: row;
      //position: absolute !important;
      //left: 5px;
      //bottom: 12px;
      //padding-bottom: 14px;
    }
    @include breakpoint(460px down) {
      //bottom: 30px;
    }


  }

  // Footer right container fixing bug
  @media screen and (max-width: 1420px) and (min-width: 899px) {
    max-width: 33%;
  }
  @media screen and (max-width: 900px) and (min-width: 769px) {
    //max-width: 39% !important;
  }
}

.footer-nav {
  font-size: 12px;
  margin-bottom: 25px;
  @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include breakpoint(xmedium) {
    margin-bottom: 0;
    @include inline(top);
    width: 120px;
  }
  @include breakpoint(xlarge) {
    width: 120px;
    margin-right: 10px;
  }

  &-item {
    line-height: 1.3;
    margin-bottom: 5px;

    a {
      color: $white;
    }
  }

  h4 {
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    pointer-events: auto;
    color: $white;
    @include breakpoint(xmedium) {
      pointer-events: none;
      margin-top: 18px;
      margin-bottom: 5px;
    }
    @include breakpoint(xxmedium) {
      margin-bottom: 15px;
    }

    &.open, .footer-open & {
      font-weight: 600;
    }
  }
}

.footer-nav-link {
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }

  .active & {
    font-weight: 600;
  }
}

.footer-nav-list {
  display: none;
  margin: 15px 0 30px;
  @include breakpoint(xmedium) {
    display: block;
  }
}

.footer-social {
  margin-top: 6px;
  @include breakpoint(1420px down) {
    margin-top: 20px; // Move social icons to new line
  }
  @media (min-width: 1440px) {
    margin-left: 10px;
  }
  @include inline;
  @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
    margin: 13px auto 0;
    display: table;
    margin-bottom: 20px;
  }
  @include breakpoint(medium down) {
    order: 1;
  }
  @include breakpoint(769px down) {
    margin: 20px 0;
  }
}

.footer-social-link {
  @include inline;
  @media (min-width: 1290px) {
    margin-left: 24px;
  }
  margin-left: 10px;
  border-bottom: 2px solid transparent;
  @include transition;

  &:hover {
    border-bottom: 2px solid $a-hover-active-color;
  }

  @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
    margin-left: 30px;
    &:first-child {
      margin-left: 0;
    }

  }
}

#toTop {
  display: none;
  position: fixed;
  z-index: $overlay-z-index - 1;
  @include transition;
  @media only screen and (max-width: 899px) {
    bottom: 70px;
  }
  bottom: 55px;
  right: 12px;
  width: 51px;
  height: 51px;
  font-size: 0;
  border-radius: 100%;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  margin: 0;
  padding-top: 17px;
  background: rgba(255, 255, 255, 0.3);
  @include breakpoint(xmedium) {
    width: 80px;
    height: 80px;
    font-size: 12px;
  }

  .icon {
    display: block;
    margin: 0 auto 10px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.85);
    transform: scale(1.1);
  }
}

.footer-logo-group {
  //display: flex;
  //padding-right: 100px;

  img {
    vertical-align: middle;
    border-radius: 5px;
    width: auto;
  }

  a {
    display: inline-block;
  }

  @include breakpoint(xmedium down) {
    margin-bottom: 20px;
  }

  &--top {
    margin-top: 40px;
    margin-bottom: 40px;
    @include breakpoint(xmedium down) {
      margin-top: 0;
      margin-bottom: 20px;
      display: flex;
    }

    img {
      @include breakpoint(xxmedium down) {
        max-height: 80px;
        width: auto;
      }
    }

    a {
      display: inline-block;
      margin-left: 20px;
    }
  }

  @include breakpoint(xxmedium down) {
    //justify-content: flex-end;
  }
  @include breakpoint(xmedium down) {
    float: right;
    //margin: 0;
  }
  @include breakpoint(768px down) {
    float: left;
    justify-content: left;
    //margin: 0;
  }
  @include breakpoint(medium down) {
    order: 2;
  }
  @include breakpoint(900px) {
    //margin: 20px 0 0 90px;
  }

  .lang-en &, .lang-sl & {
    background: url(/assets/img/project/logo/unicef_en@2x.png) no-repeat center;
    background-size: 118px 56px;
    @include breakpoint(900px) {
      background-size: 148px 71px;
    }
  }

  @include breakpoint(460px down) {
    position: relative;
    top: 35px;
    padding-right: 0;
  }

  .footer-unicef--icon,
  .footer-best-from-serbia--icon {
    backface-visibility: hidden;
    //filter: grayscale(100%);
    //transition: all 0.4s;
    //opacity: 0.7;

    //&:hover {
    //  filter: unset;
    //  opacity: 1;
    //}
  }

  .footer-unicef--icon {
    margin-left: 30px;
    @include breakpoint(xxmedium down) {
      margin-left: 15px;
    }
  }

  .footer-best-from-serbia--icon {
    width: 72px;
    height: 72px;
    @include breakpoint(xxmedium down) {
      width: 50px;
      height: 50px;
    }
  }

  //.footer-unicef--icon {
  //  width: 75px;
  //  height: 105px;
  //  @include breakpoint(xxmedium down) {
  //    width: 55px;
  //    height: 75px;
  //  }
  //}
}

.trust-mark {
  width: 100%;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
  line-height: 1.2;

  @include breakpoint(medium down) {
    display: none;
  }

  img {
    width: 200px;
    filter: grayscale(100%);
    transition: all 0.2s;
    opacity: 0.7;

    &:hover {
      filter: none;
      transition: all 0.2s;
      opacity: 1;
    }
  }
}

.trust-mark-mobile {
  margin-top: 40px;
  display: block;

  @include breakpoint(xmedium up) {
    display: none !important;
  }

  img {
    width: 200px;
    margin-left: 15px;
    //filter: grayscale(100%);
    //transition: all 0.2s;
    //opacity: 0.7;

    //&:hover {
    //  filter: none;
    //  transition: all 0.2s;
    //  opacity: 1;
    //}

    @include breakpoint(xxsmall down) {
      width: 150px;
    }
  }
}

.footer--region-selector {
  padding-top: 14px;
  width: 260px !important;
  margin-right: 30px !important;

  &--label {
    display: inline-block;
    text-transform: uppercase;
    margin-right: 10px;
  }

  &--link {
    padding: 8px;

    &-active {
      border: 1px solid #bbb;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1100px) {
    width: 120px !important;
    &--label {
      display: none;
    }
  }
}

//.lang-mk .page-footer {
//  display: none;
//}

.page-footer--navigation {
  background: #f3f3f3;
  margin-bottom: 50px;

  &-inner {
    @extend .inner-row;
    @include breakpoint(xmedium) {
      justify-content: left;
      max-width: 97%;
      margin: 0 auto 50px;
    }
  }
}

.payment-logo {
  width: 100%;
  height: 80px;
  @include breakpoint(769px up) {
    text-align: center;
  }
  @include breakpoint(351px down) {
    bottom: 37px;
    img {
      width: 44px;
      height: 28px;
    }
    .footer-payment--logo-american-express {
      width: 31px !important;

    }
    .footer-payment--logo--dina {
      width: 47px !important;
    }
  }

  .footer-payment--link {
    text-decoration: none;
  }

  .footer-payment--logo {
    margin-right: 2px;
  }

  .footer-payment--logo-american-express {
    margin-right: 6px;
  }

  .footer-payment--logo--dina {
    margin-right: -2px;
  }

  .footer-payment--logo {
    backface-visibility: hidden;
    filter: grayscale(100%);
    transition: all 0.4s;
    opacity: 0.7;

    &:hover {
      filter: unset;
      cursor: pointer;
      opacity: 1;
    }
  }
}


.cookie-settings-link-wrapper {
  @include breakpoint(xmedium) {
    position: absolute;
    bottom: 75px;
    left: 5px;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}