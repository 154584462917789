table {
	width: 100%;
    margin-bottom: 20px;
    border-radius: 0;
    border-collapse: collapse;
/*
	border-collapse: separate;
	border-spacing: 1px;
*/
	th,
	td {
		padding: 14px 8px;
		vertical-align: middle;
		text-align: left;
	}
	th {
		line-height: 1.2;
		padding: 17px 8px;		
	}
	caption {
		padding: 10px 8px;
		font-weight: bold; 
	}
	thead {
		background: #eee;
		th,
		td {
			font-weight: bold;
			color: $black;	  
		}
		th {
			position: relative;
			&:before, &:after {
				position: absolute;
				content: '';
				width: 100%;
				height: 5px;
// 				background: $white;
				border-bottom: 1px solid $c-middlegray;
				border-top: 1px solid $c-middlegray;				
			}
			&:before {
				top: 0;
				left: 0;
			}
			&:after {
				left: 0;
				bottom: 0;
			}
		}
	}
	tfoot {
		border-bottom: 1px solid $c-middlegray;
	}
	tbody {
		td {
			border:none;
			border-bottom: 1px solid $c-middlegray;
			border-collapse: separate;
			border-spacing: 1px;
		}
	}
	tr.no-border {
		td {
			border:none;
		}
	}
	tr.gray-info {
		background: $c-middlegray;
	}
}

table,
th,
td {
// 	border-collapse: coll;
}


@media only screen and (max-width: map-get($breakpoints, medium) - 1) {
  .stack-table {
	  thead {
	    display: none;
	  }
	  tfoot {
	    display: none;
	    border-bottom: none; 
	  }
	  tr,
	  th,
	  td {
	    display: block; 
	  }
	  td {
	    border-bottom: 0; 
	  } 	  
	  tr {
		 border-bottom: 1px solid $c-middlegray; 
	  }
  }
} 

