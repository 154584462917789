.checkout-additional-product-list {

  &__headline {
    margin-top: 30px;
    margin-bottom: 30px;
    //font-size: 28px;
    font-weight: bold;
  }

  &__grid {
    justify-content: left !important;

    & > .has-size:nth-child(n+3) {
      @include until($desktop) {
        display: none !important;
      }
    }

    .product-list-base--item {
      @include until($desktop) {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
    }
  }

  &__price {
    margin-bottom: 15px;
  }

  &__cart {
    margin: 0 !important;
    padding: 7px 15px 7px 14px !important;

    @include until($xmobile) {
      font-size: 12px !important;
      padding: 5px 16px !important;
    }

    &.disabled {
      background-color: $c-gray;
      pointer-events: none;
    }

    .ic-cart-top {
      background-size: 17px !important;
    }
  }
}

.checkout-additional-product-sizes {
  margin-bottom: 20px;

  &__title {
    margin-bottom: 15px;
    font-weight: bold;
  }

  &__item-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
  }

  &__item {
    padding: 5px;
    margin-right: 3px;
    line-height: 1;
    cursor: pointer;

    @include until($xmobile) {
      font-size: 12px;
    }

    &.selected {
      background-color: $c-text;
      color: $white;
      border-radius: 4px;
    }
  }
}