.ce_two-img-priceL {
  &__inner {
    margin: auto;
  }

  &__single {
    display: grid;
    grid-template-areas: 'holder1 holder2'
                         'info info';
    grid-template-columns: 1fr 1fr;

    &_holder {
      line-height: 0;

      .iframe-container {
        margin-bottom: 0 !important;
      }

      a {
        line-height: 0;
      }
    }

    &_holder1 {
      grid-area: holder1;
    }

    &_holder2 {
      grid-area: holder2;
    }

    &_info {
      grid-area: info;
      text-align: left;
      line-height: 0;

      &_row {
        .ce_single_product_name {
          margin-right: 0;
        }
      }

      &_row2 {
        display: inline-grid;
        grid-template-columns: 20px auto;
        grid-gap: 10px;
        padding-left: 0 !important;
        line-height: 1;

        @include from($desktop) {
          display: grid;
        }
        @include from($desktop-large) {
          display: inline-grid;
        }

        &-icon {
          display: flex;
          align-items: flex-end;

          .ic-cart {
            width: 17px;
            height: 20px;

            @include from(641px) {
              width: 20px;
              height: 23px;
            }
          }
        }
      }
    }
  }

  &__holder {
    @include video_holder;
  }

  &__player {
    @include player;
  }

}

// @include breakpoint(xxmedium) --> max 360
@include breakpoint(small down) {
  .ce_two-img-priceL {
    &__inner {
      max-width: 376px;
    }

    &__single {
      grid-template-columns: 1fr 1fr;
    }

  }
}

/// @include breakpoint(xmedium) ---> min 768
@include from($tablet) {
  .ce_two-img-priceL {
    &__inner {
      margin-right: 6px;
      margin-left: 6px;
    }

    &__single {
      grid-template-areas: 'holder1 holder2'
      'info info';
      grid-template-columns: 1fr 1fr;
    }

  }
}

/// @include breakpoint(xmedium) ---> min 1024px
@include from($desktop) {
  .ce_two-img-priceL {
    &__inner {
      margin-right: 12px;
      margin-left: 12px;
    }

    &__single {
      grid-template-areas: ' info holder1 holder2';
      grid-template-columns: 1fr 1fr 1fr;

      &_info {
        align-self: end;
        text-align: right;

        &_row {
          .ce_single_product_name {
            margin-right: 15px;
          }
        }

        &_row2 {
          display: inline-grid;
        }
      }

    }

  }
}

/// @include breakpoint(large) ---> min 1200
@include from($desktop-large) {
  .ce_two-img-priceL {

    &__single {
      grid-template-areas: 'holder1 holder2'
                           'info info';
      grid-template-columns: 1fr 1fr;

      &_info {
        align-self: unset;
        text-align: left;

        &_row {
          .ce_single_product_name {
            margin-right: 0;
          }
        }
      }
    }

  }
}

/// @include breakpoint(xmedium) ---> min 1480px
@include from($ultra-wide-screen) {
  .ce_two-img-priceL {
    &__inner {
      margin-right: 20px;
      margin-left: 20px;
    }

    &__single {
      grid-template-areas: 'info holder1 holder2';
      grid-template-columns: 1fr 1fr 1fr;

      &_info {
        align-self: end;
        text-align: right;

        &_row {
          .ce_single_product_name {
            margin-right: 15px;
          }
        }
      }
    }

  }
}



