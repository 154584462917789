.ce_portrait_mapping {

	&__wrapper {
		@include mobile_mapping;
	}
	&__container {

	}
	&__inner {
		margin: auto;
		max-width: 375px;
	}
	&__singleitem {
		&_desktop {
			display: none;
		}
		&_tablet {
			display: none;
		}
		&_mobile {
			display: block;
		}
	}

}

// @include breakpoint(xxmedium) --> max 768px
//@include breakpoint(xxmedium down) {
//	.ce_portrait_mapping {
//
//		&__wrapper {
//			@include mapping-popup-mobile;
//		}
//		&__container {
//
//		}
//		&__inner {
//
//		}
//		&__content {
//
//		}
//
//	}
//}

// @include breakpoint(xxmedium) --> max 360
@include breakpoint(small down) {
	.ce_portrait_mapping {

		&__wrapper {

		}
		&__container {

		}
		&__inner {
			max-width: 100%;
		}
		&__content {

		}

	}
}

/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
	.ce_portrait_mapping {

		&__wrapper {

		}
		&__container {

		}
		&__inner {
			max-width: 515px;
		}
		&__singleitem {
			&_desktop {
				display: none;
			}
			&_tablet {
				display: block
			}
			&_mobile {
				display: none;
			}
		}

	}
}

/// @include breakpoint(large) ---> min 1200
@include breakpoint(large) {
	.ce_portrait_mapping {

		&__wrapper {
			.mappedimage-singleitem-popup {
				display: none;
			}
		}
		&__container {

		}
		&__inner {
			max-width: 600px;
		}
		&__singleitem {
			&_desktop {
				display: block;
			}
			&_tablet {
				display: none;
			}
			&_mobile {
				display: none;
			}
		}

	}
}



