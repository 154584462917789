.mobile-cart-notice, .mobile-wishlist-notice {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  text-align: center;
  padding: 5px;
  color: $white;
  background: $newblack;
  font-size: 12px;

  p {
    margin: 0;
  }

  @include breakpoint(xmedium) {
    display: none !important;
  }
}

.cart-mobile-data {
  @include breakpoint(990px) {
    display: none;
  }
}

.cart-empty {
  display: block;
  margin: 20px 0;
}

.addProductToCart.disabled {
  pointer-events: none;
  opacity: 0.5 !important;
}

.cart-preview-headline {
  pointer-events: none;

  .icon-close {
    position: absolute;
    right: 20px;
    pointer-events: auto;
  }
}

.cart-preview-wrap {
  margin-left: 10px;
  margin-right: 10px;
  max-width: 950px;

  @include until($desktop) {
    margin-top: $top-margin !important;
  }

  @include breakpoint(xmedium) {
    //margin-left: $headernav-width;
    margin: 0 auto;
  }

  .product-list-base--item {
    opacity: 1;
  }

  .product-list-smaller {
    margin-left: 0 !important;
  }

  .product-related-headline {
    margin-left: 0 !important;
  }
}

.product-name-link {
  text-decoration: none;
}

.cart-table {
  @include breakpoint(990px) {
    border-bottom: 1px solid $c-middlegray;
  }

  td:first-child, th:first-child {
    padding-left: 0;
  }

  thead {
    background: none;
  }

  tbody {
    td {
      &:first-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    th,
    td {
      &:nth-child(2) {
        text-align: left;
        padding-left: 0;
      }
    }
  }

  .prod-img-link {
    display: block;
    width: 75px;
    height: 85px;

    img {
      max-width: 100%;
      border: 1px solid $c-lightgray;
      font-size: 9px;
    }
  }

  tr.empty td {
    border-bottom: none;
    padding: 8px;
  }

  span[class^=color-dot] {
    margin: 0 auto;
  }

  .cart-total-labels, .cart-total {
    vertical-align: top;
  }

  .cart-total {
    text-align: right;

    &.cart-total-overallcosts {
      vertical-align: bottom;
    }
  }

  @media only screen and (max-width: 990px) {
    .empty {
      display: none;
    }
  }
  @media only screen and (max-width: 480px) {
    .product-name {
      display: inline-block;
      max-width: 150px;
    }
  }

  .cart-prod-name {
    span[class^=color-dot] {
      display: none;
    }
  }

  @media only screen and (max-width: 990px) {
    background: $white;
    thead {
      display: none;
    }
    tr,
    th,
    td {
      display: block;
      padding-top: 0;
      padding-bottom: 0;
    }
    td {
      border-bottom: 0;
    }
    .cart-prod-img, .cart-prod-name {
      @include inline(top);
    }
    .cart-prod-name {
      width: 63%;
      line-height: 1.2;

      .separator {
        display: none;
      }

      span[class^=color-dot] {
        @include inline(top);
        width: 14px;
        height: 14px;
        margin: 0 0 0 7px;
      }
    }
    .product-name-link {
      display: block;
      margin-bottom: 10px;
      font-weight: 700;
    }
    .cart-prod-color, .cart-prod-size, .cart-prod-price, .cart-prod-qty, .cart-info-box {
      display: none;
    }
    .single-cart-prod {
      position: relative;
      border-bottom: 1px solid $c-middlegray;
      padding-bottom: 15px;
      padding-top: 15px;
    }
    .cart-prod-remove {
      position: absolute;
      right: 8px;
      top: 15px;
      padding: 0;
    }
    .cart-prod-subtotal {
      position: absolute;
      right: 8px;
      bottom: 15px;
      padding: 0;
    }
    .cart-increase-decrease-quantity {
      margin-top: 25px;
    }
    .cart-total, .cart-total-labels {
      width: 49%;
      display: inline-block;
    }
    .cart-total-labels {
      color: $c-middlegray;

      strong {
        color: $c-middlegray;
      }

      .cart-total-value-label {
        color: $black;

        @include until($xmobile) {
          font-size: 12px;
        }
      }
    }
    .cart-total-value {
      font-size: 16px;
    }
    tfoot {
      border-bottom: 0;

      tr {
        border-bottom: 1px solid $c-middlegray;
        padding-bottom: 15px;
        padding-top: 12px;
      }
    }
  }
}

.cart-action-notice {
  font-weight: 600;
  color: $black;
  line-height: 1.5;

  img {
    width: 100%;
    height: auto;
  }
}

.cart-info-box {
  p {
    margin: 0;
  }
}

.cart-increase-decrease-quantity {
  white-space: nowrap;
}

#cart-preview {
  display: none;
  position: absolute;
  right: 0;
  z-index: $overlay-z-index + 1;
  max-width: 600px;
  background: $white;
  width: 100%;
  padding: 20px 20px 0 25px;
  max-height: 710px;
  overflow-y: auto;
  @media only screen and (max-height: 800px) {
    max-height: 450px;
  }
  @include from($desktop) {
    bottom: auto;
    top: 61px;
    width: 600px;
  }

  .proceed-button-wrapper {
    border: none;
    background-color: #EFEFEF;
    padding-top: 10px;
    margin-left: -25px;
    margin-right: -20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }

  .btn-large {
    padding: 6px 35px 7px;
  }
}


.small-cart-table {
  .cart-total-value-label {
    vertical-align: initial;
    line-height: 1;
  }

  td:first-child, th:first-child {
    padding-left: 0;
  }

  tbody {
    td {
      &:first-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    th,
    td {
      &:nth-child(2) {
        text-align: left;
        padding-left: 0;
      }
    }
  }

  th {
    &:first-child {
      &:before, &:after {
        border: none;
      }
    }
  }

  td.cart-prod-img {
    width: 90px;
  }

  .prod-img-link {
    display: block;
    width: 75px;
    height: 85px;
    font-size: 10px;
    color: $c-gray;

    img {
      max-width: 100%;
      border: 1px solid $c-lightgray;
    }
  }

  th, td {
    vertical-align: bottom;
  }

  span[class^=color-dot] {
    @include inline;
    width: 20px;
    height: 20px;
  }

  .cart-total {
    text-align: right !important;
  }

  .cart-total-labels, .cart-total {
    vertical-align: top;
  }

  .size-label {
    margin-left: 40px;
  }

  .size-label, .color-label {
    @include inline;
    font-size: 12px;
    color: $c-text;
    margin-right: 10px;
  }

  .product-name-link {
    @include inline(top);
    height: 44px;
    line-height: 1.2;
    max-width: 215px;
  }

  .cart-total-labels, .cart-info-box {
    text-align: right;
  }

  tfoot {
    border-bottom: 0;

    td {
      border-bottom: 1px solid $c-middlegray;
    }
  }

  tr.gray-info {
    td {
      padding: 5px 8px;
    }
  }

  .cart-total-value-overallcosts {
    font-size: 14px;
  }

  .prodid-value {
    font-size: 11px;
  }
}

.small-cart-shipment-notice {
  margin-left: -25px;
  margin-right: -20px;
  background-color: #EFEFEF;
  padding: 10px 20px 0 20px;

  &:after {
    display: block;
    content: "";
    height: 0;
    clear: both;
  }

  p {
    margin: 0;
  }
}

.cart-progress {
  @include clearfix;
  margin-bottom: 15px;
  @media only screen and (min-width: 991px) {
    margin-bottom: 35px;
  }
  @media only screen and (max-width: map-get($breakpoints, xmedium)) {
    @include flex;
    justify-content: space-around;
  }

  &-step {
    @include breakpoint(xmedium) {
      float: left;
      width: 25%;
    }
  }

  @include breakpoint(xmedium) {
    &-step {
      width: auto;
    }
    &-cart {
      width: 25%;
    }
    &-data {
      width: 32%
    }
    &-overview {
      width: 28%;
    }
  }

  &-number {
    @include inline;
    @include breakpoint(medium) {
      font-size: 50px;
    }
    font-size: 32px;
    font-family: $f-special;
    color: $c-gray;
    margin-right: 5px;

    .active & {
      color: $black;
    }
  }

  &-text {
    @include inline;
    line-height: 1.2;
    color: $c-gray;
    border-bottom: 3px solid transparent;

    .active & {
      border-bottom: 3px solid $black;
      color: $black;
    }

    > p {
      display: none;
      @media only screen and (min-width: 998px) {
        @include inline;
      }
    }
  }

  .ic-arrow {
    margin-left: 15px;
    color: $c-gray;
  }

  .active {
    .ic-arrow {
      color: $black;
    }
  }
}

.cart-discount-value {
  color: $psred;
  font-weight: 600;
}

.cartDecreaseQuantity {
  @include inline;
  width: 19px;
  height: 19px;
  border: 1px solid $black;
  border-radius: 100%;
  text-align: center;
  background: url('/assets/workfiles/project/css/icons/SVG/minus-toggle.svg') no-repeat center center;
  background-size: 7px 1px;
}

.cartIncreaseQuantity {
  @extend .cartDecreaseQuantity;
  background: url('/assets/workfiles/project/css/icons/SVG/plus-toggle.svg') no-repeat center center;
  background-size: 7px;
}

.cart-singleproduct-quantity {
  font-weight: 700;
  vertical-align: middle;
  margin: 0 4px;
}

.prodid-label, .size-label {
  display: inline-block;
  font-size: 11px;
  color: $c-middlegray;
  margin-right: 10px;
  @media only screen and (min-width: 991px) {
    display: none;
  }
}

.prodid-value, .size-value {
  @media only screen and (min-width: 991px) {
    font-size: 14px;
  }
  font-size: 11px;
  display: inline-block;
}

.cart-total-value-overallcosts {
  font-size: 22px;
  line-height: 1;

  @include until($xmobile) {
    font-size: 18px;
  }
}

.cart-total-value-label {
  vertical-align: text-bottom;
  line-height: 1.1;

  @include from($mobile) {
    line-height: 1.4;
    white-space: nowrap;
  }
}

.cart-action-banner {
  @include breakpoint(medium) {
    display: block;
  }
  display: none;
  text-align: center;

  img {
    display: table;
    max-width: 100%;
    height: auto;
    margin: 20px auto;
  }
}

.proceed-button-wrapper {
  @include clearfix;
  @media only screen and (min-width: 991px) {
    border-top: 1px solid $c-middlegray;
  }

  &--no-border {
    border-top: none;
  }

  padding-top: 20px;

  .button-continue {
    margin-top: 35px;
    @include breakpoint(medium) {
      margin-top: 5px;
    }
  }

  .button-next {
    float: right;
  }

  @media only screen and (max-width: 990px) {
    display: flex;
    flex-direction: column-reverse;
    .button-continue, .button-next {
      margin: 0 auto 30px;
    }
  }
}

.advantage {
  &-wrapper {
    border: 10px solid $c-middlegray;
    background: $white url('/assets/img/project/bckg/cart-teaser.png') no-repeat bottom right;
    background-size: 243px 250px;
    padding: 50px 20px 20px;
    margin: 70px 0 50px;
    @media only screen and (max-width: map-get($breakpoints, medium)) {
      .footnote {
        text-align: center;
      }
    }
  }

  &-wrapper-inner {
    @include breakpoint(medium) {
      @include flex;
      justify-content: space-around;
    }
    margin-bottom: 35px;

    .icon-wrap {
      display: block;
      width: 50px;
      height: 50px;
      margin: 0 auto 15px;
      background: $c-middlegray;
      border-radius: 100%;
      line-height: 50px;
      text-align: center;
      color: $black;
    }
  }

  &-box {
    margin-bottom: 20px;
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
    line-height: 1.2;
    font-family: $f-special;
    font-size: 20px;
    text-align: center;
  }
}

@media only screen and (max-width: 990px) {
  .information-box {
    text-align: center;
    font-size: 12px;
    margin-bottom: 15px;
  }
}

.cart-toggle {
  position: relative;
}

.prod-counter {
  display: none;
  position: absolute;
  top: 1px;
  right: 5px;
  padding: 3px 5px;
  background: $white;
  color: $black;
  @include from($desktop) {
    right: -5px;
    background: $black;
    color: $white;
  }
  font-size: 10px;
  border-radius: 20px;
  line-height: 1;
  vertical-align: middle;
}

.cart-detailview--unavailable {
  margin: 20px 0 40px 0;

  &-header {
    color: $psred;
    font-weight: 700;
  }

  hr {
    margin: 5px 0;
    border-top: 1px solid $c-middlegray;
  }
}

.regular-price {
  &-cheaper,
  &-more-expensive {
    font-weight: 700;
  }

  &-cheaper {
    color: $green;
  }

  &-more-expensive {
    color: $psred;
  }

  &-cheaper-message,
  &-more-expensive-message {
    background: $c-lightgray;
    margin: 0 0 5px 0;
    display: inline-block;
    padding: 3px 5px;

    border-radius: 2px;
    font-size: 10px;
    color: $c-darkgray !important;
  }
}

.payment--wrapper {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  @include breakpoint(400px down) {
    display: inline-block;
  }

  &--border {
    padding-top: 20px;
    border-top: 1px solid #ccc;
  }
}

.payment-methods--wrapper img:first-child {
  margin-right: 5px;
}

.checkout-cart-gift {
  &--trigger {
    display: block;
    margin: 40px 0 50px 0;

    label {
      font-size: 16px !important;
      font-weight: 700;
    }
  }

  &--content {
    padding-bottom: 30px;
    @media only screen and (min-width: 800px) {
      display: grid;
      grid-template-columns: auto 400px;
      grid-gap: 60px;
    }
  }

  &--content-left-images {
    img {
      width: 100%;
      height: auto;
    }

    label {
      text-align: center;
    }

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  &--content-right input,
  &--content-right textarea {
    width: 100%;
  }

  &--image {
    width: 100%;
    max-width: 300px;
  }
}

.checkout-cart-couvert {
  &--trigger {
    display: block;
    margin: 40px 0 50px 0;

    label {
      font-size: 16px !important;
      font-weight: 700;
    }
  }

  &--content-images {
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px solid #ccc;
    img {
      width: 100%;
      height: auto;
    }

    label {
      text-align: center;
    }

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  &--image {
    width: 100%;
    max-width: 300px;
  }
}

.checkout-cart--coupon {
  border-bottom: 1px solid #ccc;
  padding-top: 20px;
  padding-bottom: 30px;
}

.checkout-cart--coupon--headline {
  font-weight: 700;
  color: $psgreen
}

.checkout-cart--coupon--error {
  color: $psred;
  font-weight: 700;
}

.checkout-cart--coupon button {
  margin-top: 10px;
}