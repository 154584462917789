/* --------------------------------- VARIABLES --------------------------------- */

// Colors
$black: #000;
$white: #ffffff;
$red: #f00;
$pspink: #f43493;
$yellow: yellow;
$green: green;
$psred: #C22026;
$actionred: #C70013;
$newblack: #222227;
$psgreen: #288D13;
$coral: #CA5C57;
$brown: #924937;
$purple: #8200FF;
$c-blue: #4A90E2;
$c-gray: #9B9B9B;
$c-middlegray: #CCCCCC;
$c-headlines: $black;
$c-lightgray: #ECECEC;
$c-lightergray: #F2F2F2;
$c-border: $c-gray;
$c-darkgray: #131414;
$c-text: #4A4A4A;
$c-text-mobile: rgba(74,74,74, 0.5);
$c-headlines: $black;
$c-placeholder: #7C8495;
$c-border: $c-gray;
$overlay-z-index: 4;
$fb-btn-blue: #3B5998;
$in-btn-blue: #E4405F;
$pin-btn-blue: #C92228;
$action-pink: #F1008C;
$action-gray: #c7c7c7;
$note-green:#3DB600;
$detail-gray:#262626;
$product-detail-border: #D9D9D9;

$sticker-red: #CF001E;


$nav-light: #fff;
$nav-light-hover: #fff;
$nav-dark: #111;
$nav-dark-hover: #000;
$nav-border: #4A4A4A;
$nav-border-rgba: rgba(74,74,74, 0.2);

$nav-black: #000;
$nav-grey: #A2A2A2;
$nav-red: #CF1515;
$nav-pink: #FF4DAB;
$lighten: 10%;

// Breakpoints
$breakpoints: (
	small: 0,
	xxsmall: 375px,
	xsmall: 480px,
	medium: 641px,
	xmedium: 769px,
	xxmedium: 1025px,
	large: 1201px,
	xlarge: 1367px,
	xxlarge: 1401px,
	xxxlarge: 1441px,
	xxxxlarge: 1680px,
	wide: 1919px
);

$breakpoints-classes: (small, medium, xmedium, xxmedium, large, xlarge, xxlarge, xxxlarge);

$container-width: (
	small: 100%,
	medium: 100%,
	xmedium: 100%,
	xxmedium: 100%,
	large: 1120px,
	xlarge: 1120px,
	xxlarge: 1200px,
	xxxlarge: 1441px
);

$footer-closed: 52px;
$footer-height: 470px;
$footer-closed-tablet: 96px;
$footer-height-tablet: 470px;

$headernav-width: 250px;
$mobile-margin: 20px;

// Grid inner columns width
$c-1-inner-width: 100%;
$c-2-inner-width: 48.5%;
$c-3-inner-width: 31.3333%;
$c-4-inner-width: 22.75%;
$c-5-inner-width: 17.6%;

$space-none: 0;
$space-small: 25px;
$space-medium: 40px;
$space-large: 90px;
$space-xlarge: 130px;

// Typo
$hr-display: block;
$hr-height: 0;
$hr-border-top: 1px solid $c-border;
$hr-margin: 20px 0;
$hr-padding: 0;

$f-text: 'Open Sans', sans-serif;
$f-special: 'Lora', serif;
$f-krub: 'Krub', 'Open Sans', sans-serif;

$checkbox-size: 22px;

// Anchors
$a-link-color: $black;
$a-hover-active-color: $black;
$a-visited-color: $black;



//**********************************************************************
// Constants
//**********************************************************************

// Breakpoints
$xsmobile: 380px;
$xmobile: 480px;
$mobile: 640px;
$tablet: 768px;
$desktop: 1024px;
$desktop-large: 1200px;
$wide-screen: 1366px;
$ultra-wide-screen: 1480px;
$popup-cart-breakpoint-large: 1550px;
$popup-cart-breakpoint-wide: 1650px;
$product-detail-full-image-breakpoint: 1650px;
$content-extended-image-breakpoint: 1655px;
$slider-max-width-breakpoint: 1920px;


// Margins and paddings
$container-margin: 20px; // Used for side margins, padding
$top-margin: 80px;

$ce-margin-vertical: 80px; // used for top and bottom margin
$ce-margin-vertical-tablet: 80px; // used for top and bottom margin
$ce-margin-vertical-desktop: 100px; // used for top and bottom margin
$ce-margin-vertical-desktop-large: 100px; // used for top and bottom margin
$ce-margin-vertical-widescreen: 100px; // used for top and bottom margin
$headline-margin: 40px 0 20px 0;
$two-columns-header-margin-top: 30px;


$ce-padding-vertical: 50px; // Padding e.g. for Items with background colors


// Tablet view, should be at least less than $tablet + 2 * $container-margin
$container-width-tablet: $tablet - 2 * $container-margin;

// Desktop view, should be at least less than $desktop + 2 * $container-margin
$container-width-desktop: $desktop - 2 * $container-margin;

// Desktop large view, should be at least less than $desktop-large + 2 * $container-margin
$container-width-desktop-large: $desktop-large - 2 * $container-margin;

// Widescreen view, should be at least less than $wide-screen + 2 * $container-margin
$container-width-widescreen: $wide-screen - 2 * $container-margin;

// Widescreen view, should be at least less than $wide-screen + 2 * $container-margin
$container-width-ultra-widescreen: $ultra-wide-screen - 2 * $container-margin;