// Product Color dots
span[class^='color-dot']{
	display: block;
	width: 24px;
	height: 24px;
	@include breakpoint(medium down) {
		width: 18px;
		height: 18px;
	}
	border-radius: 100%;
	border: 1px solid rgba(255,255,255,0.95);
	margin: 3px;
	.active & {
		margin: 0;
		width: 27px;
		height: 27px;
		border: 3px solid rgba(255,255,255,0.95);
		@include breakpoint(medium down) {
			width: 20px;
			height: 20px;
			border: 3px solid rgba(255,255,255,0.95);
		}
	}	
}
.active span.color-dot-bela {
	border: 3px solid rgba(120,120,120,0.8);
}
span.color-dot {
	&-18   { background: $white; border:1px solid $c-gray; } /*Bela*/
	&-19   { background: #53A700; } /*Zelena*/
	&-20   { background: #262626; } /*Crna*/
	&-21   { background: #C57705; } /*Braon*/
	&-22   { background: #8700FF } /*Ljubi?asta*/
	&-23   { background: #F14288 } /*Roza*/
	&-24   { background: #F5F5DB; border:1px solid $c-gray; } /*Be?*/
	&-25   { background: #C1C1C1; } /*Siva*/
	&-26   { background: #F1B500; } /*?uta*/
	&-27   { background: #193576; } /*Teget*/
	&-28   { background: #CB1607; } /*Crvena*/
	&-29   { background: #3875C0; } /*Plava*/
	&-30   { background: #888888; } /*Srebro*/
	&-31   { background: #EFCD02; } /*Oker*/
	&-32   { background: #F7A602; } /*Zlato*/
	&-33   { background: #CB1607; } /*Bordo*/
	&-34   { background: #F7A602; } /*Narand?asta*/
	&-1253 { background: #8700FF; } /*Ljubičasta*/
	&-1254 { background: #F5F5DB; border:1px solid $c-gray;} /*Bež*/
	&-1255 { background: #F1B500; } /*Žuta*/
	&-1256 { background: #F7A602; } /*Narandžasta*/
	&-bela   { background: $white; border:1px solid $c-gray; } /*Bela*/
	&-zelena   { background: #53A700; } /*Zelena*/
	&-crna   { background: #262626 } /*Crna*/
	&-braun   { background: #C57705; } /*Braon*/
	&-braon   { background: #C57705; } /*Braon*/
	&-ljubiasta   { background: #8700FF } /*Ljubi?asta*/
	&-roza   { background: #F14288 } /*Roza*/
	&-be   { background: #F5F5DB; border:1px solid $c-gray; } /*Be?*/
	&-siva   { background: #C1C1C1; } /*Siva*/
	&-uta   { background: #F1B500; } /*?uta*/
	&-teget   { background: #193576; } /*Teget*/
	&-crvena   { background: #CB1607; } /*Crvena*/
	&-plava   { background: #3875C0; } /*Plava*/
	&-srebro   { background: #888888; } /*Srebro*/
	&-oker   { background: #EFCD02; } /*Oker*/
	&-mesing   { background: #B2996D; } /*Mesing*/
	&-zlato   { background: #F7A602; } /*Zlato*/
	&-bordo   { background: #CB1607; } /*Bordo*/
	&-narandasta   { background: #F7A602; } /*Narand?asta*/
	&-ljubicasta { background: #8700FF; } /*Ljubičasta*/
	&-bez { background: #F5F5DB; border:1px solid $c-gray; } /*Bež*/
	&-uta { background: #F1B500; } /*Žuta*/
	&-narandasta { background: #F7A602; } /*Narandžasta*/
	&-bakar { background: #8B2500; } /*Bakar*/
	&-staro { background: #F7A602; } /*Staro zlato*/		
	&-transparentna { background: transparent; border:1px solid $c-gray; } /*Transparentna*/
}