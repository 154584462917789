.ce_one_col_image_portrait {
  &__wrapper {

  }
  &__container {

  }
  &__inner {
    max-width: 355px;
    margin: auto;
  }
  &__holder {
    text-align: center;
    line-height: 0;
  }
  &__img {
    max-width: 100%;
    margin: 0 auto;
  }
  &__text {
    padding: 0 10px;
    margin-top: 10px;
    @include col_img_txt;
    p {
      margin-top: 0;
    }
  }
}


// @include breakpoint(xxmedium) --> max 360
@include breakpoint(small down) {
  .ce_one_col_image_portrait {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      //max-width: 355px;
    }
    &__headline {

    }
    &__img {
      width: 100%;
      height: auto;
    }

  }
}

/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_one_col_image_portrait {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: 984px;
    }
    &__headline {

    }
    &__content {

    }

  }
}

/// @include breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_one_col_image_portrait {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: 1080px;
    }
    &__headline {

    }
    &__content {

    }

  }
}



