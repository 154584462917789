.cookie-notice {
	display:none;
	padding: 15px;
	background-color: rgba(68, 68, 68, 0.85);
	color: #fff;
	bottom: 0;
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;	
	text-align: center;
	p {
		margin:10px 0;		
	}		
}
.notice-close { 
	position: absolute;
	right:5px;
	top: 5px;
	&:hover {
		color:#fff;
	}
}
