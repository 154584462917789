.accordion__item {
  padding: 0 !important;
}

.accordion__trigger_icon {
  position: absolute;
  top: 5px;
  right: 0;
  height: 10px;
  width: 10px;
  background: sassvg("ic-chevron") no-repeat center right;
  transition: all 0.2s;
  transform: rotateX(180deg);
}

.accordion__trigger_active {
  .accordion__trigger_icon {
    transform: rotateX(0deg);
    transition: all 0.2s;
  }
}