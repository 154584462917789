fieldset {
	border: none;
	margin: 0;
	padding: 0;
}

input:focus {
	outline: none;
}

label,
.field-wrap,
.field-wrap-inner input,
.powermail_field {
	display: inline-block;
	font-family: $f-text;
}

input, select, textarea, select, .select2-container {
	max-width: 100%;
    @include breakpoint(medium) {
        width: 400px;
    }
	width: 280px;	
	@media only screen and (max-width: map-get($breakpoints, xxmedium) - 1) {
		.inner-two-col & {
			width: 280px;
		}
	}
	@media only screen and (max-width: map-get($breakpoints, large) - 1) {
		.login-form-wrapper & {
			width: 100%;
			max-width: 400px;
		}
	}
}
input, select {
	font-family: $f-text;
	padding: 10px 15px 11px;
	font-size: 14px;
	font-weight: 600;
	border: 1px solid $c-border;
	border-radius: 3px;
	background: $white;
	&::-webkit-input-placeholder {
		font-weight: 300;
		color: $c-placeholder;
	}
}

// disables zoom on ios
@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: map-get($breakpoints, xmedium) - 1) { 
	select,
	textarea,
	input {
		font-size: 16px;
		background: $white;
	}
}

.input-small {
	width: 25%;
}
.input-medium {
	width: 70%;
	margin-left: 5%;
}
.formrow-wrap .description{
	background: url(../workfiles/project/css/icons/SVG/info.svg) no-repeat left center;
	background-size: 7px 27px;
	padding-left: 15px;
	margin-top: 10px;
	min-height: 30px;
	font-size: 12px;
	a {
		text-decoration: underline;
		font-weight: 600;
	}
	@include breakpoint(xlarge) {
		@include inline(middle);
		margin-top: 0px;
		padding-top: 6px;
		max-width: 450px;
		padding-left: 35px;
	}
	.narrow-wrapper & {
		max-width: 300px;
		@media only screen and (max-width: 1820px) {
			display: block;
			margin-top: 10px;
			padding-left: 15px;
			max-width: 400px;
		}
	}
}
.checkbox-wrap, .radio-wrap {
	.description {
		background: none;
		padding-left: 40px;
		padding-top: 0;		
		@include breakpoint(xlarge) {
			padding-left: 40px;	
		}
		.narrow-wrapper & {
			@media only screen and (max-width: 1820px) {
				padding-left: 40px;
			}
		}
	}
}

input[type=radio], input[type=checkbox], input[type=submit] {
	width: auto;
}
legend {
	margin-bottom: 15px;
}
.form-title,
.powermail_legend {
	display: none;
}
.form-title {
	font-weight: 600;
	font-size: 22px;
	.form-inline & {
		margin-bottom: 0;
/*
		@include breakpoint(xlarge) {
			margin-left: 170px;
		}		
*/
	}
}

input[type=submit] {
	@extend .btn;
	margin-top: 10px;
}
.ic-checkbox, .radio {
	@include inline(top);
	position: relative;
	width: $checkbox-size;
	height: $checkbox-size;
	line-height: 1;
	border: 1px solid $black;
	background: $white;
}
.ic-checkbox {
	.checked & {
		background: $white url(../workfiles/project/css/icons/SVG/check-mark.svg) no-repeat;
		background-position: center;
		background-size: 17px;
		border: 1px solid $black;
	}	
}
.radio {
	.checked & {
		background: $white url(../workfiles/project/css/icons/SVG/radio-circle.svg) no-repeat;
		background-position: center;
		background-size: 12px;
		border: 1px solid $black;
	}	
}

.inline-wrap {
	@include breakpoint(990px) {
		.field-wrap {
			@include inline(top);
			width: auto;
		}
		label {
			@include inline(top);
			min-width: 100px;
			margin-right: 15px;
		}
	}
}

label.radio-single,
label.checkbox-single {
	text-align: left;
	margin-right: 0;
}

.checkbox-wrap label.checked {
	color: $black;
	//font-weight: 400;
}
.checkbox-wrap {
	label {
		font-weight: 300;
		@include transition();
	}
	.checkbox-single {
		margin-bottom: 5px;
	}
}
.radio {
	border-radius: 100%;
}
.radio-wrap {
	.radio-single {
		display: block;
		text-align: left;
		font-weight: 300;
		margin-bottom: 5px;
		.radio {
			margin-left: 0;
		}
	}
}
// Because of breakline we are adding inline block to container
.checkbox-label-inline {
	display: inline !important;
}
.radio-label, .checkbox-label {
	@include inline(middle);
	margin: 0 10px 0 5px !important;
	font-size: 14px;
	cursor: pointer;
	text-transform: none;
	max-width: 90%;
}
input:checked ~ .radio-label {
	color: $black;
	font-weight: 400;
}

// POWERMAIL
.formrow-wrap,
.powermail_fieldwrap {
	margin-bottom: 30px;
}
.powermail_form {
	@extend .contact-form-main;
}

form.powermail_form.powermail_form_14.layout2 {
	.form-title {
		display: block;
	}
}
form.powermail_form.powermail_form_1.layout2 {
	.form-title {
		display: block;
		margin-bottom: 30px;
	}
}
.powermail_form {
	h4 {
		text-transform: uppercase;
		margin-top: 25px;
	}	
	.powermail_fieldwrap_type_html {
		margin-bottom: 0;
	}
}
.powermail_form.layout2  {
	@extend .form-inline;
}
.powermail_fieldwrap.powermail_fieldwrap_type_html.powermail_fieldwrap_obaveznapolja {
	margin-bottom: 10px;
}
.powermail_fieldwrap.powermail_fieldwrap_type_html.powermail_fieldwrap_formsubheadline0101 {
	margin-bottom: 0
}
.powermail_form.layout2 .formrow-wrap.nolabel {
	@include breakpoint(xlarge) {
		margin-left: 170px;
	}
	.checkbox-label {
		max-width: 250px;
		margin-left: 0;
		vertical-align: top;
		padding-left: 15px;
		@include breakpoint(medium) {
			max-width: 300px;
		}	
		@include breakpoint(xlarge) {
			max-width: 500px;
		}
	}
}
.formrow-wrap.nolabel {
	.powermail_label {
		display: none;
	}	
}
.field-wrap {
	display: block;
}

.contact-form-main {
	.btn {
		display: block;
		width: auto;
	}
	
}
label {
	display: block;
	text-transform: uppercase;
	width: auto;
	font-size: 12px;
	line-height: 17px;
	margin-bottom: 5px;
	color: $black;
	&.required:after {
		content: '*';
	}
}

input::placeholder,
textarea::placeholder {
	font-family: $f-text;
	color: $c-placeholder;
	font-weight: 300;
}
textarea {
	height: 160px;
	resize: none;
	font-size: 14px;
	font-weight: 600;
	color: $black;
	padding: 10px 15px;
	border: 1px solid #aaa;
	border-radius: 3px;
}

.form-inline {
	.btn {
		display: block;
		width: auto;
		text-transform: uppercase;
		padding: 10px 40px;
		@include breakpoint(xlarge) {
			margin-left: 170px;
		}
	}
	.radio-wrap {
		.radio-single {
			display: block;
			text-align: left;
		}
	}
	@include breakpoint(xlarge) {
		.field-wrap,
		label,
		input, select {
			@include inline(middle);
		}
		input, select {
			margin-right: 30px;
		}
		.field-wrap {
			width: auto;
		}
		label {
			width: 150px;
			margin-right: 15px;
			margin-bottom: 0;			
		}
		.radio-single, .checkbox-single {
			margin-bottom: 5px;
			width: auto;
		} 
		.btn {
			margin-left: 170px;
		}
		.required-fields-notice {
			margin-bottom: 15px;
			@include breakpoint(xlarge) {
				margin-bottom: 0;
				.narrow-wrapper & {
					margin-bottom: 15px;
				}
			}
		}
		.radio-list-label,
		.checkbox-list-label {
			vertical-align: top;
		}
		.textarea-label {
			vertical-align: top;
			padding-top: 10px;
		}
	}
}

.form-inline,
.contact-form-main {
	.checkbox-label {
		text-align: left;
		width: auto;
		min-width: 50px;
	}
	.checkbox-single {
		display: block;
		text-align: left;
		width: auto;
	}
}
.contact-form-inline {
	@extend .form-inline;
}

.intreg-register-form {
	@extend .form-inline;
	.radio-single span {
		vertical-align: middle;
	}
	input[type=submit] {
		margin-left: 0 !important;
	}
	.register-premium-wrapper {
		display: none;
		padding-left: 40px;
		text-transform: none;
		margin-top: 15px;
		.checkbox-label {
			max-width: 80%;
		}
	}
	.radio-single {
		margin-bottom: 30px !important;;
	}
	.gender-radio-wrap,
	.country-radio-wrap {
		.radio-single {
			display: inline-block;
			margin-bottom: 0 !important;
		}
	}
}

hr.form-divider {
	border-top: 1px solid $black;
	width: 100%;
	max-width: 470px;
	margin: 0 auto;
	@include breakpoint(large) {
		margin-left: 140px;
	}
}
.errorClass {
	border: 1px solid $psred;
}
.errorMessage {
	.powermail_field_ime-error {

	}
	.errorClass {
		border: none;
		font-size: 12px;
		margin: 5px 0 0;
		color: $psred;
		text-transform: none;
	}
}
input.errorClass {
	background: $white url(../workfiles/project/css/icons/SVG/form-error.svg) no-repeat 97% center;
	background-size: 18px;
}

.alert {
	@extend .errorClass;
	margin:3px 0;
	padding:3px 50px 3px 3px;
	p {
		margin: 5px 15px;
	}
	&-danger {
		@extend input.errorClass;
		p, label {
			color: $psred;
		}
	}
	&-success {
		@extend input.valid;
		border-color: $psgreen;
		p, label {
			color: $psgreen;
		}
	}
	.checkbox-single {
		padding: 10px 0 0 15px;
		.checkbox-label {
			font-weight: 400;
		}
	}
	.checkbox-wrap label.checked {
		color: $psgreen;
	}
	&-danger--only-text {
		border: none;
		font-size: 12px;
		margin: 5px 0 0;
		color: $psred;
	}
	&-success--only-text {
		border: none;
		font-size: 12px;
		margin: 5px 0 20px 0;
		color: $psgreen;
	}
	&-neutral--only-text {
		border: none;
		font-size: 12px;
		margin: 5px 0 20px 0;
		color: $nav-grey;
	}
}

input.valid {
	background: $white url(../workfiles/project/css/icons/SVG/form-correct.svg) no-repeat 97% center;
	background-size: 18px;
}
.notify-checkbox-wrapper {
	@include inline(bottom);
	.field-wrap {
		display: block;
	}
}
.terms-of-service-wrapper {
	@include breakpoint(xxmedium) {
		margin-left: 170px;
	}
}
.required-fields-notice {
	background: url(../workfiles/project/css/icons/SVG/required.svg) no-repeat left center;
	background-size: 18px 18px;
	padding-left: 30px;
	font-size: 12px;
	@include breakpoint(xlarge) {
		margin: 0 0 50px 595px;
		@media only screen and (max-width: 1820px) {
			.narrow-wrapper & {
				margin: 15px 0 50px 0;
			}			
		}
	}
	
}


.fb-login-btn {
	@extend .btn;
	background: $fb-btn-blue;
	padding: 5px 15px 5px 0px;
	&:hover {
		background-color: lighten($fb-btn-blue,10%);

	}
	.icon {
		margin: 0 10px 0 15px;
		@include inline(text-top);
	}
}

//Select field
.select2-container--default .select2-selection--single .select2-selection__rendered {
	font-weight: 600;
	padding-left: 15px;
	padding-top: 5px;
}
.select2-results__option {
	color: $black;
	font-weight: 600;
	padding-left: 15px;
}
select {
	display: inline-block;
	height: 40px;
	appearance: none;
	background: url(../workfiles/project/css/icons/SVG/select.svg) no-repeat 98% center;
	background-size: 11px 6px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	background: url(../workfiles/project/css/icons/SVG/select.svg) no-repeat;
	background-size: contain;
	border: none;
	height: 7px;
	width: 12px;
}
.select2-container .select2-selection--single {
	height: 40px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 8px;
	right: 10px;
}
.select2-search--dropdown {
	display: none;
}

label.required:after {
	content: '*';
}

.field-notice {
	font-size: 12px;
	color: $c-gray;
}
.form-errorContainer {
	display: none;
	width: 100%;
    border: 1px dotted $psred;
    padding: 20px;
    text-align: center;
	color: $psred;
	margin-bottom: 20px;
	h3 {
		color: $psred;
		font-size: 18px;
	}
}

/* The checkbox notifyby switch */
.customer-notifyby {
	margin: 20px 0 35px;
	&-input {
		display: block;
		width: 100%;
		opacity: 0;
		@include breakpoint(400px) {
			display: inline-block;
			width: 125px;			
		}
		padding: 3px 5px 4px;
		font-size: 12px;
		vertical-align: top;
		@include transition();
		.checked-notifyby & {
			opacity: 1;
		}
	}
	.checkbox-single {
		float: left;
	}
	.checkbox-label {
		font-size: 10px;
		text-transform: uppercase;
		margin-left: 10px;
		margin-right: 15px;
	}
	.formrow-wrap {
		margin-bottom: 20px;
	}
	input[type=submit] {
		margin-top: 0;
	}	
	.errorClass::placeholder {
		color: $psred;
	}	
}

/*
.checkbox-switch {
	position: relative;
	@include inline(middle);
	width: 45px;
	height: 22px;
	margin-top: 5px;
	text-transform: none;
	@include breakpoint(xxmedium) {
		margin-right: 10px;
	}
	line-height: initial;
	cursor: pointer;
	&-slider {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $c-middlegray;
		border-radius: 20px;
		height: 24px;
		@include transition (0.4s);
		&:before {
			position: absolute;
			content: "";
			left: 0;
			bottom: -1px;
			height: 26px;
			width: 26px;
			background-color: $white;
			@include transition(0.4s);
			box-shadow: 0 0 1px 0 rgba(0,0,0,0.5);
			border-radius: 50%;
		}
	}
	&-on,
	&-off {
		@include transition(0.2s);
		vertical-align: text-top;
		color: $white;
		font-size: 10px;
		font-style: italic;
		font-weight: 600;
		user-select: none;
		top: 4px;
	}
	&-off {
		position: absolute;
		right: 5px;
		color: $black;
	}
	&-on {
		position: absolute;
		left: 5px;
		opacity: 0;
	}
	&-input {
		position: absolute;
		right: -130px;
		height: 20px;
		width: 110px;
		max-width: initial;
		opacity: 0;
		@include transition();
		font-weight: 600;
		font-size: 11px;
		padding: 1px 10px;
	}
	&-label {
		@include inline(middle);
		text-transform: uppercase;
		font-size: 10px;
		max-width: 140px;
		line-height: 1.2;
	}
}
input:checked ~ .checkbox-switch-slider {
	background-color: $psgreen;
	&:before {
		transform: translateX(20px);
	}
}
input:checked ~ .checkbox-switch-off {
	opacity: 0;
}
input:checked ~ .checkbox-switch-on {
	opacity: 1;
}
input:checked ~ .checkbox-switch-input {
	opacity: 1;
}
*/

.error-message {
	color: $psred;
}
.succes-message {
	color: $psgreen;
}
#tx_felogin_loginform {
	margin-top: 15px;
}
.selectize-input {
	border: 1px solid $c-gray;
	max-width: 400px;
	height: 39px
}


.formrow-wrap--checkout-password,
.formrow-wrap--checkout-registration {
	display: none;
}

.checkout-data-entry--personal {
	opacity: 0.2;
	pointer-events: none;
}

.paymentemail-ajax-loading-container,
.paymentpw-ajax-loading-container {
    padding: 5px;
    display: none; // Switch with inline-block
    width: 20px;
    height: 20px;
}
.paymentemail-ajax-loading {
	display: inline-block;
	width:14px;
	height:14px;
	background-image: sassvg('ic-reset', $red);
	background-repeat: no-repeat;
  	animation: rotation 1s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.field-wrap--checkout-registration {
	display: flex;
	padding-top: 10px;

	span.checkbox-single {
		display: inline-flex;
		padding-left: 10px;
		text-transform: none;
	}
	.checkbox-single {
		line-height: 1.5;
	}
}

.formrow-wrap--checkout-registration--reset-password {
	display: flex;
	align-items: center;
	.checkout--password-reset-text {
		padding-left: 10px;
	}
}