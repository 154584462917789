.ce_isolate_flip {

  &__wrapper {

  }
  &__container {

  }
  &__img {
    opacity: 0;
    transition: $animation;
  }
  &__inner {
    width: 375px;
    margin: auto;
    position: relative;
  }
  &__headline {
    position: absolute;
    left: 30px;
    bottom: 220px;
    width: 375px;
    opacity: 0;
    transition: $animation;
    @include font($family: $font-headline,
                  $size: 70px,
                  $weight: 700,
                  $color: #010101,
                  $line: 65px,
                  $space: -1.7px);
  }
  &__content {
    position: absolute;
    top: 300px;
    left: -10px;
    width: 350px;
    @include font($family: $f-krub,
                  $size: 16px,
                  $color: $color-bodytext,
                  $line: 26px);
    opacity: 0;
    transition: $animation;
  }
  &__readmore {
    position: absolute;
    left: 20px;
    top: 20px;
    @include readmore;
    opacity: 0;
    transition: $animation;
    &_holder {
      position: relative;
    }
  }
  &__img {
    opacity: 0;
    width: 375px;
    height: 520px;
  }
}

// breakpoint(small down) ---> max 480
@include breakpoint(xxsmall down) {
  .ce_isolate_flip {

    &__inner {
      width: 100%;
    }
    &__headline {
      bottom: 40%;
      width: 100%;
      transition: $animationMobile;
    }
    &__content {
      width: 100%;
      top: 60%;
      padding-right: 15px;
      transition: $animationMobile;
    }
    &__readmore {
      transition: $animationMobile;
      &_holder {

      }
    }
    &__img {
      width: 100%;
      height: auto;
    }

  }
}

// breakpoint($xxsmall down) ---> max 375
@include breakpoint(xxsmall down) {
  .ce_isolate_flip {

    &__inner {

    }
    &__headline {
      font-size: 60px;
      line-height: 55px;
      bottom: 47%;
    }
    &__content {
      top: 50%;
      line-height: 20px;
    }
    &__readmore {

    }

  }
}

/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_isolate_flip {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      width: 989px;
      height: 845px;
    }
    &__headline {
      left: unset;
      right: 30px;
      bottom: 365px;
      width: 507px;
      @include font($size: 100px,
                    $line: 90px);
    }
    &__content {
      width: 440px;
      top: 490px;
      right: 50px;
      left: unset;
    }
    &__readmore {
      top: 30px;
    }
    &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 610px;
      height: 845px;
    }

  }
}

/// @include breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_isolate_flip {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      height: 1080px;
    }
    &__headline {
      width: 557px;
      bottom: 550px;
      right: 0px;
      @include font($size: 130px,
                    $weight: 700,
                    $line: 120px);
    }
    &__content {
      right: 20px;
      top: 565px;
    }
    &__readmore {

    }
    &__img {
      width: 780px;
      height: 1080px;
    }

  }
}
