.ce_text_default_headline {

  &__wrapper {
    @include txt-ce-margin;
  }
  &__container {

  }
  &__inner {
    max-width: 375px;
    margin: auto;
  }
  &__title {
    @include new_h1($family: $f-krub);
    margin-top:15px;
  }
  &__content {
    @include new_leadtext;
    a {
      @include font($size: 14px,
      $weight: bold,
      $color: #000000,
      $line: 18px);
    }
  }

}

// @include breakpoint(xxmedium) --> max 360
@include breakpoint(small down) {
  .ce_text_default_headline {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: unset;
    }
    &__content {

    }

  }
}

/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_text_default_headline {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: $txt-maxwidth-tablet;
    }
    &__content {

    }

  }
}

/// @include breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_text_default_headline {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: $txt-maxwidth-desktop;
    }
    &__content {

    }

  }
}



