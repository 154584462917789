.ce_text_default {

  &__wrapper {
    @include txt-ce-margin;
  }
  &__container {

  }
  &__inner {
    max-width: 375px;
    margin: auto;
  }
  &__content {
    @include new_default;
    h1 {
      @include new_h1 ($family: $f-krub);
    }
    h2 {
      @include new_h2 ($family: $f-krub);
    }
    h3 {
      @include new_h3 ($family: $f-krub);
    }
    a {
      @include font($size: 14px,
      $weight: bold,
      $color: #000000,
      $line: 18px);
    }
  }

}

// @include breakpoint(xxmedium) --> max 360
@include breakpoint(small down) {
  .ce_text_default {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: unset;
    }
    &__content {

    }

  }
}

/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_text_default {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: $txt-maxwidth-tablet;
    }
    &__content {

    }

  }
}

/// @include breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_text_default {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: $txt-maxwidth-desktop;
    }
    &__content {

    }

  }
}

.ce_text_default__wrapper--seoblock {
  margin-top: 0;
  max-width: $desktop-large;
  padding-right: 40px;
  @include from($tablet) {
    margin-left: 260px;
  }

  .ce_text_default__inner {
    margin: 0;
    max-width: initial;
  }

  h1, h2 {
    font-weight: 400;
    font-size: 48px !important;
    line-height: 74px;
    letter-spacing: 0;
    margin-top: 60px;
    @include from($desktop) {
      margin-top: 0;
    }
  }
}