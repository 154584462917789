.header--logo {
	@include breakpoint(xmedium down) {
		display: none;
	}
}

.header--logo--container {
	@include breakpoint(xmedium down) {
		position: fixed;
		top: 0;
		z-index: 10;
		display: block;
		width: 60px;
		height: 43px;
		background: url('/assets/img/project/logo/logo-black.svg') no-repeat center;
		background-size: 60px 43px;
		font-size: 0;
		margin: 15px 0 0 0;
		@include transition;
		text-indent: -1000px;
		.header-white & {
			background: url('/assets/img/project/logo/logo-white.svg') no-repeat center;
			background-size: 60px 43px;
		}
		&.logo-black {
			background: url('/assets/img/project/logo/logo-black.svg') no-repeat center;
			@include breakpoint(xmedium) {
				width: 140px;
				height: 110px;
				background-size: 140px 110px;
			}
		}
		.visible &,
		.header-left-hovered & {
			background-image: url('/assets/img/project/logo/logo-black.svg') !important;
			background-size: 60px 43px;
			//@include breakpoint(xmedium) {
			//	background-size: 140px 110px;
			//}
			.header-white & {
				width: 60px;
				height: 43px;
			}
		}
	}
}

.header-white {
	@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
		.header-nav-icon span {
			background: #fff !important;
		}
	}
}

.visible-nav {
	@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
		.header-logo {
			background: url('/assets/img/project/logo/logo-black.svg') no-repeat center !important;
			background-size: 60px 43px !important;
			//position: relative;
		}
		.header-nav-icon span {
			background: #000 !important;
		}
	}
}



////.submenu-toggle {
////	position: relative;
////	.icon-open {
////		position: absolute;
////		right: 0;
////		top: 0;
////		width: 30px;
////		height: 30px;
////	}
////}
////
////.header-nav-list-level-0 {
////	padding-bottom: 100px;
////}
////.submenu-wrap,
////.header-nav-list-level-1,
////.header-nav-list-level-2 {
////	display: none;
////}
////.header-nav-item-level-0 {
////	&.active.sub {
////		.header-nav-list-level-1 {
////			display: block;
////		}
////	}
////}
////.header-nav-item-level-1 {
////	&.active.sub {
////		.header-nav-list-level-2 {
////			display: block;
////		}
////	}
////}
////.header-nav-list-level-2 {
////	padding-left: 10px;
////	padding-top: 5px;
////}
////.main-nav-hidden {
////	@include breakpoint(xmedium) {
////		position: absolute;
////		left: -1000px;
////		width: 100%;
////		&.visible {
////			position: relative;
////		}
////	}
////}
////.header-subnav {
////	@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
////		display: none !important;
////	}
////}
////
////@mixin nav-link {
////    //display: block;
////	color: $nav-dark;
////	//margin-bottom: 4px;
////	&:active, &:hover {
////		color: $nav-dark;
////	}
////	&:visited {
////		color: $nav-dark-hover;
////	}
////	.text {
////		@include transition;
////		border-bottom: 1px solid transparent;
////		&:hover {
////			border-color: $nav-dark;
////			//.header-white & {
////			//	border-color: $c-lightgray;
////			//}
////		}
////	}
////}
////.header-nav-link-level-0 {
////	@include nav-link;
////    position: relative;
////	//padding: 5px 20px 3px 0;
////	text-transform: uppercase;
////	font-size: 14px;
////	@include transition;
////	.active & .text {
////		font-weight: 800;
////		border-bottom: 2px solid $black;
////		@include breakpoint(xmedium) {
////			//.header-white & {
////			//	border-bottom: 2px solid $white;
////			//}
////		}
////	}
////}
////
////.icon-open {
////	display: inline-block;
////	vertical-align: middle;
////	width: 20px;
////	height: 20px;
////	background-image: url('/assets/workfiles/project/css/icons/SVG/plus-toggle-white.svg');
////	background-position: center;
////	background-repeat: no-repeat;
////	cursor: pointer;
////
////	.submenu-toggle & {
////		background-position: 10px center;
////	}
////
////	background-size: 15px;
////	transition: background 0.2s ease;
////}
////
////
////.open > .icon-open {
////	background: url('/assets/workfiles/project/css/icons/SVG/minus-toggle-white.svg') no-repeat;
////	background-position: center;
////	background-size: 15px 1px;
////}
////
////.submenu-toggle.open {
////	.icon-open {
////		background-position: 10px center;
////	}
////}
////.header-nav-link-level-1 {
////	@include nav-link;
////	&.submenu-toggle {
////	    position: relative;
////		padding: 2px 20px 2px 0;
////		margin-bottom: 2px;
////	}
////	.icon-open {
////		background-size: 11px;
////		background-position: 12px;
////	}
////	&.open {
////		.icon-open {
////			background-position: 12px;
////		}
////	}
////	.header-nav-item-level-1.active & {
////		font-weight: 800;
////		color: $black;
////		@include breakpoint(xmedium) {
////			//.header-white & {
////			//	color: $white;
////			//}
////		}
////	}
////}
////.header-nav-list-level-1 {
////	//margin-top: 5px;
////}
////.header-nav-item-level-1  {
////	line-height: 1.3;
////	font-size: 13px;
////	padding-bottom: 8px;
////	padding-left: 10px;
////}
////
////.header-nav-link-level-2  {
////	@include nav-link;
////	padding: 2px 0 3px;
////	.header-nav-item-level-2.active & {
////		font-weight: 800;
////		color: $black;
////		@include breakpoint(xmedium) {
////			//.header-white & {
////			//	color: $white;
////			//}
////		}
////	}
////}
////
////.header-nav h4 {
////	color: $black;
////	@include breakpoint(xmedium) {
////		//.header-white & {
////		//	color: $white;
////		//}
////	}
////	@include transition;
////}
////
////.header-nav-rootpage {
////	text-transform: uppercase;
////}
////
////@include breakpoint(xmedium) {
////	//.header-white .header-nav a {
////	//	color: $nav-light-hover;
////	//	&:hover {
////	//		color: $nav-light-hover;
////	//	}
////	//}
////}
////.header-left {
////	margin-top: 170px;
////	//margin-left: 26px;
////
////	width: 100%;
////	z-index: 9;
////	@include breakpoint(xmedium) {
////
////		//width: 200px;
////		height: calc(100vh - 250px); // Viewport - logo - footer
////		//padding-left: 10px;
////		transition: top 0.3s linear;
////		overflow: hidden;
////		background: transparent;
////
////	}
////	&-hovered .header-left {
////		@include breakpoint(xmedium) {
////			opacity: 1;
////		}
////	}
////	&-inner {
////		@include breakpoint(xmedium) {
////			overflow: hidden;
////			height:100%;
////		}
////	}
////	@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
////		margin-top: 20px;
////		.header-nav {
////			//margin-top: -60px;
////			width:100%;
////			padding-top: 90px;
////			background: #fff;
////			overflow:hidden;
////			&-list-level-0 {
////				width:110%;
////				height:100%;
////				padding-right:10%;
////				overflow: auto;
////			}
////		}
////		//
////	}
////	&.header-top-auto {
////		top: auto !important;
////	}
////	&-bckg {
////		@include breakpoint(xmedium) {
////			width: 250px;
////			height: 100vh;
////			position: fixed;
////			top: 0;
////			left: 0;
////			z-index: 1;
////			background: #fff;
////			opacity: 0;
////			transition: all 0.3s ease-in;
////		}
////	}
////	&-bckg.header-left-bckg-hovered {
////		@include breakpoint(xmedium) {
////			opacity:0.85;
////			transition: all 0.3s ease-in;
////		}
////	}
////}
////.header-left-wrapper {
////	width: 250px;
////	height: 100vh;
////	position: fixed;
////	top: 0;
////	left: -769px;
////	z-index: 9;
////	background: #fff;
////	transition: all 0.5s;
////	overflow: hidden;
////	&.visible {
////		left: 0;
////		transition: all 0.5s;
////	}
////	@media only screen and (max-width: 768px) {
////		left: unset;
////		right: -769px;
////		&.visible {
////			left: unset;
////			right: 0;
////			transition: all 0.5s;
////			bottom: 50px;
////			overflow-y: auto;
////			height: auto;
////		}
////	}
////}
////
////.icon-circle {
////	position: fixed;
////	top: 0;
////	width: 250px;
////	//width: map-get($breakpoints, xmedium);
////	height: 250px;
////	text-align: center;
////	margin-right: 0;
////	border-radius: 100%;
////	z-index: 4;
////	//@include breakpoint(map-get($breakpoints, xmedium)) {
////	@media only screen and (max-width: 768px) {
////		display: none;
////	}
////}
////.main-menu {
////	position: fixed;
////	top: 160px;
////	left: 40px;
////	color: #000;
////	&.inactive {
////		display: none;
////	}
////	.header-white & {
////		color: #fff;
////	}
////
////}
////
////// Color in navigation page configuration
////@mixin filled-nav-link($bckg) {
////	color: $white !important;
////	background: $bckg;
////	//padding: 3px 10px;
////	padding: 4px 15px;
////	margin-left: -10px;
////	margin-right: -10px;
////	.text:hover, .header-nav-item-level-0.current.active & .text, .active & .text {
////		border: none !important;
////	}
////	.icon-open {
////		background: url('/assets/workfiles/project/css/icons/SVG/plus-toggle-white.svg') no-repeat;
////		background-size: 15px;
////		background-position: 0 center;
////	}
////	&.open {
////		.icon-open {
////			background: url('/assets/workfiles/project/css/icons/SVG/minus-toggle-white.svg') no-repeat;
////			background-position: 0 center;
////			background-size: 15px 1px;
////		}
////	}
////
////}
////
////a.header-nav-link {
////	&-pink {
////		color: $pspink !important;
////		.text:hover,
////		.header-nav-item-level-0.current.active & .text,
////		.header-nav-item-level-0.active & .text {
////			border-color: $pspink !important;
////		}
////	}
////	&-red {
////		color: $psred !important;
////		.text:hover,
////		.header-nav-item-level-0.current.active & .text,
////		.header-nav-item-level-0.active & .text {
////			border-color: $psred !important;
////		}
////	}
////	&-green {
////		color: $psgreen !important;
////		.text:hover,
////		.header-nav-item-level-0.current.active & .text,
////		.header-nav-item-level-0.active & .text {
////			border-color: $psgreen !important;
////		}
////	}
////	&-pink-fill {
////		@include filled-nav-link($pspink);
////	}
////	&-red-fill {
////		@include filled-nav-link($psred);
////	}
////	&-green-fill {
////		@include filled-nav-link($psgreen);
////	}
////	&-black-fill {
////		@include filled-nav-link($black);
////	}
////	// Fix Paddings for subnavigation
////	&-level-1 {
////		&.submenu-toggle {
////			.icon-open {
////				top:-4px;
////			}
////		}
////
////		&.header-nav-link-pink,
////		&.header-nav-link-red,
////		&.header-nav-link-green,
////		&.header-nav-link-pink-fill,
////		&.header-nav-link-red-fill,
////		&.header-nav-link-green-fill,
////		&.header-nav-link-black-fill {
////			padding: 2px 7px !important;
////			margin-left: 0 !important;
////			margin-bottom: -4px !important;
////			.icon-open {
////				background-position: 0px 2px;
////				top:0;
////			}
////			&.open {
////				.icon-open {
////					background-position: 0px 10px;
////				}
////			}
////		}
////	}
////}
////
//.header-right {
//	position: fixed;
//	bottom: 0;
//	width: 100%;
//	background: $black;
//	height: 60px;
//	z-index: 10;
//	text-align: center;
//	.hide-header-icons & {
//		display: none;
//	}
//	@include breakpoint(xmedium) {
//		position: relative;
//		bottom: unset;
//		background: transparent;
//		z-index: $overlay-z-index + 1;
//	}
//}

//.header-links-item {
//	display: inline-block;
//	vertical-align: middle;
//	@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
//		vertical-align: top;
//		a {
//			width: 68px;
//			color: $white;
//			text-align: center;
//			text-decoration: none;
//		}
//		.icon {
//			display: block;
//			margin: 0 auto;
//		}
//	}
//}
//.header-links-item a {
//	cursor: pointer;
//	display: block;
//	@include breakpoint(xmedium) {
//		padding: 9px 13px 6px;
//	    margin: 0 7px 0 12px;
//	}
//	padding: 8px 0 8px;
//	margin: 1px 5px 0;
//	border-bottom: 2px solid transparent;
//	@include transition;
//	&:hover, &.active {
//		border-bottom: 2px solid $black;
//	}
//	&.open {
//		background: $white;
//		color: $black;
//	}
//	@include breakpoint(xmedium) {
//		//.header-white & {
//		//	color: $nav-light;
//		//	&:hover, &.active {
//		//		border-bottom: 2px solid $white;
//		//	}
//		//	&.open {
//		//		background: $white;
//		//		color: $black;
//		//	}
//		//}
//	}
//	.text {
//		font-size: 10px;
//		text-transform: uppercase;
//		@include breakpoint(xmedium) {
//			display: none;
//		}
//	}
//}
////a.search-toggle {
////	margin-right: 0;
////}
////a.icon-close {
////	position: absolute;
////	right: 10px;
////	@include breakpoint(xmedium) {
////		right: 20px;
////	}
////	cursor: pointer;
////}
//.header-search {
//	display: none;
//	position: absolute;
//	top: auto;
//	bottom: 60px;
//	z-index: 10;
//	right: 0;
//	background: $white;
//	width: 100%;
//	padding: 15px 35px 15px 15px;
//	@include breakpoint(xmedium) {
//		z-index: $overlay-z-index + 1;
//		padding: 22px 50px 26px 30px;
//		bottom: auto;
//		top: 50px;
//		width: 600px;
//	}
//	@include breakpoint(xxmedium) {
//		width: 800px;
//	}
//	@include breakpoint(large) {
//		width: 1000px;
//	}
//	//&-form {
//	//	position: relative;
//	//	a.icon-close {
//	//		right: -20px;
//	//		@include breakpoint(xmedium) {
//	//			right: -30px;
//	//		}
//	//	}
//	//	&-input {
//	//		width: 100%;
//	//		@include breakpoint(xmedium) {
//	//			font-size: 28px;
//	//			height: 50px;
//	//		}
//	//		border-radius: 0px;
//	//		border:none;
//	//		border-bottom: 1px solid $c-border;
//	//		padding: 0 35px 16px 0;
//	//		&::-webkit-input-placeholder {
//	//			@include breakpoint(xmedium) {
//	//				font-size: 28px;
//	//			}
//	//			color: $black;
//	//		}
//	//	}
//	//	.btn-search {
//	//		position: absolute;
//	//		right: 0;
//	//		top: 0;
//	//	}
//	//}
//}
////
////.header-nav {
////	@include breakpoint(xmedium) {
////		display: block !important;
////	}
////	@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
////		display: none;
////	    top: 0;
////		padding: 10px 20px;
////		transition: transform 0.4s;
////		-webkit-font-smoothing: antialiased;
////		height: 70vh;
////		overflow: auto;
////		z-index: 9;
////	}
////}
////
////.header-nav-icon-container{
////    position: fixed;
////    top: 10px;
////    right: 10px;
////    height: 50px;
////    width: 50px;
////    cursor: pointer;
////	z-index: 10;
////	@include breakpoint(xmedium) {
////		display: none;
////	}
////}
////
////.header-nav-icon {
////	display: block;
////	width: 22px;
////	height: 19px;
////	position: absolute;
////	right: 15px;
////	top: 15px;
////	transform: rotate(0deg);
////	@include transition(0.5s);
////	span {
////		display: block;
////		position: absolute;
////		height: 2px;
////		width: 50%;
////		background: $black;
////		.visible & {
////			background: $black;
////		}
////		opacity: 1;
////		transform: rotate(0deg);
////		@include transition(0.25s);
////		&:nth-child(even) {
////		  left: 50%;
////		}
////		&:nth-child(odd) {
////		  left:0px;
////		}
////		&:nth-child(1), &:nth-child(2) {
////		  top: 0px;
////		}
////		&:nth-child(3), &:nth-child(4) {
////		  top: 9px;
////		}
////
////		&:nth-child(5), &:nth-child(6) {
////		  top: 18px;
////		}
////	}
////	&.close span {
////		&:nth-child(1),&:nth-child(6) {
////			transform: rotate(45deg);
////		}
////
////		&:nth-child(2),&:nth-child(5) {
////			transform: rotate(-45deg);
////		}
////
////		&:nth-child(1) {
////			left: 1px;
////			top: 3px;
////		}
////
////		&:nth-child(2) {
////			left: calc(50% - 2px);
////			top: 3px;
////		}
////
////		&:nth-child(3) {
////			left: -50%;
////			opacity: 0;
////		}
////
////		&:nth-child(4) {
////			left: 100%;
////			opacity: 0;
////		}
////
////		&:nth-child(5) {
////			left: 1px;
////			top: 11px;
////		}
////
////		&:nth-child(6) {
////			left: calc(50% - 2px);
////			top: 11px;
////		}
////	}
////}
////
////// Hides nav on product detail page and on sales network page
////.nav-back-link {
////	display: none;
////	color: $c-text;
////	text-transform: uppercase;
////	margin-left: 10px;
////	margin-top: 20px;
////	@include transition;
////	text-decoration: none;
////	@include breakpoint(xmedium) {
////		margin-top: 0;
////		margin-left: 0px;
////	}
////	.icon {
////		width: 22px;
////		height: 15px;
////		margin-right: 15px;
////		margin-left: 0px;
////		transform: rotatey(180deg);
////		transition: linear 0.3s;
////	}
////	&.open .icon {
////		transform: rotatey(0deg);
////	}
////	&:hover {
////		color: $black;
////	}
////	&.show-back-link {
////		@include breakpoint(xmedium) {
////			display: block;
////			margin-bottom: 20px;
////		}
////	}
////}
////.detail-page-nav {
////	@include breakpoint(xmedium) {
////		.nav-back-link {
////			display: block;
////			margin-bottom: 20px;
////		}
////	}
////	.page-main {
////		padding-top: 0;
////	}
////	.header-left {
////		@include breakpoint(xmedium) {
////			//width: 170px;
////		}
////		opacity: 1;
////	}
////	//.header-logo {
////	//    width: 50px;
////	//	height: 39px;
////	//	background-size: 50px 39px;
////	//	@include breakpoint(xmedium) {
////	//		width: 83px;
////	//		height: 65px;
////	//		background-size: 83px 65px;
////	//	}
////	//}
////	@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
////		.nav-back-link {
////			margin-left: 10px;
////			margin-top: 10px;
////		}
////	}
////}
////
////
////.header-links {
////	.icon {
////		fill: $nav-dark;
////		.header-white & {
////			fill: $nav-light;
////            filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(121deg) brightness(104%) contrast(101%);
////		}
////	}
////	&.header-white {
////		.icon {
////			fill: $nav-light;
////            filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(121deg) brightness(104%) contrast(101%);
////		}
////	}
////}
////.nav-info-wrapper {
////	padding-left: 40px;
////	min-height: 52px;
////	.nav-back-trigger{
////		width: 35px;
////		height: 30px;
////		margin-bottom: 7px;
////		background: url(/assets/workfiles/project/css/icons/SVG/arrow-back.svg) no-repeat 0;
////		background-size: 25px;
////		cursor: pointer;
////		opacity: 0;
////		visibility: hidden;
////		transition: all 0.3s ease;
////		&.active {
////			opacity: 1;
////			visibility: visible;
////			transition: all 0.3s ease;
////		}
////	}
////	.nav-title {
////		//font-size: 11px;
////		//font-weight: bold;
////		//text-transform: uppercase;
////		a {
////			font-size: 11px;
////			font-weight: bold;
////			text-transform: uppercase;
////			text-decoration: none;
////			&:hover {
////				text-decoration: underline;
////			}
////		}
////	}
////	@media only screen and (max-width: map-get($breakpoints, xmedium) - 1){
////		padding-left: 25px;
////	}
////
////}
//////.nav-arrow {
//////	display: inline-block;
//////	width: 35px;
//////	height: 22px;
//////	float: right;
//////	background: url(/assets/workfiles/project/css/icons/SVG/arrow-next.svg) no-repeat center center;
//////	background-size: 5px;
//////	cursor: pointer;
//////}
////
////
////.nav-level-holder {
////	padding: 0 10px 0 40px;
////	@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
////		padding: 0 10px 0 25px;
////	}
////}
////
////.header-nav-grid {
////	position: absolute;
////	left: 0;
////	display: grid;
////	grid-template-columns: 250px 250px 250px;
////	transition: all 0.5s ease;
////	.nav-divider {
////		margin-top: 10px;
////		@media only screen and (max-width: map-get($breakpoints, xmedium) - 1){
////			margin-top: 15px;
////		}
////	}
////	&.level2active {
////		left: -250px;
////		transition: all 0.5s ease;
////	}
////	&.level3active {
////		left: -500px;
////		transition: all 0.5s ease;
////	}
////
////	ul {
////		padding: 0;
////		margin: 10px 0;
////		list-style: none;
////		li {
////			padding: 3px 0;
////			line-height: 1.3;
////			&.nav-arrow {
////				background: url(/assets/workfiles/project/css/icons/SVG/arrow-next.svg) no-repeat top 7px right 20px;
////				background-size: 5px;
////				cursor: pointer;
////				transition: all 0.3s ease;
////
////				&.navlevel-trigger:hover {
////					transition: all 0.3s ease;
////					background-position: top 7px right 15px;
////					filter: brightness(0);
////				}
////			}
////
////			a {
////				text-decoration: none;
////				transition: none;
////				font-size: 12px;
////				display: inline-block;
////				max-width: 160px;
////				border-bottom: 2px solid transparent;
////				&.active {
////					font-weight: bold;
////				}
////			}
////			a:hover {
////				border-bottom: 2px solid #000;
////				transition: none;
////			}
////			&.nav-color {
////
////				&-grey {
////					a {
////						color: #A2A2A2;
////						&:hover {
////							border-bottom: 2px solid #A2A2A2;
////						}
////					}
////					&:hover {
////						filter: unset !important;
////					}
////				}
////				&-red {
////					a {
////						color: #CF1515;
////						&:hover {
////							border-bottom: 2px solid #CF1515;
////						}
////					}
////					&:hover {
////						filter: unset !important;
////					}
////				}
////				&-pink {
////					a {
////						color: #FF4DAB;
////						&:hover {
////							border-bottom: 2px solid #FF4DAB;
////						}
////					}
////					&:hover {
////						filter: unset !important;
////					}
////				}
////				&-black-fill {
////					a {
////						transition: all 0.3s ease;
////						background-color: #000;
////						padding: 2px 3px 0px 3px;
////						color: #ffffff;
////						&:hover {
////							border-bottom: 2px solid transparent;
////						}
////					}
////					&.nav-arrow.navlevel-trigger:hover {
////						filter: none;
////					}
////					&:hover {
////						a {
////							background-color: lighten($nav-black, 25%);
////							transition: all 0.3s ease;
////						}
////					}
////				}
////				&-grey-fill {
////					a {
////						transition: all 0.3s ease;
////						background-color: #A2A2A2;
////						padding: 2px 3px 0px 3px;
////						color: #ffffff;
////						&:hover {
////							border-bottom: 2px solid transparent;
////						}
////					}
////					&.nav-arrow.navlevel-trigger:hover {
////						filter: none;
////					}
////					&:hover {
////						a {
////							background-color: lighten($nav-grey, $lighten);
////							transition: all 0.3s ease;
////						}
////					}
////				}
////				&-red-fill {
////					a {
////						transition: all 0.3s ease;
////						background-color: #CF1515;
////						padding: 2px 3px 0px 3px;
////						color: #ffffff;
////						&:hover {
////							border-bottom: 2px solid transparent;
////						}
////					}
////					&.nav-arrow.navlevel-trigger:hover {
////						filter: none;
////					}
////					&:hover {
////						a {
////							background-color: lighten($nav-red, $lighten);
////							transition: all 0.3s ease;
////						}
////					}
////				}
////				&-pink-fill {
////					a {
////						transition: all 0.3s ease;
////						background-color: #FF4DAB;
////						padding: 2px 3px 0px 3px;
////						color: #ffffff;
////						&:hover {
////							border-bottom: 2px solid transparent;
////						}
////					}
////					&.nav-arrow.navlevel-trigger:hover {
////						filter: none;
////					}
////					&:hover {
////						a {
////							background-color: lighten($nav-pink, $lighten);
////							transition: all 0.3s ease;
////
////						}
////					}
////				}
////			}
////
////		}
////	}
////
////	#nav-level2, #nav-level3 {
////		a {
////			text-transform: none;
////		}
////	}
////
////}
////.nav-level2-toggle, .nav-level3-toggle {
////	display: none;
////}
