$product-list-item-padding-mobile: 6px;
$product-list-item-padding-desktop: 8px;
$product-list-item-padding-large: 15px;
$product-list-item-icons-column-width: 20px;
$product-list-item-icons-column-gap: 10px;

$productColors: (
        "color-344": #fff,
        "color-436": #fff,
        "color-463": #53A700,
        "color-356": #53A700,
        "color-371": #53A700,
        "color-448": #53A700,
        "color-349": #262626,
        "color-441": #262626,
        "color-348": #C57705,
        "color-440": #C57705,
        "color-352": #8700FF,
        "color-353": #8700FF,
        "color-445": #8700FF,
        "color-362": #F14288,
        "color-454": #F14288,
        "color-455": #F14288,
        "color-346": #F5F5DB,
        "color-345": #F5F5DB,
        "color-437": #F5F5DB,
        "color-438": #F5F5DB,
        "color-364": #C1C1C1,
        "color-456": #C1C1C1,
        "color-373": #F1B500,
        "color-354": #F1B500,
        "color-446": #F1B500,
        "color-465": #F1B500,
        "color-350": #CB1607,
        "color-442": #CB1607,
        "color-361": #3875C0,
        "color-453": #3875C0,
        "color-365": #888888,
        "color-366": #888888,
        "color-457": #888888,
        "color-458": #888888,
        "color-360": #EFCD02,
        "color-452": #EFCD02,
        "color-357": #B2996D,
        "color-449": #B2996D,
        "color-372": #F7A602,
        "color-367": #F7A602,
        "color-459": #F7A602,
        "color-464": #F7A602,
        "color-347": #CB1607,
        "color-439": #CB1607,
        "color-359": #F7A602,
        "color-358": #F7A602,
        "color-450": #F7A602,
        "color-451": #F7A602,
        "color-343": #8B2500,
        "color-435": #8B2500,
        "color-368": #00008b,
        "color-460": #00008b,
        "color-363": #FFC0CB,
        "color-417": #7815c6,
        "color-370": #{radial-gradient(circle at 50% 0,
        rgba(255,0,0,.5),
        rgba(255,0,0,0) 70.71%),
      radial-gradient(circle at 6.7% 75%,
        rgba(0,0,255,.5),
        rgba(0,0,255,0) 70.71%),
      radial-gradient(circle at 93.3% 75%,
        rgba(0,255,0,.5),
        rgba(0,255,0,0) 70.71%) beige},
  //"visebojno": #{linear-gradient(70deg, #fff810  30%, rgba(0,0,0,0) 30%), linear-gradient(30deg, #63e89e 60%, #ff7ee3 60%)},
        "color-transparentna": transparent
);

@each $key, $value in $productColors {
  .product-list-base--item-color.#{$key} {
    background: $value;
  }

  .color-filter-checkmark.#{$key} {
    background: $value;
  }
}

$filterColors: (
        "color-344": #fff,
        "color-436": #fff,
        "color-463": #53A700,
        "color-356": #53A700,
        "color-371": #53A700,
        "color-448": #53A700,
        "color-349": #262626,
        "color-441": #262626,
        "color-348": #C57705,
        "color-440": #C57705,
        "color-352": #8700FF,
        "color-353": #8700FF,
        "color-445": #8700FF,
        "color-362": #F14288,
        "color-454": #F14288,
        "color-455": #F14288,
        "color-346": #F5F5DB,
        "color-345": #F5F5DB,
        "color-437": #F5F5DB,
        "color-438": #F5F5DB,
        "color-364": #C1C1C1,
        "color-456": #C1C1C1,
        "color-373": #F1B500,
        "color-354": #F1B500,
        "color-446": #F1B500,
        "color-465": #F1B500,
        "color-350": #CB1607,
        "color-442": #CB1607,
        "color-361": #3875C0,
        "color-453": #3875C0,
        "color-365": #888888,
        "color-366": #888888,
        "color-457": #888888,
        "color-458": #888888,
        "color-360": #EFCD02,
        "color-452": #EFCD02,
        "color-357": #B2996D,
        "color-449": #B2996D,
        "color-372": #F7A602,
        "color-367": #F7A602,
        "color-459": #F7A602,
        "color-464": #F7A602,
        "color-347": #CB1607,
        "color-439": #CB1607,
        "color-359": #F7A602,
        "color-358": #F7A602,
        "color-450": #F7A602,
        "color-451": #F7A602,
        "color-343": #8B2500,
        "color-435": #8B2500,
        "color-368": #00008b,
        "color-460": #00008b,
        "color-363": #FFC0CB,
        "color-417": #7815c6,
        "color-370": #{radial-gradient(circle at 50% 0,
        rgba(255,0,0,.5),
        rgba(255,0,0,0) 70.71%),
      radial-gradient(circle at 6.7% 75%,
        rgba(0,0,255,.5),
        rgba(0,0,255,0) 70.71%),
      radial-gradient(circle at 93.3% 75%,
        rgba(0,255,0,.5),
        rgba(0,255,0,0) 70.71%) beige},
  //"visebojno": #{linear-gradient(70deg, #fff810  30%, rgba(0,0,0,0) 30%), linear-gradient(30deg, #63e89e 60%, #ff7ee3 60%)},
        "color-transparentna": transparent
);

@each $key, $value in $filterColors {
  .color-filter-checkmark.#{$key} {
    background: $value;
  }
}


.product-list-main {
  img {
	  width: 100%;
  }

  &:last-child {
    margin-bottom: 50px;
  }

  @include from($tablet) {
    margin-left: 250px;
  }

  .product-list-section {
    opacity: 1;
    transition: opacity 1.2s;

    &.opacityMax {
      opacity: 0;
      //transition: opacity 1.1s;
    }
  }

}


.product-list-base--item-info {
  display: grid;
  grid-template-columns: $product-list-item-icons-column-width 1fr;
  grid-gap: $product-list-item-icons-column-gap;
}

.product-list-base--item-data-top {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-bottom: 15px;

  @include from($desktop) {
    grid-template-columns: 1fr auto;
  }

}


.product-list-base--item-color-container {
  position: absolute;
  bottom: 100px;
  left: 10px;
  right: 10px;
  z-index: 1;

  &.products-list-sbl {
    bottom: 150px;
  }
}

.product-list-base--item-color-wrap {
  display: flex;
  justify-content: center;
}

.product-list-base--item-color {
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
  border: 1px solid $white;
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  text-align: center;

  &.active-color {
    position: relative;
    top: -1px;
    width: 20px;
    height: 20px;
    border: 3px solid $white;

    @include from($desktop) {
      width: 29px;
      height: 29px;
    }
  }


  @include from($desktop) {
    width: 27px;
    height: 27px;
  }
}


.product-list-base--item-block {
  position: relative;
}

.product-list-base--item-stickers-container {
  position: absolute;
  top: 0;
  //right: $product-list-item-padding;
  right: 0;
  //z-index: 1;
}

.product-list-base--item-stickers-item {
  height: 30px;
  width: 30px;
  margin-bottom: 5px;

  // Product list
  &--multibase {
    display: grid;
    place-items: center;
    background: $actionred;
    color: $white;
    font-size: 14px;
    font-weight: 300;
    .product-related-list &,
    .product-recommended-list &,
    .product-list-indexedsearch & {
        font-size: 14px !important;
    }
  }

  @include from($desktop) {
    height: 50px;
    width: 50px;
    &--multibase {
      font-size: 24px;
      .product-list-indexedsearch & {
          font-size: 14px !important;
      }
    }
  }

  .product-list-indexedsearch & {
    height: 50px;
    width: 50px;
    @include from($tablet) {
      height: 30px !important;
      width: 30px !important;
    }
  }

  &.sticker-discount {
    background-color: $sticker-red;
    font-size: 23px;
    line-height: 30px;
    text-align: center;
    color: $white;

    @include from($desktop) {
      font-size: 35px;
      line-height: 50px;
    }
  }

  &.sticker-bestseller {
    img {
      width: 30px;
      height: 30px;

      @include from($desktop) {
        height: 50px;
        width: 50px;
      }
    }
  }
}


.product-list-base--item {
  min-height: 300px;
  opacity: 0;
  transition: 0.2s opacity;

  &:hover {
    .ic-wishlist,
    .ic-cart {
      opacity: 1;
      transition: opacity 0.2s;
    }
  }

  &.view-in {
    opacity: 1;
    transition: 0.2s opacity;
  }

  &-cart {
    display: flex;
    align-items: flex-end;
    padding-bottom: 3px;
  }

  .ic-wishlist {
    width: 18px;
    height: 18px;
    opacity: 0.3;
    transition: opacity 0.2s;

    @include from($mobile) {
      width: 20px;
      height: 20px;
    }
  }

  .ic-cart {
    width: 17px;
    height: 20px;
    opacity: 0.3;
    transition: opacity 0.2s;

    @include from($mobile) {
      width: 20px;
      height: 23px;
    }
  }
}

.product-list-base--item-image-holder {
  position: relative;

  & > .product-list-base--item-image:nth-child(2) {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    .product-list-base--item-image:nth-child(2) {
      opacity: 1;
      transition: opacity 0.2s;
    }
  }
}

.product-list-base--item-image {
  margin-bottom: 5px;
  width: 100%;
}

.product-list-base--item-name {
  margin-bottom: 0;
  min-height: 44px;
  font-size: 18px;

  @include until($tablet) {
    font-size: 14px;
    min-height: 32px;
  }

  @include until($desktop-large) {
    font-size: 16px;
    min-height: 38px;
  }
}

.product-list-base--item-price {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;

  @include from($desktop) {
    font-size: 22px;
  }

  &-old {
    margin-bottom: 3px;
    min-height: 16px;
    text-decoration: line-through;
    color: $c-text;
    line-height: 1.2;
  }
}

.product-list-base--item-data-bottom {
  display: grid;
  grid-template-columns: $product-list-item-icons-column-width auto;
  grid-gap: $product-list-item-icons-column-gap;

  &-link {
    text-decoration: none;
  }
}

.product-list-base--item-sbl {
  &.product-list-show-until-desktop {
    margin-top: 20px;
  }
}

.product-list-base--item-sbl-link {
  display: inline-block;
  padding: 2px 15px;
  border: 1px solid #999;
  border-radius: 5px;
  transition: all .2s;
  text-decoration: none;
}


.product-list-two-cols--item-wrap {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;

  .product-list-base--item {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 40px;
    padding: 0 $product-list-item-padding-mobile;

    @include from($desktop) {
      padding: 0 $product-list-item-padding-desktop
    }
    @include from($wide-screen) {
      padding: 0 $product-list-item-padding-large
    }
  }

  .product-list-base--item-color-container {
    bottom: 155px;

    @include from($tablet) {
      bottom: 160px;
    }
    @include from($desktop) {
      bottom: 115px;
    }
    @include from($desktop-large) {
      bottom: 135px;
    }

  }
}

.product-list-three-cols--item-wrap {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;

  .product-list-base--item {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
    //padding: 0 $product-list-item-padding-mobile;
    padding: 0;

    @include from($tablet) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 $product-list-item-padding-mobile;
    }

    @include from($desktop) {
      flex: 0 0 33%;
      max-width: 33%;
      padding: 0 $product-list-item-padding-desktop;
    }

    @include from($wide-screen) {
      padding: 0 $product-list-item-padding-large
    }

  }

  .product-list-base--item-color-container {
    bottom: 155px;

    @include from($tablet) {
      bottom: 165px;
    }
    @include from($desktop) {
      bottom: 175px;
    }

    @include from($wide-screen) {
      bottom: 135px;
    }
  }

  .product-list-base--item-sbl.product-list-show-from-desktop {
    @include from($desktop) {
      display: none;
    }
    @include from($wide-screen) {
      display: block;
    }
  }

  .product-list-base--item-sbl.product-list-show-until-desktop {
    @include from($desktop) {
      display: block;
    }
    @include from($wide-screen) {
      display: none;
    }
  }
}

.product-list-four-cols--item-wrap {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;

  .product-list-base--item {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
    padding: 0 $product-list-item-padding-mobile;

    @include from($tablet) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include from($desktop) {
      padding: 0 $product-list-item-padding-desktop
    }

    @include from($desktop-large) {
      flex: 0 0 25%;
      max-width: 25%;
      //padding: 0 $product-list-item-padding-large
    }
  }

  .product-list-base--item-color-container {
    bottom: 105px;

    @include from($tablet) {
      bottom: 160px;
    }
    @include from($desktop) {
      bottom: 120px;
    }
    @include from($desktop-large) {
      bottom: 175px;
    }
    @include from($ultra-wide-screen) {
      bottom: 130px;
    }

  }


  .product-list-base--item-sbl.product-list-show-from-desktop {
    @include from($desktop-large) {
      display: none;
    }
    @include from($ultra-wide-screen) {
      display: block;
    }
  }

  .product-list-base--item-sbl.product-list-show-until-desktop {
    @include from($desktop-large) {
      display: block;
    }
    @include from($ultra-wide-screen) {
      display: none;
    }
  }
}

.product-list-five-cols--item-wrap {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;

  .product-list-base--item {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 40px;
    padding: 0 $product-list-item-padding-mobile;

    @include from($tablet) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include from($desktop-large) {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  .product-list-base--item-color-container {
    bottom: 155px;

    @include from($tablet) {
      bottom: 160px;
    }
    @include from($desktop) {
      bottom: 120px;
    }
    @include from($desktop-large) {
      bottom: 175px;
    }

  }

  .product-list-base--item-color {
    display: inline-block;

    @include from($desktop-large) {
      width: 16px;
      height: 16px;
    }
    @include from($wide-screen) {
      width: 22px;
      height: 22px;
    }

    &.active-color {
      @include from($desktop-large) {
        width: 18px;
        height: 18px;
      }
      @include from($wide-screen) {
        width: 24px;
        height: 24px;
      }
    }
  }

  .product-list-base--item-sbl.product-list-show-from-desktop {
    @include from($desktop) {
      display: block;
    }
    @include from($desktop-large) {
      display: none;
    }
  }

  .product-list-base--item-sbl.product-list-show-until-desktop {
    @include from($desktop) {
      display: none;
    }
    @include from($desktop-large) {
      display: block;
    }
    //@include from($ultra-wide-screen) {
    //  display: none;
    //}
  }
  .product-list-base--item-stickers-item {
    @include from($desktop-large) {
      width: 30px;
      height: 30px;
    }

    img {
      @include until($tablet) {
        width: 30px;
        height: 30px;
      }
      @include from($desktop-large) {
        width: 30px;
        height: 30px;
      }
    }
  }

  .product-list-base--item-stickers-item--multibase {
    display: grid;
    place-items: center;
    background: $actionred;
    color: $white;
    font-size: 24px;
    font-weight: 300;
  }
}

.product-list-six-cols--item-wrap {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  .product-list-base--item {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 40px;
    padding: 0 $product-list-item-padding-mobile;

    @include from($tablet) {
      flex: 0 0 33%;
      max-width: 33%;
    }

    //@include from($desktop) {
    //  padding: 0 $product-list-item-padding-desktop
    //}

    @include from($desktop-large) {
      flex: 0 0 16.5%;
      max-width: 16.5%;
      //padding: 0 $product-list-item-padding-large
    }

    &-name {
      @include from($tablet) {
        font-size: 14px;
        min-height: 32px;
      }
    }

    &-price {
      @include from($tablet) {
        font-size: 18px;
      }
    }
  }

  .ic-wishlist {
    @include from($tablet) {
      width: 18px;
      height: 18px;
    }
  }

  .ic-cart {
    @include from($tablet) {
      width: 18px;
      height: 21px;
    }
  }

  .product-list-base--item-color-container {
    bottom: 150px;

    @include from($tablet) {
      bottom: 155px;
    }
    @include from($desktop) {
      bottom: 160px;
    }
    @include from($desktop-large) {
      bottom: 155px;
    }
    //@include from($wide-screen) {
    //  bottom: 155px;
    //}

  }

  .product-list-base--item-color {
    display: inline-block;

    @include from($desktop-large) {
      width: 16px;
      height: 16px;
    }

    &.active-color {
      @include from($desktop-large) {
        width: 18px;
        height: 18px;
      }
    }
  }

  .product-list-base--item-sbl.product-list-show-from-desktop {
    @include from($desktop) {
      display: none;
    }
  }

  .product-list-base--item-sbl.product-list-show-until-desktop {

    @include from($desktop) {
      display: block;
    }
    //@include from($ultra-wide-screen) {
    //  display: none;
    //}
  }
}

//*****
.product-list-show-from-desktop {
  display: none;
  @include from($desktop) {
    display: block;
  }
}

.product-list-show-until-desktop {
  display: none;
  @include until($desktop) {
    display: block;
  }
}

.hide-element {
  display: none;
}

/******** FILTER *******/
.product-list--filter {
  display: none;
  position: fixed;
  //top: 120px;
  bottom: 60px;
  right: 0;
  height: 500px;
  width: 299px;
  border-radius: 2px 0 0 2px;
  background-color: $white;
  z-index: 3;

  &.filter-active {
    display: block;
    width: 270px
  }

  @media (max-width: $tablet) and (max-height: 560px) {
    top: 60px;
    height: auto;
  }

  @include from($tablet) {
    display: block;
    top: 125px;
    //bottom: 80px;
    left: 35px;
    right: unset;
    width: 200px;
    height: auto;
    border: none;
  }

  @include until($desktop) {
    .accordion__trigger {
      position: relative;
      display: inline-flex !important;
      padding-right: 20px;
    }
  }

  &-title {
    margin-bottom: 10px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
  }

  &-content {
    margin-bottom: 25px;
  }
}

.product-list--filter-inner {
  position: absolute;
  top: 50px;
  bottom: 20px;
  left: 15px;
  right: 0;
  padding-right: 20px;
  padding-left: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin; // Firefox scroll width hack

  @include from($tablet) {
    top: 20px;
    left: 0;
  }
  @include from($desktop) {
    padding-right: 16px;
  }
}

// Cart product scroll style
//@include from($desktop-large) {
.product-list--filter-inner::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
}

.product-list--filter-inner::-webkit-scrollbar {
  width: 5px;
  background-color: #fafafa;
}

.product-list--filter-inner::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border: 2px solid #c1c1c1;
}

//}

.product-list--filter-open {
  position: fixed;
  bottom: 130px;
  right: 12px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  background: sassvg("ic-filter") no-repeat center, #000;

  @include from($tablet) {
    display: none !important;
  }
}

.product-list--filter-close {
  position: fixed;
  bottom: 130px;
  right: 12px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  background: sassvg("ic-filter-close", #fff) no-repeat center, #000;
  z-index: 4;

  @include from($tablet) {
    display: none !important;
  }
}

// Reset
.product-list--filter-reset {
  position: fixed;
  bottom: 525px;
  right: 12px;
  line-height: 23px;
  cursor: pointer;
  color: $black;
  font-size: 10px;
  background: sassvg("ic-reset") no-repeat center right;
  padding-right: 25px;

  @media (max-width: $tablet) and (max-height: 560px) {
    top: 70px;
    bottom: unset;
  }

  @include from($desktop) {
    left: 130px;
    top: 110px;
    bottom: unset;
    right: unset;
  }
}

// Sizes
.product-list--filter-sizes-wrap {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(20px, 25px));
  margin-left: -5px;
}

.product-list--filter-size-item {
  display: flex;
  height: 25px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #4A4A4A;
  cursor: pointer;
  transition: 0.1s background-color;

  &.selected-size {
    background-color: #4A4A4A;
    color: white;
    transition: 0.1s background-color;
  }
}

// Price
.product-list--filter-price-values-wrap {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  justify-content: space-between;
  margin-top: 10px;
}

.product-list--filter-price-value {
  color: #000000;
  font-size: 10px;
}

// checkboxes
.product-list--filter-checkboxes {
  max-width: 70%;

  @include until($tablet) {
    .accordion__content {
      margin-top: 15px;
    }

    .accordion__content .checkbox-container {
      margin-bottom: 15px;
    }
  }
  @include from($tablet) {
    max-width: unset;
  }
}

// Color
.product-list--filter-color {
  height: 22px;
  margin-bottom: 10px;
  margin-right: 10px;
  border: 1px solid #f3f3f3;
  border-radius: 50%;

  @include from($tablet) {
    margin-bottom: 7px;
    margin-right: 7px;
  }

  &.active {
    border: 1px solid $black;
  }

  &-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}

.color-filter {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  user-select: none;
}

.color-filter input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.color-filter-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid $white;
  border-radius: 50%;
}

//.color-filter input:checked ~ .color-filter-checkmark {
//  height: 20px;
//  width: 20px;
//  border: 1px solid #7F7F7F;
//}

.filter-overlay {
  display: none;
  position: fixed;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
  @media only screen and (min-width: map-get($breakpoints, xmedium) - 1) {
    display: none !important;
  }
}

// Checkboxes
/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize;
  color: #4A4A4A;
  font-size: 12px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border: 1px solid #979797;
  border-radius: 1px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-checkmark {
  //background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-checkmark {
  //background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
  left: 4px;
  top: 0px;
  width: 3px;
  height: 9px;
  border: solid #000;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hide-field {
  display: none !important;
}