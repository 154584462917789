.register-page {
	@include until($desktop) {
		margin-top: $top-margin !important;
	}
	.radio-single.checked {
		.register-premium-wrapper {
			display: block;
		}
	}
}

.modal {
	display: block;
	position: fixed;
	z-index: 10;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.7);
	p {
		color: inherit;
	}
	.list-heading {
		margin: 15px 0 5px;
	}
	&-content {
		background-color: #fefefe;
		margin: auto;
		padding: 30px;
		border: 1px solid #888;
		width: 80%;
		max-width: 800px;
	}
	&-button {
		@extend .btn-middle;
		margin: 0 !important;
		&-container {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-top: 30px;
		}
	}
	&-cancel {
		border: none;
		margin-top: 10px;
		text-decoration: underline;
		cursor: pointer;
		&:hover {

		}
	}
	.checkbox-label {
		margin-left: 0;
	}
}

/*
.register-page {
	@extend .inner-row;
	margin-bottom: 70px;
	.checkbox-wrap {
		@include breakpoint(medium) {
			padding-right: 30px;
		}

		@include breakpoint(xlarge) {
			padding-right: 0px;
		}
		@include breakpoint(1700px) {
			margin-right: 50px;
		}

		margin-bottom: 15px;
		display: inline-block;
		width: auto;
		.field-wrap {
			width: auto;
		}
	}
	.checkbox-single {
		margin-left: 0;
	}
	.checkbox-label {
		font-size: 24px;
	}
}
.register-page-inner {
	@include breakpoint(xxmedium) {
		@include xy-cell(6);
	}
}
.register-page-checkbox-container {
	border-top: 1px solid $black;
	border-bottom: 1px solid black;
	margin: 20px 0 30px 0;
	padding: 10px 0 25px 0;
	.checkbox-label {
		font-family: $f-special;
		font-size: 22px;
		margin-right: 0;
	}

}

.register-page-checkbox-title {
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 35px;

}
.register-page-disclaimer {
	font-size: 11px;
	line-height: 15px;
	a {
		color: $c-blue;
	}
}
*/
