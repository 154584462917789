//CONTACT PERSONS

.contact-persons {
	@extend .inner-row;
	&-single {
		padding-right: 50px;
		margin-bottom: 30px;
		display: inline-block;
		vertical-align: top;
		width:360px;
		max-width: 100%;
		@include breakpoint(large) {
			padding-right: 50px;
		}
		@include breakpoint(xxmedium) {
			padding-right: 10px;
		}
	}
	&-name {
		font-weight: bold;
	}
	p {
		margin: 0;
		line-height: 20px;
	}
	.contact-persons-position, a {
		font-size: 12px;
		color: $c-placeholder;
	}
}


// CONTACT DATA


.contact-data {

	&-inner {
		@extend .inner-row;
		padding: 25px 10px;
		@include breakpoint(medium) {
			padding: 30px;
		}
		@include breakpoint(xmedium) {
			margin-left: 250px;
		}
		@include breakpoint(large) {
			padding: 50px 10px;
		}
	}
	&-table-wrap {
		@include xy-cell(12);
		width: 100%;
		margin-left: 0;
		@include breakpoint(xxmedium) {
			@include xy-cell(7);
		}
	}
	table {
		margin-top: 5px;
		tr {
			border-bottom: 1px solid #ddd;
		}
		td {
			border: none;
			padding: 3px 1px;
			font-weight: 300;
			width: 50%;
			text-align: left;
			@include breakpoint(xxmedium) {
			}
		}
	}
	&-h {
		width: 100%;
	}
	&-logo-wrap {
		@include xy-cell(12);
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		img {
			width: 140px;
			height: 110px;
			display: block;
		}
		@include breakpoint(xxmedium) {
			width: 100%;
			@include xy-cell(5);

		}
	}
}

body.page111 {
	padding-bottom: 0;
}