.premium-teaser {

	&-single {
		// Positioning

		// Display & Box Model
		border: 5px solid $c-middlegray;
		margin: 25px 0;
		padding: 15px 0 15px 10px;

		// Color

		// Text


		// Other
		@include breakpoint(medium) {
			display: inline-block;
			padding-right: 10px;
		}
		@include breakpoint(xmedium) {
			max-width: 350px;
			margin: 20px 10px;
			padding: 20px;
		}
		@include breakpoint(large) {
			margin: 20px 30px;
		}
		.btn {
			// Positioning

			// Display & Box Model
			margin-top: 15px;
			margin-left: 30px;
			padding: 5px 30px;

			// Color

			// Text
			text-transform: uppercase;
			// Other
			.c-2-1-inner &,
			.c-2-2-inner & {
				@include breakpoint(medium) {
					margin-left: 0;
				}
				@include breakpoint(xxmedium) {
					margin-left: 35px;
				}
				@include breakpoint(large) {
					margin-left: 45px;
				}
			}
			@include breakpoint(xmedium) {
				margin-left: 45px;
			}

		}
		.icon {
			height: 25px;
			width: 25px;
			display: inline-block;
			vertical-align: top;
			margin: 5px 5px 0 0;
			color: $black;
			.c-2-1-inner &,
			.c-2-2-inner & {
				@include breakpoint(medium) {
					display: block;
					margin: 0 auto 10px;

				}
				@include breakpoint(xxmedium) {
					display: inline-block;
					margin-right: 5px;
				}
				@include breakpoint(large) {
					margin-right: 15px;
				}

			}
			@include breakpoint(xmedium) {
				margin: 5px 15px 0 0 ;
			}
		}
	}
	&-club,
	&-shop {
		@extend .premium-teaser-single;
	}
	&-shop {
		background-image: url(/assets/img/project/bckg/online-kupovina-small-1.png), url(/assets/img/project/bckg/online-kupovina-small-2.png);
		background-repeat: no-repeat;
		background-position: left bottom, 95% top;
		background-size: 30%, 20%;
		.c-2-1-inner &,
		.c-2-2-inner & {
			@include breakpoint(xmedium) {
			background-size: 45%, 40%;
			}
			@include breakpoint(xxmedium) {
				background-size: 30%, 20%;
			}
		}
	}
	&-club {
		background: url(/assets/img/project/bckg/nl-large-4.png) no-repeat;
		background-position: 160% bottom;
		background-size: 55%;

	}
	&-description {
		// Positioning

		// Display & Box Model
		display: inline-block;
		margin: 0;

		// Color

		// Text
		font-family: $f-special;
		font-size: 16px;

		// Other
		@include breakpoint(xxmedium) {
			font-size: 18px;
			line-height: 22px;
		}
		strong {
			text-transform: uppercase;
		}

	}
	&-landscape {
		background-image: url(/assets/img/project/bckg/premium-prednosti-large-1.png),
		url(/assets/img/project/bckg/premium-prednosti-large-2.png),
		url(/assets/img/project/bckg/premium-prednosti-large-3.png);
		background-repeat: no-repeat;
		background-position: left top, center bottom, right top;
		background-size: 15%, 60%, 15%;
		@include breakpoint(medium) {
			background-size: 7%, 50%, 5%;
		}


		@extend .inner-row;
		max-width: 920px;
		margin: 50px auto;
		border: 5px solid $c-middlegray;
		padding: 15px 10px;
		@include breakpoint(medium) {
			padding: 30px 5px 10px 20px;
		}
		@include breakpoint(xxmedium) {
			padding-left: 40px;
		}
		@include breakpoint(large) {
			padding-left: 100px;
		}

		&-description {
			@include xy-cell(12, $gutters: 0);
			@include breakpoint(600px) {
				@include xy-cell(6, $gutters: 0);
				display: inline-block;
				vertical-align: top;
			}
			@include breakpoint(medium) {
				width: 50%;
			}
		}

	}
	&-title {
		// Positioning

		// Display & Box Model
		margin: 0 0 25px 0;
		padding-left: 10px;

		// Color
		color: $black;

		// Text
		font-family: $f-special;

		// Other
		@include breakpoint(medium) {
			// Positioning

			// Display & Box Model
			padding: 0;

			// Color

			// Text
			font-size: 22px;

			// Other

		}
		@include breakpoint(large) {
			line-height: 36px;
		}
		span {
			// Positioning

			// Display & Box Model
			display: block;

			// Color

			// Text
			font-size: 30px;
			text-transform: uppercase;

			// Other
		}
	}
	&-links-wrapper {
		@include xy-cell(12, $gutters: 0);
		.btn-middle {
			min-width: 200px;
			padding: 10px 20px;
			@include breakpoint(xxmedium) {
				min-width: 250px;
			}
		}
		@include breakpoint(600px) {
			@include xy-cell(6, $gutters: 0);
			display: inline-block;
			margin-left: 30px;
		}
		@include breakpoint(xmedium) {
			padding-right: 0;
			margin-left: 0;
		}
		@include breakpoint(895px) {
			margin-left: 20px;
		}
	}
}
.premium-teaser-link-description {
	// Positioning

	// Display & Box Model
	margin-left: 30px;
	margin-top: 5px;

	// Color

	// Text
	font-size: 10px;

	// Other
	@include breakpoint(xxmedium) {
		font-size: 12px;
	}
	@include breakpoint(large) {
		margin-left: 50px;
	}
}
.premium-teaser-step {
	font-family: $f-special;
	font-size: 18px;
	// Positioning

	// Display & Box Model
	display: inline-block;
	min-width: 18px;
	margin-right: 10px;

	// Color

	// Text

	// Other
	@include breakpoint(large) {
		font-size: 22px;
		margin-right: 30px;
	}


}
.premium-example {

	&-left,
	&-right {
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		width: 40%;

	}
	&-left {
		background: url(/assets/img/project/bckg/divider.png) no-repeat center right;
		padding-right: 25px;
		width: 40%;
		@include breakpoint(xmedium) {
			width: 48%;
		}
	}
	&-right {
		padding-left: 15px;
		width: 50%;
		margin-top: 30px;
		max-width: 200px;
		@include breakpoint(xmedium) {
			margin-top: 0;
		}
		@include breakpoint(xxmedium) {
			margin-top: 30px;
		}
	}
}

// PREMIUM CLUB

.premium-club {
	@extend .inner-row;
	@include breakpoint(xmedium) {
		margin-left: 250px;
	}
	@include breakpoint(xxxlarge) {
		margin-left: 350px;
	}

	&-description-wrapper {
		padding: 80px 10px 0;
		@include breakpoint(xxmedium) {
			@include xy-cell(6, $gutters: 0);
		}
		@include breakpoint(xxlarge) {
			@include xy-cell(5, $gutters: 0);
			padding-right: 95px;
		}
	}

	&-slider-wrapper {
		width: 100%;
		height: 50vh;
		&.slick-dotted.slick-slider {
			margin-bottom: 0;			
		}
		@include breakpoint(xxmedium) {
			height: calc(100vh - 52px); //footer-height
			overflow: hidden;
			@include xy-cell(6, $gutters: 0);
		}
		@include breakpoint(xxlarge) {
			@include xy-cell(7, $gutters: 0);
		}
		//SLIDER DOTS
		.slick-dots {
			top: auto;
			bottom: 10px;
			right: 50%;
			transform: translateX(50%);
			li {
				display: inline-block;
			}
			@include breakpoint(xxmedium) {
				bottom: auto;
				top: 50%;
				right: auto;
				left: 20px;
				transform: translateY(-50%);
				li {
					display: block;
				}
			}
		}

	}
	&-list {

		&-wrapper {
			border-top: 3px solid $black;
			border-bottom: 3px solid $black;
			padding: 25px 0;
			h2 {
				// Positioning
	
				// Display & Box Model
				margin-bottom: 25px;
	
				// Color
				color: $black;
	
				// Text
				font-size: 22px;
				font-weight: 400;
				// Other
			}
			ul {
				@extend .ps-list;
			}
		}

	}
	&-text {
		// Positioning

		// Display & Box Model
		margin: 30px 0;

		// Color
		color: $c-text;

		// Text
		line-height: 20px;
		font-size: 12px;
	}
	&-register-title {
		// Positioning

		// Display & Box Model
		margin-bottom: 40px;

		// Color
		color: $black;

		// Text
		font-family: $f-special;
		font-size: 18px;
		// Other
		span {
			text-transform: uppercase;
		}
		@include breakpoint(xxlarge) {
			font-size: 24px;
		}
	}
	&-step {
		@extend .premium-teaser-step;
	}
	&-link-description {
		@extend .premium-teaser-link-description;
	}
}

.slick-slide.img-half-slider-holder {
	@extend .img-full-holder;
	@include breakpoint(xxmedium) {
		height: calc(100vh - 52px); //footer-height
	}
	height: 50vh;
}

.premium-data {
	&-table {
		td {
			border: none;
			padding: 3px 1px;
		}
		tr {
			border-bottom: 1px solid $c-border;
		}	
	}	
	&-headline {
		margin-top: 35px;
		font-weight: 600;
	}
}
.credit-earned {
	color: $psgreen;
	font-weight: 600;
}
.credit-sold {
	color: $psred;
	font-weight: 600;
}

.premium-orders-headline {
	margin-top: 50px;
	.icon {
		vertical-align: baseline;
		margin-right: 15px;
		@include breakpoint(xmedium) {
			margin-left: -40px;			
		}
	}
	.ic-premium {
		width: 25px;
		height: 24px;
	}
	.ic-cart {
		width: 22px;
		height: 26px;
	}
}

.account-data-wrap {
	h1 {
		margin-bottom: 20px;
	}
}

.order-data {
	&-list {
		border-bottom: 3px solid $c-border;
		margin-bottom: 30px;
		.accordion-container {
			border: none;
			padding-top: 20px;
		}
		.accordion-trigger {	
			border-width: 3px;
			border-top: 3px solid $c-border;
			border-bottom: 0;
			&.active {
				border-color: $black;
			}		
			@include breakpoint(xxmedium) {
				padding: 15px 70px 15px 0;
			}		
			padding: 15px 20px 15px 0;
			h3 {
				display: inline-block;
				span {
					font-size: 12px;
					font-weight: normal;
				}
			}
			.order-data-table {
				@include breakpoint(xmedium) {
					width: 250px;
					float: right;
				}
				@include clearfix();
				margin-bottom: 0;
				td {
					font-size: 14px;
				}
			}
		}
		.ic-calculator {
			width: 15px;
			height: 16px;
			vertical-align: top;
			margin-right: 20px;
			margin-top: 5px;
		}
		.order-product-nr {
			@include breakpoint(860px) {
				display: block;
				position: absolute;				
				top: 15px;
				left: -40px;
			}
			display:none;
			line-height: 1;
			font-size: 20px;
			font-family: $f-special;
		}
	}
	&-date {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 5px;
	}
	&-product-name {
		position: relative;
		border-top: 1px solid $c-border;
		border-bottom: 1px solid $c-border;
		padding: 10px 0;
		margin-bottom: 10px;
		p {
			margin: 0;
		}
	}
	&-table {
		td {
			padding: 3px 1px;
			font-size: 12px;
		}
	}
	&-product-single {
		margin-bottom: 20px;
	}
}
.orderlist-cart-sum { 
	padding-left: 40px;
	@include breakpoint(xmedium) {
		padding-left: 0;
		text-align: right; 
	}
}

.become-premium {
	@extend .premium-teaser-club;
	max-width: none;
	width: 100%;
	margin-left: 0;
	margin-right: 0;
	&-headline {
		display: inline-block;
		font-family: $f-special;
		max-width: 85%;
	}
	.premium-teaser-description {
		margin-left:35px;
		@include breakpoint(xmedium) {
			margin-left: 45px;
		}
	}
}

.account-deactivation-box {
	& p {
		margin: 0;
	}
}