.shopbylook {
  &-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 250px;
    padding-bottom: 50px;
    @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
      margin-left: 0;
    }

    @include breakpoint(xxmedium down) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(xsmall down) {
      grid-template-columns: 1fr;
    }
  }

  &-container--two-cols {
    grid-template-columns: 1fr 1fr;
    @include breakpoint(xsmall down) {
      grid-template-columns: 1fr;
    }
  }

  &-item {
    position: relative;
    margin: 0 3px 3px 0;
    @media only screen and (max-width: map-get($breakpoints, medium) - 1) {
      width: 100%;
      margin: 3px 0;
    }

    &-image {
      display: block;
      width: 100%;
      height: auto;
    }

    &-cta-wrapper {
      position: absolute;
      width: 100%;
      top: 63%;
      text-align: center;
      transition: all 500ms;
    }

    &-cta {
      display: inline-block;
      padding: 10px 30px;
      text-decoration: none;
      border-radius: 3px;
      border: 1px solid #000;
      color: #000;
      font-weight: 600;
      background: transparent;
      transition: all 500ms;

      span {
        @media only screen and (max-width: map-get($breakpoints, large) - 1) {
          display: none;
          padding: 3px 8px;
        }
        @media only screen and (max-width: map-get($breakpoints, medium) - 1) {
          display: inline;
          padding: 0;
        }
      }

      @media only screen and (max-width: map-get($breakpoints, medium) - 1) {
        padding: 10px 30px;
      }
    }

    @media only screen and (min-width: map-get($breakpoints, large) + 1) {
      &:hover &-cta-wrapper {
        top: 60%;
      }
    }

  }
}

.shopbylook-detail {
  &-img {
    display: block;
    width: 100%;
    height: auto;
  }

  &-container {
    @media only screen and (min-width: map-get($breakpoints, large)) {
      margin-left: 200px;
    }

    .product-detail-sizes {
      display: block !important;
    }
  }

  &-image {
    @media only screen and (min-width: map-get($breakpoints, xmedium)) {
      width: 48%;
      float: left;
      margin-right: 20px;
      padding-bottom: 55px;
    }

    &-image {
      width: 100%;
      height: auto;
    }
  }

  &-products {
    @media only screen and (min-width: map-get($breakpoints, xmedium)) {
      margin-bottom: 80px;
      width: 48%;
      float: left;
    }

    & > div.shopbylook-detail-product-single:first-child {
      @media only screen and (max-width: map-get($breakpoints, xmedium)) {
        margin-top: 15px;
      }
    }
  }

  &-product-single {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    display: grid;
    grid-template-columns: 120px auto;
    grid-gap: 0px 60px;
    margin: 0 0 20px 0;
    @media only screen and (min-width: map-get($breakpoints, xxmedium)) {
      grid-template-columns: 220px auto;
    }
    @media (max-width: map-get($breakpoints, xsmall)) {
      grid-gap: 0 20px;
    }

    .product-detail-sizes {
      display: none;
      float: none;
      text-align: left;
      @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
        padding-bottom: 10px;
      }

      .product-singleSize-radio {
        display: inline;

        &:last-child {
          label {
            margin-right: 0;
          }
        }
      }
    }

    .product-btn-cart-shopbylook {
      display: none;
      float: left;
      text-align: left;
      background: transparent;
      color: #000;
      text-transform: none;
      padding: 8px 47px 9px 0;
      font-weight: 400;
      @media (max-width: map-get($breakpoints, xmedium)) {
        padding: 3px 0 0 0;
        float: none;
      }

      &:hover {
        text-decoration: underline;
      }

      .ic-cart {
        background: url(/assets/workfiles/project/css/icons/SVG/cart.svg) no-repeat 0 50%;
        background-size: 15px;
        margin-right: 5px;
        margin-top: -3px;
      }

      &.error-cart {
        color: #a80012;
      }

      &.error-cart:hover {
        text-decoration: none;
        cursor: default;
      }
    }

    .icon.ic-wishlist {
      opacity: 0.3;
      @include transition;

      &:hover {
        @include transition;
        opacity: 1;
      }
    }

    .sbl-remove-from-list {
      display: none;
    }

    .sbl-select-size-message {
      display: none;
    }

    .info-box-wrap {
      margin-bottom: 3px;
    }
  }

  &-price {
    margin-bottom: 10px;
    @media (max-width: map-get($breakpoints, xmedium)) {
      //margin: 0;
      line-height: 1;
      margin: 10px 0 12px 0;
      .old-price {
        line-height: 1.5;
        font-size: 12px;
      }
    }

    .price {
      font-weight: bold;
    }
  }

  &-single-data {
    padding: 50px 20px 20px 0;
    @media (max-width: map-get($breakpoints, xxmedium)) {
      padding-top: 20px;
    }
    @media (max-width: map-get($breakpoints, xmedium)) {
      padding: 10px 20px 0px 0px;
    }

    .product-detail-sizes {
      padding-top: 0;
    }

  }

  &-single-image {
  }

  &-single-detail {
  }

  &-productname {
    margin-top: 0;
    position: relative; // Important for wishlist position
    @media (max-width: map-get($breakpoints, xmedium)) {
      margin-bottom: 5px;
    }

    .prod-link-wishlist {
      position: absolute;
      top: 3px;
      left: -35px;
      @media (max-width: map-get($breakpoints, xsmall)) {
        right: 20px;
        left: unset;
      }
    }

    &-link {
      text-decoration: none;
    }
  }

  &-add-all {
    float: right;
    margin-top: 40px;
    padding: 0 50px;
    height: 40px;
    line-height: 40px;
    border-radius: 2px;
    background-color: #000;
    text-align: center;

    a {
      color: #FFFFFF;
      font-size: 14px;
      text-decoration: none;
    }

    @media (max-width: map-get($breakpoints, xmedium)) {
      margin: 10px 20px 30px 0;
    }
    @media (max-width: 370px) {
      height: unset;
      padding: 10px 50px;
      line-height: 15px;
    }
  }

  &-popup {
    position: fixed;
    top: 50px;
    bottom: 80px;
    right: 40px;
    width: 600px;
    background: #ffffff;
    z-index: 11;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s linear;
    overflow-y: auto;
    @media (max-width: map-get($breakpoints, xmedium)) {
      //display: none;
      visibility: hidden;
      opacity: 0;
      top: 0px;
      right: 0;
      bottom: 50px;
      width: 100%;
      padding-top: 50px;
      max-height: 100%;
      transform: none;
      z-index: 8;
    }

    &.open {
      @media (max-width: map-get($breakpoints, xmedium)) {
        visibility: visible;
        opacity: 1;
        transition: all .3s linear;
      }
      @media (min-width: map-get($breakpoints, xmedium)) {
        max-height: 80%;
        transition: all .3s linear;
      }
    }

    &-inner {
      margin: 20px;
      @media (max-width: map-get($breakpoints, xmedium)) {
        padding-bottom: 10px;
      }

      &:after {
        display: block;
        height: 0;
        clear: both;
        content: '';
      }
    }

    &-title {
      color: #000000;
      font-size: 28px;
      font-weight: 100;
      line-height: 36px;
    }

    a.icon-close {
      top: 30px;
      right: 30px;
      cursor: pointer;
      @media (max-width: map-get($breakpoints, xmedium)) {
        display: none;
      }
    }

    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .7);
      z-index: 4;
    }

    &-overflow {
      //max-height: stretch;
      //overflow-y: auto;
      margin: 35px 0;
      @media (max-width: map-get($breakpoints, xmedium)) {
        //max-height: stretch;
        //overflow-y: auto;
        margin: 30px 0;
      }

    }

    &-addtocart {
      display: inline-block;
      float: right;
      margin-right: 13px;
      min-width: 66px;
      height: 40px;
      border-radius: 2px;
      background-color: #000000;
      text-align: center;
      line-height: 40px;
      padding: 0 50px;
      //a {
      color: #FFFFFF !important;
      //font-family: Krub;
      font-size: 14px;
      text-decoration: none;
      //}
      &:hover {
        color: #FFFFFF;
      }

      @media (max-width: map-get($breakpoints, xmedium)) {
        margin-right: 18px;
      }
    }

    .shopbylook-detail-product-single:last-child {
      margin: 0;
    }

    .shopbylook-detail-product-single {
      grid-template-columns: 145px auto;
      grid-gap: 0 40px;
      @media (max-width: map-get($breakpoints, xmedium)) {
        grid-gap: 0 20px;
      }
    }

    .shopbylook-detail-single-data {
      position: relative;
      padding: 25px 20px 20px 0;
      @media (max-width: map-get($breakpoints, xmedium)) {
        padding: 20px 0px 15px 0;
      }
    }

    .product-btn-cart-shopbylook {
      display: none !important;
    }

    .prod-link-wishlist {
      display: none;
    }

    .product-detail-sizes {
      padding-bottom: 0;

      &:after {
        clear: both;
        display: block;
        content: '';
        height: 0;
      }
    }

    .product-sizeselect {
      color: #4A4A4A;
      //font-family: Krub;
      font-size: 14px;
      line-height: 18px;
      padding: 6px !important;
    }

    .sbl-remove-from-list {
      display: block;
      padding-right: 11px;
      width: 90px;
      min-width: 90px;
      height: auto;
      position: absolute;
      right: 13px;
      top: 50%;
      transform: translateY(-50%);
      background-position-x: right;
      color: #4A4A4A;
      //font-family: Krub;
      font-size: 10px;
      line-height: 13px;
      @media (max-width: map-get($breakpoints, xmedium)) {
        top: 35px;
        transition: none;
        right: 5px;
        height: 11px;
        width: 11px;
        min-width: 11px;
        z-index: 1;
        &-text {
          display: none;
        }
      }

    }

    .product-size {
      padding: 0 8px !important;
      @media (max-width: map-get($breakpoints, xmedium)) {
        margin: -10px 0 0 -17px !important;
      }
    }

    .sbl-select-size-message {
      display: none;
      color: #A80012;
      font-size: 10px;
      line-height: 13px;
    }
  }

}

.shopbylook-detail-trigger {
  display: none;
}

.shopbylook-detail-container {
  .product-size {
    display: inline-block;
    border: 1px solid transparent;
    padding: 5px 5px 0 8px;
    margin: -10px 0 0 -18px;

    .product-sizeselect {
      font-size: 14px;
      margin-right: 2px;
      margin-bottom: 0;
      padding: 8px;
      border: 1px solid transparent;
      @media (max-width: map-get($breakpoints, xsmall)) {
        padding: 5px;
      }

      &.product-singleSize-radio-selected {
        background: none;
        border: 1px solid #4A4A4A;
        border-radius: 2px;
        color: #000000;
        font-weight: 600;
      }
    }

    &.error-nosizeselected {
      box-shadow: none;
      border: 1px solid #A80012;
      border-radius: 2px;
      padding: 0px 8px 0 8px;

      .product-btn-cart {
        background-color: $red;
      }
    }
  }

  .product-singleSize-radio.has-not-stock {
    pointer-events: none;
  }
}

.fixedBackground {
  overflow: hidden;
  height: 100vh;
}
