body {
	// Needed for sticky footer
	min-height:100%;
	font-family: $f-text;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.7;
	color: $c-text;
	background: $white;
	//background-image: url('/assets/img/project/bckg/roses-left.png');
	//background-position: 20% top;
	//background-repeat: no-repeat;
	padding-bottom: $footer-closed;
	margin: 0;
	@include breakpoint(xmedium) {
		margin: 0 0 $footer-closed;
	}
	//@include breakpoint(large) {
	//	background-image: url('/assets/img/project/bckg/roses-left.png'), url('/assets/img/project/bckg/roses-right.png');
	//	background-position: -10% 25%,110% 60%;
	//}
}

*:focus {
	outline: none;
}
nav {
	ul {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
			display: block;
		}
	}
	a {
		text-decoration: none;
		&:link{
			text-decoration: none;
		}
	}
}
p {
	margin: 15px 0;
}
.indent-content {
	@include indent-content;
}

.page-main {
	&.page-padding-top {
		//padding-top: 80px;
		@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
			//padding-top: 50px;
			.detail-page-nav & {
				//padding-top: 100px;
			}
		}
	}
}


//.remove-padding-bottom {
//    .page-main-container {
//        padding-bottom: 0 !important;
//        @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
//            padding-bottom: 0 !important;
//        }
//    }
//    .slick-dotted.slick-slider {
//        margin-bottom: 0;
//    }
//}

.hidden {
	display: none !important;
}

.info-trigger {
	cursor: pointer;
}

.info-box {
	display: none;
	@include horizontal-center-content;
	top:5%;
	z-index: 9;
	background: $white;
	border:10px solid white;
}

.info-box-overlay {
	display: none;
	position: fixed;
	top: 0;
	pointer-events: none;
	width:100%;
	height:100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: $overlay-z-index;

    @include until($desktop) {
      display: none !important;
    }
	//@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
	//	display: none !important;
	//}
}
.search-overlay {
	display: none;
	position: fixed;
	top: 0;
	pointer-events: none;
	width:100%;
	height:100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: $overlay-z-index;

  //@include from($desktop) {
  //  display: none !important;
  //}
}
.navigation-box-overlay {
	display: none;
	position: fixed;
	top: 0;
	pointer-events: none;
	width:100%;
	height:100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: 8;
}

@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
	.teaser-fixed {
		position: static !important;
		right: auto !important;
		top: auto !important;
	}
}

.footnote {
	display: inline-block;
	min-height: 36px;
	font-size: 12px;
	padding-left: 45px;
	//padding-top: 10px;
	background: url('/assets/img/project/bckg/rose-blue@2x.png') no-repeat top left;
	background-size: 36px 38px;
}
.icon-footnote {
	@include inline(super);
	width: 11px;
	height: 12px;
	background: url('/assets/img/project/bckg/rose-blue@2x.png') no-repeat top left;
	background-size: 11px 12px;
	margin-left: 5px;
}

.page-main {
	ul {
		padding-left: 0;
		list-style: none;
	}
}

.ps-list {
	li {
		background: url(../workfiles/project/css/icons/SVG/polygon.svg) no-repeat left center;
		background-size: 18px;
		padding-left: 30px;
		color: $black;
		line-height: 30px;
		text-transform: uppercase;
		@include breakpoint(xmedium) {
			font-size: 16px;
		}
	}
}

ul.f3-widget-paginator {
	display: table;
	width: 100%;
	text-align: center;
	margin: 20px auto;
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		vertical-align: middle;
		width: 30px;
		height: 30px;
		border-radius: 20px;
		background: $c-lightgray;
		margin: 0 5px 5px;;
		color: $c-gray;
		line-height: 30px;
		a {
			color: $c-gray;
			text-decoration: none;
			border-radius: 20px;
			display: block;
			line-height: 30px;
		}
		&.next a {
			font-size: 0;
			&:after {
				content: '>';
				font-size: 14px;
				vertical-align: top;
			}
		}
		&.previous a {
			font-size: 0;
			&:after {
				content: '<';
				font-size: 14px;
				vertical-align: top;
			}
		}
		&.current,
		&:hover,
		&:hover a {
			background: $c-gray;
			color: $white
		}
	}
}

.inline-content {
	@include inline();
}

.ce-gallery img {
	max-width: 100%;
	height: auto;
}

.ce-center figure {
	margin: 1em 0;
	text-align: center;
}