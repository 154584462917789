.faq {
	@extend .wrapper_outer;
	position: relative;
	@include breakpoint(xmedium) {
		margin-left: 250px;
		margin-right: 20px;
	}
	@include breakpoint(large) {
		margin-left: 350px;
	}
	&-inner {
		width: 100%;
		@include breakpoint(xxmedium) {
			min-height: 430px;
		}
		@media screen and (max-width: map-get($breakpoints, xxmedium) - 1 ) {
			min-height: 0 !important;
		}
	}
	&-h {
		@include h1;
		padding: 10px;
		@include breakpoint(xxmedium) {
			width: 37%;
		}
	}
	&-trigger {
		display: block;
		text-decoration: none !important;
		color: $c-text;
		border-bottom: 1px solid $c-lightgray;
		padding: 12px;
		@include transition();
		@include breakpoint(xxmedium) {
			width: 37%;
			position: relative;
			cursor: pointer;
			&:after {
				@include transition();
				z-index: 2;
				position: absolute;
				content: '';
				right: -7%;
				top: 50%;
				transform: translateY(-50%);
				width: 0;
				height: 0;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
				border-left: 20px solid $white;
				@include breakpoint(xlarge) {
					border-top: 30px solid transparent;
					border-bottom: 30px solid transparent;
					border-left: 30px solid $white;
				}
			}
			&:hover, &.open {				
				@include breakpoint(xxmedium) {
					&:after {
						right: -13%;
					}
				}
				@include breakpoint(xlarge) {
					&:after {
						right: -11%;
					}
				}
			}
		}
		&:hover, &.open {				
			color: $black;	
			border-bottom: 1px solid $black;			
		}
		&.open {
			font-weight: bold;
			border-bottom: 3px solid $black;
		}
	}

	&-description-support {
		width: 100%;
		border-top: 1px solid $c-border;
		border-bottom: 1px solid $c-border;
		padding: 20px 0;
		font-size: 12px;
		margin-top: 50px;
		h3 {
			font-size: 12px;
			text-transform: uppercase;
			font-weight: 600;
			margin-bottom: 0;
		}
		p {
			margin: 0;
		}
		.support-email {
			font-weight: bold;
			font-size: 14px;
		}
		.icon {
			@include inline();
			width: 50px;
			height: 50px;
			border-radius: 100%;
			background: $c-middlegray url('/assets/workfiles/project/css/icons/SVG/tutorial.svg') no-repeat center;
			background-size: 10px;
			margin-right: 15px;
		}
		.text {
			@include inline();
			@media only screen and (max-width: 400px) {
				max-width: 75%;
			}
		}
	}
	&-description {
		display: none;
		padding: 15px;
		background: $c-lightgray;
		@include breakpoint(xxmedium) {
			height: 550px;
		}
		overflow-y: auto;
		&.open {
			display: block;
		}
		@include breakpoint(xxmedium) {
			padding: 60px 70px;
			position: absolute;
			right: 0;
			top: 0;
			width: 60%;
		}
		@media screen and (max-width: map-get($breakpoints, xxmedium) - 1 ) {
			min-height: 0 !important;

		}
	}
}

.faq-form-wrap {
	.inner-one-col {
		padding-top: 50px;
		margin-top: 30px;
		border-top: 10px solid $c-middlegray;		
	}
	.form-title {
		display: block;
		max-width: 500px;
		margin-bottom: 20px;
	}
	.select2-container, input, select, textarea {
		@include breakpoint(xmedium) {
			width: 500px;
		}
	}
}