.ce_single_img_priceB {

  &__wrapper {

  }
  &__container {

  }
  &__inner {
    margin: auto;

    @include from($tablet) {
      max-width: 553px;
    }

  }

  &__single {
    display: grid;
    grid-template-columns: 1fr;
    &_holder {
      &_image {

      }
    }

    &_info {
      display: inline-block;
      margin: auto;
      &_row {
        display: grid;
        grid-template-columns: 20px auto;
        grid-gap: 10px;
        margin: 0 auto;

        .ce_single_product_name {
          margin-left: 0;
          margin-right: 0;
        }
      }
      &_row2 {
        display: grid;
        grid-template-columns: 20px auto;
        grid-gap: 10px;
        padding-left: 0 !important;
        margin: 0 auto;
        line-height: 1;

         &-icon {
          display: flex;
          align-items: flex-end;

          .ic-cart {
            width: 17px;
            height: 20px;

            @include from(641px) {
              width: 20px;
              height: 23px;
            }
          }
        }
      }
    }
  }
  &__holder {
    position:relative;
    margin: auto;
    padding-bottom: 178%;
    height: 0;
  }
  &__player {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit: cover;
    cursor: pointer;
  }
}
