.gallery-item {
	margin:0 0 20px;
	a.lightbox-link {
		display: block;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
	.gallery-gif {
		max-width: 100%;
		@include breakpoint(medium) {
			max-width: 400px;
		}
	}
	&.loaded {
		border-bottom: 1px solid $black;
	}
}
