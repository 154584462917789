.ce_video_full {
  &__wrapper {
    @include intend-fullwidth;
  }

  &__container {

  }

  &__inner {
    &.width-tablet {
      // max-width: 375px;
    }

    margin: auto;
  }

  &__holder {
    position: relative;
    //max-width: 375px;
    //height: 210px;
    margin: auto;
    padding-bottom: 56.25%;
    height: 0;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;

    &_mobile {
      display: none;
      @media only screen and (max-width: map-get($breakpoints, xmedium)) {
        display: inline-block;
      }
    }
    &_tablet {
      display: none;
      @media only screen and (min-width: map-get($breakpoints, xmedium)+1) and (max-width: map-get($breakpoints, large)) {
        display: inline-block;
      }
    }
    &_desktop {
      display: none;
      @media only screen and (min-width: map-get($breakpoints, large)+1) {
        display: inline-block;
      }
    }
  }

}


/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_video_full {


    &__inner {
      &.width-tablet {
        // max-width: 1024px;
      }
    }
  }
}

/// @include breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_video_full {

    &__inner {
      // max-width: 1920px;
    }

    &__holder {
      // max-width: 1920px;
    }

  }
}


