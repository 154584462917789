/* ---------------------------------- TAGS ---------------------------------- */

article, aside, figure, footer, header, hgroup, nav, section {
	display: block;
}

a {
	color: $a-link-color;
	text-decoration: underline;
	cursor: pointer;
	&:hover {		
		text-decoration: none;
	}
	&:visited,
	&:hover,
	&:active {
		color: $a-hover-active-color;
	}
	&[href^=tel] {
		color: inherit; 
		text-decoration: none;
	}
}

hr {
	display: $hr-display;
	height: $hr-height;
	border: 0;
	border-top: $hr-border-top;
	margin: $hr-margin;
	padding: $hr-padding;
}

/* ============================================ *
 * Typography
 * ============================================ */

h1, .h1 { @include h1; }

h2, .h2 { @include h2; }

h3, .h3 { @include h3; }

h4, .h4 { @include h4; }

h5, .h5 { @include h5; }

h6, .h6 { @include h6; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .ce-h {
	a, a:hover {
		text-decoration: none;
	}
}

.white-text {
    color: $white;
}
.black-text {
    color: $black;
}
.red-text {
    color: $red;
}
.purple-text {
    color: $purple;
}
.brown-text {
    color: $brown;
}

strong, b {
	font-weight: 700;
	color: inherit;
}

