.img-bckg-holder {
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	&.img-full-bckg-position-bottom {
		background-position: center bottom;	
	}
	&.img-full-bckg-position-center {
		background-position: center center;	
	}
}
.img-full-holder {
	@extend .img-bckg-holder;
	position: relative;
	width: 100%;
	height: 80vh;
	background-color: $c-lightgray;
	transition-timing-function: ease-in;
	@include breakpoint(xmedium) {
		height: calc(100vh - 52px); //footer-height
	}
}
.gradient-mask {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	.light-image &, .light-image-slide & {
		background: linear-gradient(110deg, rgba(255,255,255,0.3) 0%, transparent 30%);		
	}
	.light-image-2 &, .light-image-slide-2 & {
		background: linear-gradient(110deg, rgba(255,255,255,0.5) 0%, transparent 30%);		
	}
	.light-image-3 &, .light-image-slide-3 & {
		background: linear-gradient(110deg, rgba(255,255,255,0.7) 0%, transparent 30%);		
	}
	.dark-image &, .dark-image-slide & {
		background: linear-gradient(110deg, rgba(0,0,0,0.3) 0%, transparent 30%);	
	}
	.dark-image-2 &, .dark-image-slide-2 & {
		background: linear-gradient(110deg, rgba(0,0,0,0.5) 0%, transparent 30%);	
	}
	.dark-image-3 &, .dark-image-slide-3 & {
		background: linear-gradient(110deg, rgba(0,0,0,0.7) 0%, transparent 30%);	
	}
	pointer-events: none;
}
.img-slider-full {
	width: 100%;
	height: auto;
}

.img-full-width {
	position: relative;
	picture, img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.slider-full-holder {
	height: auto;
	overflow: hidden;
}
.slider-item {
	position: relative;
}
.slider-text {
	display: none;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	.slick-current & {
		display: block;
	}
	&-inner {		
		pointer-events: auto;
		text-align: center;
		max-width: 95%;
		@include absolute-center-content;		
		@include breakpoint(xmedium) {
			max-width: 50%;
		}
		z-index: 1;
	}
	&-blur {
		display: none;
		position: absolute;
		left: 15%;
		width: 70%;
    	top: 25%;
	    height: 60%;
		transform: rotate(4deg);
		border-radius: 100% 50% 100% 50%;
		.dark-image-slide & {
			background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 60%);
		}
	}
}
.slider-headline {
	.dark-image-slide & {
		color: $white;		
		a {
			color: $white;
		}
		.ce-h {
			color: $white;		
		}
	}
	font-family: $f-special;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 15px;
	h1 {
		@include breakpoint(xmedium) {
			font-size: 54px;
		}	
	}
	h2 {
		@include breakpoint(xmedium) {
			font-size: 48px;
		}	
	}
}
.slider-description {
	font-family: $f-special;
	font-size: 16px;
	color: $black;
	.dark-image-slide & {
		color: $white;
	}
}

.btn-slider {
	@extend .btn-black-border;
	.dark-image-slide & {
		@extend .btn-white-border;
		&:hover {
			background: transparent;			
		}
	}
}
.slider-mask {
	display: block;
	position: absolute;
	top: 0;
	@include breakpoint(xmedium) {
		width: 50%;
		left:-10%;
	}
	left: -20%;
	width: 70%;
	height: 100%;
	background: $red;	
	background-color: rgba(35,31,32,0.8);
	transform: skewX(14deg);
}
.slider-with-mask {
	.slider-text {
		transform: none;
		color: $white;
		text-align: left;
		top: 20px;
		left: 20px;
		max-width: 81%;
		@include breakpoint(xmedium) {
			top: 50%;
			left: 200px;
			max-width: 400px;
		} 
		a {
			color: $white;
		}
	}
	.slider-headline {
		position: relative;
		padding-bottom: 15px;
		&:after {
			content: '';
			position: absolute;
			bottom:0;
			left: 0;
			width: 170px;
			max-width: 100%;
			height: 3px;
			background: $white;
		}
		.ce-h {
			margin-bottom: 0;
		}
	}
}
.video-element {
	cursor: pointer;
	max-width: 100%;
	height: auto;
	@include breakpoint(xxmedium) {
		object-fit:cover;
	}
	&-wrapper {
		position:relative;
		width: 100%;
		height: 100%;
		text-align: center;	
		background-color: $c-lightgray;	
		&:after {
			display: none;
			pointer-events: none;
			@include absolute-center-content;
			content: '';
			width: 70px;
			height: 70px;
			background: url('/assets/workfiles/project/css/icons/SVG/Youtube.svg') no-repeat center;			
			background-size: 70px auto;
			opacity: 0.7;
		}
		&.paused {
			&:after {
				display: block;
				@media only screen and (max-width: map-get($breakpoints, medium) - 1) {
					display: none;
				}
			}
			&:hover {
				&:after {
					opacity: 1;
				}		
			}
		}
		&.muted {
			&:after {
				display: block;
				background: url('/assets/workfiles/project/css/icons/SVG/speaker-icon.svg') no-repeat center;			
				background-size: 70px auto;
			}
		}
	}
}

.video-full-holder {
	position:relative;
    width: 100%;
    height: 350px;
    background-color: $c-lightgray;
    @include breakpoint(medium) {
	    height: 40vh;
	}
    @include breakpoint(xmedium) {
	    padding-bottom:56.25%; 
	    height: 0;	    
    }
    overflow:hidden;
	.video-element {
		position:absolute;
	    top:0;
	    left:0;
	    width:100%;
	    height:100%;
	    object-fit: cover;
		cursor: pointer;
	}
	&:after {
		display: none;
		pointer-events: none;
		@include absolute-center-content;
		content: '';
		width: 70px;
		height: 70px;
		background: url('/assets/workfiles/project/css/icons/SVG/Youtube.svg') no-repeat center;			
		background-size: 70px auto;
		opacity: 0.7;
	}
	&.paused {
		&:after {
			display: block;
			@media only screen and (max-width: map-get($breakpoints, medium) - 1) {
				display: none;
			}
		}
		&:hover {
			&:after {
				opacity: 1;
			}		
		}
	}
	&.muted {
		&:after {
			display: block;
			background: url('/assets/workfiles/project/css/icons/SVG/speaker-icon.svg') no-repeat center;			
			background-size: 70px auto;
		}
	}
}
.video-full-slider {
	width: 100%;
	height: auto;
	background-color: $c-lightgray;
	&:after {
		display: none;
		pointer-events: none;
		@include absolute-center-content;
		content: '';
		width: 70px;
		height: 70px;
		background: url('/assets/workfiles/project/css/icons/SVG/Youtube.svg') no-repeat center;			
		background-size: 70px auto;
		opacity: 0.7;
	}
	&.paused {
		&:after {
			display: block;
			@media only screen and (max-width: map-get($breakpoints, medium) - 1) {
				display: none;
			}
		}
		&:hover {
			&:after {
				opacity: 1;
			}		
		}
	}
}

.youtube-full-holder {
    position:relative;
    width: 100%;
    height: 350px;
    @include breakpoint(medium) {
	    height: 40vh;
	}
    @include breakpoint(xmedium) {
	    padding-bottom:56.25%; 
	    height: 0;	    
    }
    overflow:hidden;
    .youtube-full-holder-iframe {
	    position:absolute;
	    top:0;
	    left:0;
	    width:100%;
	    height:100%;
	}
	@media only screen and (max-width: map-get($breakpoints, xmedium)) {
		background-color: $black;
		.youtube-full-holder-iframe {
			@include vertical-center-content;
		}		
	}
}
.youtube-full-slider {
	width: 100%;
	height: auto;
	&-iframe {
	    width:100%;
	    
	}
}
.video-text-above {
	max-width: 800px;
	margin: 0 auto;
	position: relative;
	.text-above {
		opacity: 0;
		@include transition();
		position: absolute;
		max-width: 400px;
		z-index: 1;
		bottom: 20px;
		left: 40px;
		color: $white;
		.ce-h, h1, h2, h3, h4, h5, h6, a  {
			color: $white;	
		}
	}
	.video-text-mask {
		opacity: 0;
		@include transition();		
	}
	&:hover {
		.video-text-mask, .text-above {
			opacity: 1;
		}
	}
}

.video-text-mask {
	width: 100%;
	height: 50%;
	position: absolute;
	left: 0;
	bottom: 0;
	pointer-events: none;
	background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);	
}

.video-text-bellow {
	max-width: 800px;
	margin: 0 auto;
	.video-iframe {
		margin-bottom: 15px;
	}	
}

.three-items-slider {
	display: none;
	max-width: 1060px;
	padding:  0 35px;
	margin: 0 auto;
	&.slick-initialized {
		display: block;
	}
	.slider-title {
		max-width: 300px;	
		margin-bottom: 5px;
	}
}

.three-items-slider-item {
	margin: 0 5px;
}

.slider-title {
	margin:0 auto;
}

.one-item-slider {
	max-width: 400px;
	display: none;
	margin: 0 auto;
	&.slick-initialized {
		display: block;
	}
}

.one-item-slider-item {
	margin: 0 auto;
	img {
		width: 400px;
		margin: 0 auto 15px;	
		background-color: $c-lightgray;
	}
	a:hover {
		text-decoration: none;
	}
}

.slider-box-hover-wrap {
	max-width: 300px;
	height: 225px;
	overflow: hidden;	
	margin: 0 auto;
}

.slider-box-hover {
	width: 300px;
	height: 225px;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transform: scale(1,1);
    @include transition(0.4s);
	cursor: pointer;
	margin:0 auto 15px;
// 	background-color: $c-lightgray;
	&:hover {
		transform: scale(1.2,1.2);
		.slider-box-text {
			opacity: 1;		
		}	
		.bw-image {
			filter: none;
		}	
	}
}

.slider-box-text {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	opacity: 0;
	@include transition;
	transform: scale(0.8);
	position: absolute;
	text-align: center;
	width: 210px;
	height: 210px;
	color: $white;
	padding: 0 10px;
	border-radius: 6px;
	text-align: center;
	line-height: 1.5;
	background: rgba(0,0,0,0.8);
	.ce-h, h1, h2, h3, h4, h5, h6 {
		color: $white;	
		margin-bottom: 0;
	}
	p a {
		color: $white;	
	}
	.btn-arrow-white {
		margin: 10px auto;
	}
	&.bckg-red {
		background: rgba(202, 92, 87, 0.8);
	}
	&.bckg-brown {
		background: rgba(146, 73, 55, 0.8);
	}
	&.bckg-pink {
		background: rgba(241, 0, 140, 0.8)
	}
}

.video-slider {
	max-width: 950px;
	margin: 0 auto;
}
.video-iframe {
	max-width: 100%;
	margin: 0 auto 15px;
	display: table;
}

.video-slider-title {
	max-width: 460px;
	margin: 0 auto;
}

.bw-image {
	filter: grayscale(100%);
	&:hover {
		@include transition;
		filter: grayscale(0%);
	}
	.over & {
		@include transition;
		filter: grayscale(0%);
	}
}
