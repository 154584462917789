$font-readmore: Krub;
$font-headline: Abhaya Libre;
$color-bodytext: #1A1A1A;
$color-headline: #010101;
$greybackground: #F6F6F6;
$wide-width: 1180px;
$txt-maxwidth-desktop: 800px;
$txt-maxwidth-tablet: 660px;
$txt-margin-top: 70px;
$txt-margin-bottom: 70px;

@mixin video_holder {
  position:relative;
  margin: auto;
  //padding-bottom: 178%;
  height: 100%;
}

@mixin player {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  object-fit: cover;
  cursor: pointer;
}

@mixin txt-ce-margin {
  //margin-top: $txt-margin-top;
  //margin-bottom: $txt-margin-bottom;
}

@mixin readmore {
  color: #000000;
  font-family: $font-readmore;
  font-size: 14px;
  font-weight: bold;
}

@mixin bodytext {
  font-family: $f-krub;
  color: $color-bodytext;
}

@mixin headline-decor ($width: null, $top: null) {
  &:before {
    display: block;
    position: absolute;
    @if ($width){
      width: $width;
    }
    @if ($top){
      top: $top;
    }
    height: 3px;
    left: 0;
    content: "";
    background-color: $color-bodytext;
  }
}
@mixin font($family: null,
            $weight: null,
            $color: null,
            $size: null,
            $line: null,
            $space: null,
            $style: null){

  @if ($family){
    font-family: $family;
  }
  @if ($weight){
    font-weight: $weight;
  }
  @if ($color){
    color: $color;
  }
  @if ($size){
    font-size: $size;
  }
  @if ($line){
    line-height: $line;
  }
  @if ($space){
    letter-spacing: $space;
  }
  @if ($style){
    font-style: $style;
  }

}

@mixin new_leadtext {
  @include font($family: $f-krub,
                $color: $color-bodytext,
                $size: 16px,
                $line: 26px);
}

@mixin new_default {
  @include font($family: $f-krub,
                $color: $color-bodytext,
                $size: 14px,
                $line: 24px);
}

@mixin new_h1 ($family: $font-headline){
  position: relative;
  margin: 50px auto 15px auto;
  @include font($family: $family,
                $size: 70px,
                $weight: bold,
                $color: #000000,
                $space: -2px,
                $line: 74px);
  @include breakpoint(xxmedium down) {
    @include font($size: 60px,
                  $line: 65px);
  }
}

@mixin new_h2 ($family: $font-headline){
  position: relative;
  margin: 50px auto 15px auto;
  @include font($family: $family,
                $size: 48px,
                $weight: bold,
                $color: $color-headline,
                $line: 52px);
}

@mixin new_h3 ($family: $font-headline){
  position: relative;
  margin: 50px auto 10px auto;
  @include font($family: $family,
                $size: 28px,
                $weight: bold,
                $line: 32px);
}

@mixin mobile_mapping {
  .mappedimage-ce-singleitem {
    position: relative;
    &-image {
      width: 100%;
      margin: 0 0 15px;
    }
    &-popup {
      display: none;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;
      position:absolute;
      min-height:90px;
      //background:$white;
      pointer-events: none;
      border-radius: 0;
      color: $white;
      //background: radial-gradient(circle,rgba(0,0,0,.3) 0,rgba(0,0,0,.1) 40%,transparent 70%);
      text-align: center;
      @media only screen and (max-width: map-get($breakpoints, xxmedium)){
        background: none;
      }
      @include breakpoint(xxmedium down) {
        width: 100px;
        height: 100px;
      }
      .product-list-name {
        font-size: 14px;
        margin-bottom: 10px;
        max-width: inherit;
        font-weight: 600;
        color: $white;
      }
      .product-single-name {
        margin-bottom: 5px;
      }
      .product-single-price {
        .icon {
          display: none;
        }
        .price {
          color: $black;
          font-size: 18px;
        }
        .old-price {
          color: $white;
          font-size: 11px;
          margin-left: 0;
          margin-bottom: 3px;
        }

      }
      @media only screen and (max-width: map-get($breakpoints, xxmedium)) {
        .product-single-info-popup {
          padding: 15px;
          background-color: rgba(255,255,255,.8);
          background: rgba(255,255,255,.8);
          border-radius: 4px;
          .product-single-name {
            @include font($size: 14px,
            $family: $f-krub,
            $line: 16px,
            $color: #000000);
            //margin-bottom: 6px;
          }
          .price {
            //color: #000000;
            //font-size: 18px;
            @include font($size: 16px,
            $family: $f-krub,
            $weight: bold,
            $line: 18px,
            $color: #000000);
          }
          .old-price {
            //color: #000000;
            //font-size: 11px;
            @include font($size: 10px,
            $family: $f-krub,
            $line: 13px,
            $color: #000000);
            margin-left: 0;
          }
        }
      }

      @media only screen and (max-width: map-get($breakpoints, xxmedium) - 1) {
        display: flex;
        opacity: 0;
        transition: opacity 1s;
      }

      @include breakpoint(small down) {
        .product-single-info-popup {
          padding: 10px;
          .product-single-name {
            display: block;
            @include font($size: 10px, $line: 10px);
            //margin-bottom: 6px;
          }
          .price {
            display: block;
            @include font($size: 10px, $line: 10px);
          }
          .old-price {
            display: block;
            @include font($size: 8px, $line: 8px);
            margin-bottom: 2px;
          }
        }
      }
    }
    @media only screen and (max-width: map-get($breakpoints, xxmedium) - 1) {
      display: flex;
      opacity: 0;
      transition: opacity 1s;
    }

  }
  @include breakpoint(large){
    .ce-mapp-black-tag {
      .product-single-info-popup {
        .product-single-name,
        .old-price,
        .price {
          color: $color-bodytext;
        }
      }
    }
  }

}

@mixin col_img_txt {
  @include font($family: $f-krub,
                $color: $color-bodytext,
                $size: 14px,
                $line: 24px,
                $style: italic);
}

@mixin style_name {
  @include font($size: 18px, $line: 18px);
  @include breakpoint(xmedium) {
    @include font($size: 16px, $line: 16px);
  }
  @include breakpoint(large) {
    @include font($size: 18px, $line: 1.2);
  }
}

@mixin style_price {
  @include font($family: $f-krub,
                $color: $color-bodytext,
                $size: 18px,
                $line: 18px);
  @include breakpoint(xmedium) {
    @include font($size: 16px, $line: 16px);
  }
  @include breakpoint(large) {
    @include font($size: 22px, $line: 18px);
  }
}

@mixin style_old_price {
  @include font($family: $f-krub,
                $color: #4A4A4A,
                $size: 14px,
                $line: 16px);
  //@include breakpoint(large) {
  //  @include font($size: 18px, $line: 14px);
  //}

}

@mixin generic_message ($color: null){
  position: relative;
  padding-left: 15px;
  @if ($color){
    color: $color;
  }
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  //margin-bottom: 10px;
  &:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 6px;
    width: 6px;
    @if ($color){
      background-color: $color;
    }
    border-radius: 50%;
    display: inline-block;
    content: '';
  }
}

.wrapper_outer {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  overflow:hidden;
  @media only screen and (min-width: map-get($breakpoints, xxmedium)) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  //.page-main-container-inner &:nth-of-type(1)
  //{
  //  // breakpoint(xxsmall down) ---> max 480
  //  @include breakpoint(xxsmall down) {
  //    padding: 0 30px;
  //    margin-top: 30px;
  //    margin-bottom: 30px;
  //  }
  //}
}

.wrapper_bottom_small {
  padding-bottom: 25px;
  @media only screen and (max-width: map-get($breakpoints, xmedium)) {
    padding-bottom: 18px;
  }
}

.wrapper_bottom_default {
  padding-bottom: 80px;
  @media only screen and (max-width: map-get($breakpoints, xmedium)) {
    padding-bottom: 40px;
  }
}

.wrapper_top_first {
  margin-top: 80px;
  @include breakpoint(xxsmall down) {
    margin-top: 80px;
  }
}

.text_wrapper_outer {
  margin-top: 80px;
  margin-bottom: 80px;
  // breakpoint(xxsmall down) ---> max 480
  @include breakpoint(xxsmall down) {
    padding: 0 30px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.ce_single_product {

  &_wrapper {
    grid-gap: 20px 12px;
    @include from($desktop) {
      grid-gap: 16px;
    }
    @include from($wide-screen) {
      grid-gap: 30px;
    }
  }

  &_price_holder {
    margin-top: 10px !important;
    padding-left: 70px;
    a {
      text-decoration: none;
    }
    @include breakpoint(xmedium) {
      padding-left: 55px;
    }
  }

  &_price {
    display: inline-block;
    margin-top: 6px;
    @include style_price;
  }

  &_old_price {
    display: inline-block;
    text-decoration: line-through;
    @include style_old_price;
  }

  &_name {
    display: inline-block;
    margin: 0 15px;
    @include style_name;
  }

  &_wish {
    display: inline-block;
  }

  &_image {
    transition: transform .2s ease-in-out;
    @include breakpoint(small down) {
      width: 100%;
      height: auto;
    }
  }

}

.bullet-wrapper {
  line-height: 0;
  margin-bottom: 10px;
}

.bullet-gray {
  display: inline-block;
  @include generic_message (
      $color: #A2A2A2
  );
  p {
    margin: 0;
  }
}

.bullet-black {
  display: inline-block;
  @include generic_message (
          $color: #000000
  );
  p {
    margin: 0;
  }
}

.bullet-red {
  display: inline-block;
  @include generic_message (
          $color: #CF1515
  );
  p {
    margin: 0;
  }
}

.bullet-pink {
  display: inline-block;
  @include generic_message (
          $color: #FF4DAB
  );
  p {
    margin: 0;
  }
}

.cart-detailview .information-box {
  .bullet-black {
    display: block;
    margin-bottom: 10px;
    line-height: 15px;
  }
  .bullet-red {
    display: block;
    margin-bottom: 10px;
    line-height: 15px;
  }
  .bullet-grey {
    display: block;
    margin-bottom: 10px;
    line-height: 15px;
  }
  .bullet-pink {
    display: block;
    margin-bottom: 10px;
    line-height: 15px;
  }
}

.cart-mobile-data-bullet {
  margin-left: -10px;
  margin-right: -10px;
  background-color: #efefef;
  padding: 25px 10px 15px 10px;
  //.bullet-black {
  //  margin-bottom: 10px;
  //}
  //.bullet-red {
  //  margin-bottom: 10px;
  //}
  //.bullet-grey {
  //  margin-bottom: 10px;
  //}
  //.bullet-pink {
  //  margin-bottom: 10px;
  //}
  @include breakpoint(768) {
    display: none;
  }
}

.cart-preview {
  .bullet-black {
    float: right;
  }
  .bullet-red {
    float: right;
  }
  .bullet-grey {
    float: right;
  }
  .bullet-pink {
    float: right;
  }
}

.cart-info-box {
  line-height: 1;
}

.message-red-border {
  border: 1px solid #CF1515;
  padding: 5px 7px;
  text-align: right;
  @include font($weight: 600,
                $color: #000000,
                $size: 14px,
                $line: 18px);
}

@include breakpoint(medium down) {
  //div {
  //  border: 1px solid red;
  //}
  .bullet-wrapper {
    text-align: center;
  }
  .bullet-black {
    display: inline-block;
  }
  .bullet-red {
    display: inline-block;
  }
  .bullet-grey {
    display: inline-block;
  }
  .bullet-pink {
    display: inline-block;
  }
}