.search-autocomplete-box {
  display: none;
  position: absolute;
  z-index: 1000;
  top: 100%;
  right: 0;
  background-color: $white;
  padding:0 35px;
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
  @media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
	  display: none !important;
  }

  @include from($desktop) {
    padding: 0 10px;
  }
}
.search-results-wrap {
	@include breakpoint(xmedium) {
		margin-bottom: 150px;
	}
}
form#tx_indexedsearch {
	margin-bottom: 10px;
	@include breakpoint(xmedium) {
		margin-bottom: 20px;
	}
}
.no-products-in-listview, .no-products-in-searchview, .tx-indexedsearch-noresults { font-size:14px; color:$c-gray; }

.tx-indexedsearch {
	&-info {display: none;}
	&-res {
		padding-bottom: 10px;
		margin-bottom: 20px;
		border-bottom: 1px solid $c-border;
	}
	&-title {
		font-weight: 500;
	}	
	&-percent {
		float: right;
	}
	&-browsebox {
		margin-bottom: 20px;
	}
}

ul.tx-indexedsearch-browsebox { 
	display: table; margin: 20px auto; padding: 0; list-style: none; 
	li { float: left; width: 30px; height: 30px; border-radius: 20px; background: $c-lightgray; margin: 0 5px; color: $c-gray; text-align: center; list-style:none; }
	li a { color: $c-gray; text-decoration: none; border-radius: 20px; display: block; line-height: 30px; }
	li.tx-indexedsearch-browselist-currentPage a, li:hover, li:hover a { background: $c-gray; color: $white }
	strong {font-weight: 400;}
}	

.search-buttons-wrap {
	@include breakpoint(xmedium) {
		@include inline();
	}
	.btn-gray {
		margin-bottom: 10px;
	}
	@media only screen and (max-width: map-get($breakpoints, xmedium)-1) {
		border-bottom: 1px solid $c-border;
		padding-bottom: 10px;
	}
}