.ce_video {
  &__wrapper {

  }
  &__container {

  }
  &__inner {
    max-width: 375px;
    margin: auto;
  }
  &__holder {
    position:relative;
    max-width: 375px;
    text-align: center;
    margin: auto;
  }
  &__player {
    object-fit: contain;
    cursor: pointer;
    &_desktop {
      display: inline-block;
    }
  }

}


/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_video {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: 980px;
    }
    &__holder {
      max-width: 980px;
      //height: 550px;
    }

  }
}


/// @include breakpoint(large) ---> min 1920
@include breakpoint(wide) {
  .ce_video {
    &__wrapper {

    }

    &__container {

    }

    &__inner {
      max-width: 1920px;
    }

    &__holder {
      max-width: 1920px;
      //height: 1080px;
    }
  }
}


