.counter-teaser {
	height: auto;
	width:100%;
	border-top: 2px solid #131414;
	padding: 33px 0 40px 0;
	z-index: 9;
	background: #ffffff;
	&-inner {
		margin: 0 auto;
	}
	@media only screen and (min-width: 1141px) {
		width:900px;
	}
	&-three-col {
		display: flex;
		flex-direction: row;
		margin:0 auto;
		width:100%;
		@media only screen and (min-width: 1141px) {
			width:870px;
		}
	}
	&-c-3-1-inner {
		flex: 1.5;
		color: #000000;
		font-family: "Open Sans";
		font-size: 16px;
		line-height: 22px;
		display: flex;
		align-items: center;
		p {
			max-width: 70%;
			margin: 0 auto 15px auto;
		}
	}
	&-field1, &-field2, &-field3, &-field4 {
		position: relative;
		img {
			position: absolute;
			top: 3px;
			left: -25px;
			width: 14px;
			height: 12px;
		}
	}
	&-field2 {
		margin-top: 35px;
	}
	&-field4 {
		margin-top: 20px;
	}
	&-c-3-2-inner {
		flex: 1;
		margin-right: 35px;
	}
	&-c-3-3-inner {
		flex: 1;
	}
	&-field1-title, &-field2-title, &-field3-title, &-field4-title {
		color: #000000;
		font-family: "Open Sans";
		font-size: 14px;
		font-weight: bold;
		line-height: 19px;
	}
	&-field1-amount, &-field2-amount {
		color: #F43493;
		font-family: "Open Sans";
		font-size: 26px;
		font-weight: bold;
		letter-spacing: 2px;
		line-height: 36px;
	}
	&-field1-measure, &-field2-measure {
		color: #000000;
		font-family: "Open Sans";
		font-size: 11px;
		line-height: 15px;
		//margin-left: 128px;
		//display: inline-block;
	}
	&-rectangle {
		width: 0px;
    height: 4px;
    background-color: #9b9b9b;
    margin: 0 3px 2px 10px;
		display: inline-block;
		-webkit-transition: width 2s; /* Safari */
		transition: width 2s;
	}
	&-line {
		height: 4px;
		width: 0;
		background-color: #F43493;
		display: inline-block;
		margin: 0 3px 1px 10px;
		-webkit-transition: width 2s; /* Safari */
		transition: width 2s;
	}
	&-loading {
		width: 100px;
	}
	.loadingRectangle {
		width: 5px;
	}
	&-more-than {
		color: #F43493;
		font-family: "Open Sans";
		font-size: 11px;
		line-height: 15px;
	}
	p {
		margin: 0;
	}
	.counter-teaser-field3-year1,
	.counter-teaser-field3-year2,
	.counter-teaser-field4-year1,
	.counter-teaser-field4-year2 {
		color: #000000;
		font-family: "Open Sans";
		font-size: 11px;
		line-height: 15px;
	}
	&-counteritem, &-counteritem-2 {
		position:relative;
		width: 120px;
		display: inline-block;
		height: 24px;
		//animation: movingBottomToTop ease 1s infinite;
		//-webkit-animation: movingBottomToTop ease 1s infinite;
		//position: absolute;
	}
}

.counter-wrapper {
	position: relative;
	//overflow: hidden;
}

//.counter-teaser-credit, .counter-teaser-credit2 {
//  position:fixed;
//  bottom:20px;
//  left:60px;
//  font-size:16px;
//}

.counter-value, .counter-value2 {
  position:absolute;
  transform-origin:0 100%;
  transform:skew(0,0);
  overflow:hidden;
  padding-right:30px;
  height:36px;
}
.counter-value-mask, .counter-value-mask2 {
  position:absolute;
  transform-origin:0 100%;
  transform:skew(0);
  overflow:hidden;
  line-height:1em;
}

@media only screen and (max-width: 768px) {
	.counter-teaser {
		&-three-col {
			flex-direction: column;
		}
		&-inner {
			max-width: none;
			margin: 0 28px;
		}
		&-c-3-2-inner, &-c-3-3-inner {
			margin: 25px;
		}
		&-c-3-1-inner p {
			max-width: none;
			margin: 0 auto;
		}
		&-field4 {
			margin-top: 35px;
		}
		&-c-3-3-inner {
			margin-top: 10px;
		}
	}
}


//@keyframes movingBottomToTop {
//  0% {
//    bottom: -36px;
//	}
//	50% {
//    bottom: 5px;
//	}
//  100% {
//    bottom: 36px;
//  }
//}