.news-date:before {
	// Positioning

	// Display & Box Model
	border-top: 1px solid $c-gray;
	height: 1px;
	width: 90px;
	@include inline;
	margin-right: 10px;

	// Color

	// Text

	// Other
	content: '';
}
.news-list {
	//@extend .inner-row;

	// Positioning

	// Display & Box Model
	margin-top: 40px;
	margin-bottom: 40px;

	// Color

	// Text

	// Other
	&-wrap {
		@include breakpoint(xmedium) {
			margin-left: 250px;
		}
	}
	&-h {
		padding-left: 8px;
		@include breakpoint(xmedium) {
			padding-left: 0;
		}
	}
	&-single {
		// Positioning

		// Display & Box Model
		padding: 0 10px;
		justify-content: flex-start;
		margin-bottom: 70px;

		// Color

		// Text

		// Other
		@include breakpoint(xmedium) {
			margin-bottom: 50px;
			padding: 0;
		}
		.news-list-text-wrap {
			@include breakpoint(xxmedium) {
				margin-left: 30px;
			}
		}
		&__no-img {
			padding: 0 10px;
			@include breakpoint(xmedium) {
				margin-bottom: 50px;
			}
			@include breakpoint(xxmedium) {
				@include xy-cell(6);
				display: inline-block;
				padding: 0 70px 0 0;
				margin-left: 0;
			}
		}
	}
	&-image {
		@include xy-cell(12, $gutters: 0);
		display: inline-block;
		margin-bottom: 15px;
		overflow: hidden;
		max-width: 300px;
		img {
			transition: transform 0.3s ease;
		}
		&:hover img {
			transform: scale(1.2);			
		}
		//@include breakpoint(xxmedium) {
		//	@include xy-cell(5);
		//
		//}
	}
	&-text {
		// Positioning

		// Display & Box Model
		margin: 20px 0 35px 0;

		// Color

		// Text

		// Other
		&-wrap {
			// Positioning

			// Display & Box Model
			display: inline-block;
			margin: 0;
			vertical-align: top;

			// Color

			// Text

			// Other
			@include breakpoint(xxmedium) {
				@include xy-cell(6, $gutters: 20);
			}
		}
	}
}
.news-teaser {
	@extend .inner-row;

	// Positioning

	// Display & Box Model
	justify-content: center;
	border-top: 3px solid $black;
	border-bottom: 3px solid $black;
	padding: 20px 0;
	margin-top: 60px;
	margin-bottom: 60px;

	// Color

	// Text

	// Other
	@include breakpoint(xmedium) {
		margin-left: 250px;
		padding: 40px 0;
	}
	@include breakpoint(xxlarge) {
		padding: 60px 0
	}
	&-wrap {
		@extend .inner-row;
		justify-content: center;
	}
	&-image-top {
		padding: 40px 10px;
		border-top: 3px solid $black;
		border-bottom: 3px solid $black;
		justify-content: center;
		margin-top: 40px;
		margin-bottom: 40px;
		&-img {
			position: relative;
			a {
				display: block;
				overflow: hidden;
			}	
			img {
				transition: transform 0.3s ease;
			}
			&:hover img {
				transform: scale(1.2);			
			}
		}
		@include breakpoint(xmedium) {
			margin-left: 250px;
			padding: 40px 0 20px 0;
		}
		&-single {
			@include xy-cell(12);
			@include breakpoint(xmedium) {
				@include xy-cell(6);

			}
			@include breakpoint(large) {
				@include xy-cell(3, $gutters: 30px);
			}
			@include breakpoint(1700px) {
				max-width: 270px;
			}
		}
	}
	&-label {
		// Positioning
		position: absolute;
		bottom: 10px;
		left: -15px;

		// Display & Box Model
		background: $black;
		padding: 2px 10px;
		border-radius: 3px;

		// Color
		color: $white;

		// Text
		text-transform: uppercase;
		font-size: 9px;
		line-height: 13px;

		// Other

	}
	&-title {
		// Positioning

		// Display & Box Model
		margin: 5px 0;

		// Color

		// Text
		font-family: $f-special;
		font-size: 20px;
		line-height: 22px;
		text-transform: uppercase;
		font-weight: bold;

		// Other
	}
	&-date {
		// Positioning

		// Display & Box Model
		margin-top: 5px;
		display: block;

		// Color

		// Text
		font-size: 13px;
		line-height: 18px;

		// Other
	}
	&-text {
		// Positioning

		// Display & Box Model
		margin: 5px 0;

		// Color

		// Text
		font-size: 13px;
		line-height: 18px;

		// Other
	}
	&-image-left {
		@include xy-cell(12);

		// Positioning

		// Display & Box Model
		margin-bottom: 20px;

		// Color

		// Text

		// Other
		@include breakpoint(xmedium) {
			@include xy-cell(6);
		}
		@include breakpoint(large) {
			max-width: none;
			margin-right: 0;
			.news-teaser-text-wrap {
				padding-left: 40px;
			}
		}
		@include breakpoint(xlarge) {
			.news-teaser-label {
				left: auto;
				right: -15px;
			}
		}
		@include breakpoint(xxxlarge) {
			@include xy-cell(6, $gutters: 40px,  $gutter-position: left);
			width: calc(50% - 1.875rem);
		}


	}
	&-image-right {
		@include xy-cell(12);

		// Positioning

		// Display & Box Model
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;

		// Color

		// Text

		// Other
		.news-teaser-image-right-img {
			order: 1;
		}
		.news-teaser-text-wrap {
			order: 2;
		}
		@include breakpoint(xmedium) {
			@include xy-cell(6);
		}
		@include breakpoint(large) {
			display: inline-block;
			margin-left: 0;
			max-width: none;
			text-align: right;
			.news-teaser-text-wrap {
				padding-right: 40px;
			}
		}
		@include breakpoint(xxxlarge) {
			@include xy-cell(6, $gutters: 40px, $gutter-position: right);
			width: calc(50% - 1.875rem);
		}
	}
	&-image-left-img,
	&-image-right-img{
		position: relative;
		@include inline(bottom);
		a {
			display: block;
			overflow: hidden;
		}	
		img {
			transition: transform 0.3s ease;
		}
		&:hover img {
			transform: scale(1.2);			
		}
	}
	&-text-wrap {
		// Positioning

		// Display & Box Model
		display: inline-block;
		padding: 0;

		// Color

		// Text

		// Other
		@include breakpoint(large){
			width: 50%;
		}
	}
	&-side-date {
		// Positioning

		// Display & Box Model
		display: block;
		margin-bottom: 20px;

		// Color

		// Text

		// Other
	}
}
ul.f3-widget-paginator {
	display: table;
	width: 100%;
	text-align: center;
	margin: 20px auto;
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		vertical-align: middle;
		width: 30px;
		height: 30px;
		border-radius: 20px;
		background: $c-lightgray;
		margin: 0 5px;
		color: $c-gray;
	}
	li a {
		color: $c-gray;
		text-decoration: none;
		border-radius: 20px;
		display: block;
		line-height: 30px;

	}
	li.next a {
			font-size: 0;
			&:after {
				content: '>';
				font-size: 14px;
				vertical-align: top;
			}
		}
	li.previous a {
			font-size: 0;
			&:after {
				content: '<';
				font-size: 14px;
				vertical-align: top;
			}
		}
	li.current {
		padding-top: 2px;
	}
	li.current,
	li:hover,
	li:hover a {
		background: $c-gray;
		color: $white
	}
}
.news-single  {
	&-detail {
		padding: 40px 20px 0;
		@include breakpoint(xmedium) {
			margin-left: 230px;
		}
		@include breakpoint(xxlarge) {
			margin-right: 250px;
		}
		img {
			width:100%;
			max-width: 712px;
			height: auto;
		}
	}
	&-h {
		text-transform: uppercase;
	}
	.news-detail-back {
		margin-top: 70px;
	}
}

.news-detail-share {
	margin-top: 25px;
}
