$animateClass: "start-animation";
$animation: all 1s ease-in-out;
$animationMobile: all 1s ease-in-out;

// position x movement
$rail: 20px;
// Zoom value for zoom in images
$zoomValue: 1.03;
@mixin animateElement($classToAnimate: null, $x: null, $y: null, $zoom: null){

  @if ($classToAnimate){
    .#{$classToAnimate}.#{$animateClass} {

      will-change: transform;
      opacity: 1 !important;

      @if ($y){
        transform: translateY($y);
      }

      @if ($x){
        transform: translateX($x);
      }

      @if ($zoom){
        transform: scale($zoom);
      }
    }
  }

}


/***** two-images1 *****/
@include animateElement($classToAnimate: ce_two_images1__img_large_holder, $x: 20px);
@include animateElement($classToAnimate: ce_two_images1__img_small_holder, $y: 35px);

/***** two-images2 *****/
@include animateElement($classToAnimate: ce_two_images2__img_small_holder, $x: 20px);
@include animateElement($classToAnimate: ce_two_images2__img_large_holder, $y: 20px);

/***** isolate *****/
@include animateElement($classToAnimate: ce_isolate__img);
@include animateElement($classToAnimate: ce_isolate__headline, $x: -$rail);
@include animateElement($classToAnimate: ce_isolate__content, $x: $rail);
@include animateElement($classToAnimate: ce_isolate__readmore, $x: -$rail);

/***** isolate flip *****/
@include animateElement($classToAnimate: ce_isolate_flip__img);
@include animateElement($classToAnimate: ce_isolate_flip__headline, $x: -$rail);
@include animateElement($classToAnimate: ce_isolate_flip__content, $x: $rail);
@include animateElement($classToAnimate: ce_isolate_flip__readmore, $x: -$rail);

/***** teaser-discount *****/
@include animateElement($classToAnimate: ce_teaser_discount__discount_number, $y: -8px);

/***** teaser-discount-cities *****/
.ce_text_discount_cities__info_border.#{$animateClass} {
  width: 132px;
}

/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {

  /***** two-images1 *****/
  @include animateElement($classToAnimate: ce_two_images1__img_small_holder, $y: 80px);
  @include animateElement($classToAnimate: ce_two_images1__img_large_holder, $x: 30px);

  /***** two-images2 *****/
  @include animateElement($classToAnimate: ce_two_images2__img_large_holder, $y: 30px);
  @include animateElement($classToAnimate: ce_two_images2__img_small_holder, $x: 30px);

  /***** isolate *****/
  @include animateElement($classToAnimate: ce_isolate__headline, $x: $rail);
  @include animateElement($classToAnimate: ce_isolate__content, $x: $rail);
  @include animateElement($classToAnimate: ce_isolate__readmore, $x: -$rail);

  /***** isolate-flip *****/
  @include animateElement($classToAnimate: ce_isolate_flip__headline, $x: $rail);
  @include animateElement($classToAnimate: ce_isolate_flip__content, $x: $rail);
  @include animateElement($classToAnimate: ce_isolate_flip__readmore, $x: -$rail);

}


@include breakpoint(large) {

  /***** two-images1 *****/
  @include animateElement($classToAnimate: ce_two_images1__img_small_holder, $y: 50px);

  /***** isolate *****/
  @include animateElement($classToAnimate: ce_isolate__headline, $x: $rail);
  @include animateElement($classToAnimate: ce_isolate__content, $x: $rail);
}

@include breakpoint(xxmedium down) {

  /***** mapped image *****/
  @include animateElement($classToAnimate: mappedimage-ce-singleitem-popup);

}