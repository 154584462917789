.ce_text_discount_cities {

  &__wrapper {
    @include txt-ce-margin;
  }
  &__container {

  }
  &__inner {
    max-width: 375px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
            'headline'
            'info'
            'cities';
    grid-gap: 20px;
  }
  &__headline {
    position: relative;
    width: 334px;
    padding-top: 30px;
    margin-bottom: 0;
    grid-area: headline;
    @include font($family: $font-headline,
                  $weight: 700,
                  $color: #000000,
                  $size: 48px,
                  $line: 46px);
  }
  &__content {
    @include bodytext;
    @include font($size: 16px, $line: 26px);
    margin-bottom: 30px;
  }
  &__readmore {
    width: 88px;
    @include readmore;
  }
  &__info {
    &_holder {
      grid-area: info;
      position: relative;
      //padding-top: 30px;
    }
    &_border {
      position: absolute;
      top: 0;
      left: 0;
      height: 4px;
      width: 117px;
      background-color: #000000;
      transition: all 0.7s ease-in-out;
    }
  }
  &__cities {
    position: relative;
    @include bodytext;
    @include font($size: 12px, $line: 20px);
    padding-left: 30px;
    &_border {
      position: absolute;
      top: 37px;
      bottom: 2px;
      left: 0;
      width: 1px;
      background-color: #D8D8D8;
    }
    &_map {
      position: absolute;
      top: -3px;
      left: -6px;
      width: 15px;
      height: 20px;
      background: url(/assets/img/project/ic-map.svg) no-repeat;
    }
    &_holder {
      grid-area: cities;
      @media only screen and (max-width: map-get($breakpoints, xxmedium)) {
        padding-left: 10px;
      }
    }
  }

}

// @include breakpoint(xxmedium) --> max 360
@include breakpoint(small down) {
  .ce_text_discount_cities {

    &__inner {
      max-width: unset;
      grid-template-columns: 1fr;
    }
    &__headline {
      width: 100%;
    }
    &__cities {
      &_holder {
        padding-left: 6px;
      }
    }

  }
}

/// @include breakpoint(xmedium) ---> min 768
@include breakpoint(xmedium) {
  .ce_text_discount_cities {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: 500px;
    }
    &__headline {
      margin-bottom: 0;
    }
    &__content {

    }
    &__readmore {

    }
    &__cities {

    }
    &__info {
      &_holder {
        padding-top: 0px;
      }
    }
  }
}

/// @include breakpoint(large) ---> min 1200
@include breakpoint(large) {
  .ce_text_discount_cities {

    &__wrapper {

    }
    &__container {

    }
    &__inner {
      max-width: $txt-maxwidth-desktop;
      grid-template-columns: 430px 1fr;
      grid-template-areas:
              'headline headline'
              'info cities';
      grid-gap: 10px 30px;
    }
    &__headline {
      width: unset;
    }
    &__content {

    }
    &__readmore {

    }
    &__cities {

    }

  }
}


