.ce_list_discount_cities {

  &__wrapper {
    margin-top: 20px;
    margin-bottom: 80px;
  }
  &__container {

  }
  &__inner {
    margin: auto;
    padding: 0 30px;
  }

  &__info {
    &_border {
      position: absolute;
      top: 0;
      left: 0;
      height: 4px;
      width: 117px;
      background-color: #000000;
      transition: all 0.7s ease-in-out;
    }
  }
  &__cities {
    position: relative;
    @include bodytext;
    @include font($size: 12px, $line: 20px);
    padding-left: 30px;
    &_border {
      position: absolute;
      top: 37px;
      bottom: 2px;
      left: 0;
      width: 1px;
      background-color: #D8D8D8;
    }
    &_map {
      position: absolute;
      top: -3px;
      left: -8px;
      width: 18px;
      height: 25px;
      background: url(/assets/img/project/ic-map-new.svg) no-repeat;
    }
  }

}

// @include breakpoint(xxmedium) --> max 360
@include breakpoint(small down) {
  .ce_list_discount_cities {

    &__inner {
      max-width: unset;
    }

  }
}
