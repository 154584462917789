$f-special: "Playfair Display";
.blog-list {
	@include indent-content;
	&-item {
		width: 520px;
		max-width: 100%;
		margin-bottom: 40px;
		padding: 0 10px;
		@include breakpoint(xxmedium) {
			margin-bottom: $space-large;	
		}
		&-smaller {
			@include breakpoint(xxmedium) {
				margin-bottom: 40px;				
			}	
		}
		&-narrow {
			width: 1060px;
			max-width: 100%;
			margin-bottom: 40px;
			@include breakpoint(xxmedium) {
				margin-bottom: $space-large;	
			}
		}
		&-video {
			&-element {
				height: 800px;
			}
			@media only screen and (max-width: map-get($breakpoints, xxmedium) - 1) {
				&-iframe, &-element {
					height: 400px;
				}
			}
			@media only screen and (max-width: map-get($breakpoints, medium) - 1 ) {
				&-iframe, &-element {
					height: 270px;
				}
			}	
		}	
		&-video-full {
			width: 100%;
			max-width: 100%;
			margin-bottom: 40px;
			@include breakpoint(960px) {
				width: calc(100% - 20px);
				max-width: 100%;
			}
			@include breakpoint(xxmedium) {
				margin-bottom: $space-large;	
			}
			.video-element {
				height: 800px;
				object-fit: contain;
			}
			@media only screen and (max-width: map-get($breakpoints, xxxlarge) - 1) {
				.video-iframe, .video-element {
					height: 600px;
				}
			}
			@media only screen and (max-width: map-get($breakpoints, xxmedium) - 1) {
				.video-iframe, .video-element {
					height: 400px;
				}
			}
			@media only screen and (max-width: map-get($breakpoints, medium) - 1 ) {
				.video-iframe, .video-element {
					height: 270px;
				}
			}	
			.blog-list-headline {
				text-transform: none;
				&:after {
					@include horizontal-center-content;
				}
			}
			.blog-list-content {
				text-align: center;
				max-width: 850px;
				margin: 0 auto;
			}
		}	
	}
	&-sizer {
		@extend .blog-list-item;
	}
	&-category {
		display: inline-block;
		text-decoration: none;
		font-family: $f-special;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 700;
		border:1px solid $black;
		padding: 7px 20px;
		margin-left: 0;
		position: absolute;
		bottom: 10px;
		.blog-view-0 & {
			position: relative;
			margin-left: -9px;
		}
		@include breakpoint(xlarge) {
			.blog-view-0 & {
				position: relative;
				margin-left: -29px;
			}
		}
		@media only screen and (max-width: 960px) {
			.blog-view-0 & {
				position: relative;
				margin-left: -9px;
			}
		}
		&:before, &:after {
			position: absolute;
			top: -1px;
			content: '';
			border: 1px solid $black;
			width: 10px;
			height: 100%;
		}
		&:before {
			left: -12px;
		}
		&:after {
			right: -12px;
		}
		.text-white &, .dark-blog-listimage & { 
			border-color: $white;
			color: $white;
			margin-left: -1px;
			&:before, &:after {
				border-color: $white;	
			}
		}
	}
	&-date {
		@include breakpoint(xmedium) {
			float: right;
			margin-top: 40px;
			.blog-view-0 & {
				margin-top: 30px;
			}
		}
		@media only screen and (max-width: map-get($breakpoints, xmedium) - 1) {
			.blog-view-0 & {
				display: block;
				margin-top: 5px;
			}
		}
	}
	&-headline {
		text-transform: uppercase;
		font-family: $f-special;
		font-weight: 400;
		position: relative;
		padding-bottom: 15px;
		margin:15px 0;
		@include line-after(170px);
		.blog-view-0 & { 
			display: block;
		}
		@include breakpoint(xmedium) {
			display:inline-block;
			max-width: calc(100% - 90px);		
			.blog-view-0 &, .blog-view-4 &  { 
				max-width: 100%;
			}
			font-size: 36px;
			margin: 20px 0 0;
		}
	}
	&-link {
		@extend .btn-no-border;
		@include breakpoint(xmedium) {
			.blog-view-0 & {
				float: right; 
			}
		}
		.blog-view-3 & {
			font-size: 11px;
			line-height: 14px;
		}
	}
	&-content {
		.blog-view-0 & {
			width: 100%;
			min-height: 120px;
			position: absolute;
			bottom: 10%;
			left: 0;
			padding: 0 20px 0 50px;
			@include breakpoint(xlarge) {
				padding: 0 30px 0 50px;
			}
		}
		position: relative;
		z-index: 1;
		.blog-view-3 & {
			text-align: center;
		}
		.blog-view-4 & {
			@include breakpoint(851px) {
				position: absolute;
				height: 100%;
				width: calc(100% - 20px);
				top: 0;
				left: 0;
				overflow: hidden;
				pointer-events: none;
			}
		}
		&.text-white, &.dark-blog-listimage {
			transition: color 0.3s ease;
			color: $white;
			a, .blog-list-headline, .blog-list-category {
				color: $white;
				border-color: $white;
			}
			.blog-list-headline:after {
				background: $white;
			}
			.blog-list-category {
				border-left: 0px;
				&:before, &:after {
					border-color: white;
				}
			}
		}
	}
	&-text {
		@include breakpoint(xmedium) {
			margin: 20px 0;
			.blog-view-0 & {
				max-width: calc(100% - 160px);
			}
		}
		@media only screen and (max-width: map-get($breakpoints, medium) - 1) {
			.blog-view-0 & {
				display: none;
			}
		}
		.blog-view-3 & {
			font-weight: 600;
			margin: 0;
		}
		.blog-view-4 & {
			color: $black;
		}
		.text-white &, .dark-blog-listimage & {
			color: $white;
		}
	}
	&-image-wrap {
		position: relative;
		.blog-view-3 & {
			z-index: 2;
		}
	}
	&-image-link {
		display: block;
		overflow: hidden;
		.blog-view-3 & {
			overflow: visible;
		}
	}
	&-image {
		@extend .img-responsive;
		.blog-view-3 & {
			transition: transform 0.3s ease;			
			&:hover {
				transform: scale(1.2);
			}
		}
		&-slider {
			&.slick-dotted.slick-slider {
				margin-bottom: 0;				
			}
			.blog-view-0 & {
				.slick-dots {
					top: 10px;
					bottom: auto;
				}
			}
		}
	}
}
.blog-view-0, .blog-view-4 {
	overflow: hidden;
	position: relative;
	cursor: pointer;
}
.blog-list-content-inner {
	@include breakpoint(xmedium) {
		z-index: 9;
		position: relative;
	}
	.blog-view-4 & {
		pointer-events: auto;
		@include breakpoint(851px) {
			width: 90%;
			max-width: 460px;
			padding-left: 40px;
			@include vertical-center-content;
		}
		@include breakpoint(xxmedium) {
			padding-left: 70px;
		}
	}
}
.blog-mask-bottom {
	display: none;
	position: absolute;
	left: 0;
	bottom: -90%;
	width: calc(100%);
	height: 270px;
	@include breakpoint(xmedium) {
		height: 300px;
	}
	@include breakpoint(xxxlarge) {
		height: 270px;
	}
	background-color: rgba(35,31,32,0.8);
	transform: skewY(7deg);
}
.blog-mask-left {
	display: none;
	@media only screen and (max-width: 850px) {
		display: none !important;
	}
	position: absolute;
	top: 0;
	@include breakpoint(xmedium) {
		width: 60%;
		left:-10%;
	}
	left: -20%;
	width: 50%;
	height: 100%;
	background: $red;	
	background-color: rgba(35,31,32,0.8);
	transform: skewX(14deg);
}
.blog-view-4 {
	@media only screen and (max-width: 850px) {
		.blog-list-content.text-white, .blog-list-content.dark-blog-listimage {
			color: $black;
			.blog-list-headline, a {
				color: $black;
				&:after {
					background: $black;	
				}
			}
			.blog-list-text {
				color: $c-text;
			}
		}
	}
}

.blog-view-5 {
	@extend .inner-row;
	@include breakpoint(520px) {
		align-items: flex-end;
	}
	.blog-list-image-wrap {
		width: 100%;
		@include breakpoint(520px) {
			width: 46%;
		}
	}
	.blog-list-content {
		width: 100%;
		padding-left:0;
		@include breakpoint(520px) {
			width: 54%;
			padding-left: 25px;
		}
	}
	.blog-list-image {
		@include breakpoint(520px) {
			width: 100%;
		}
	}
	.blog-list-link {
		margin-top: 10px;
	}
	@media only screen and (max-width: 519px) {
		.blog-list-headline {
			margin-top: 0;			
		}
	}
}


// Blog teasers
div[class^='blog-teaser-'] {
	width: 405px;
	max-width: 100%;
	border: 1px solid $c-border;
	padding: 5px 5px 10px 85px;
	margin-bottom: 20px;
	@include breakpoint(xxmedium) {
		min-height: 230px;
		margin-bottom: 50px;
	}
	position: relative;
	background: $white;
	.social-feed-wrap {
		max-width: 300px;
		overflow: hidden;
	}
}
div.blog-teaser-wrap {
	padding: 20px 30px 10px;
	.formrow-wrap {
		margin-bottom: 20px;
	}
	&:before, &:after {
		display: none;
	}
}
.blog-teaser-h {
	font-family: $f-special;
	font-weight: 400;
	@include breakpoint(xxmedium) {
		font-size: 36px;
		&.blog-teaser-nl-h {
			margin-bottom: 35px;			
		}
	}		
}
.blog-teasers-list {
	@media only screen and (max-width: map-get($breakpoints, large) - 1) {
		@include breakpoint(medium) {
			@include flex-justify;
			div[class^='blog-teaser-'] {
				width: 49%;
			}
		}
	}	
}

.blog-submenu-list {
	margin: 0 0 30px;
	a {
		display: block;
		text-decoration: none;
		padding: 8px 0;
	}
	&-item {
		border-bottom: 1px solid $c-middlegray;
		&:hover, &.active{
			border-color: $black;
		}
	}
	li.active>a {
		font-weight: 700;
	}
	.submenu-wrap {
		margin-bottom: 20px;
		a {
			padding: 5px 0 0;			
		}
		&.submenu-open {
			display: block;
		}
	}
	.submenu-toggle {
		text-transform: uppercase;
		.text {
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.blog-detail {
	margin-left: 10px;
	margin-right: 10px;
	padding-bottom: 50px;
	margin-bottom: 60px;
	border-bottom: 1px solid $black;	
	max-width: 770px;
	@include breakpoint(xmedium) {
		margin-left: $headernav-width; //header width + 100px
		margin-right: 10px;
	}
	@include breakpoint(large) {
		margin-left: auto;
		margin-right: auto;
	}		
	&-headline {
		width: 100%;
		h1 {
			font-family: $f-special;
			position: relative;
			padding-bottom: 15px;
			margin-bottom: 15px;
			@include breakpoint(medium) {
				margin-bottom: 15px;
			}
			@include line-after;			
		}
	}
	img {
		width: 100%;
		height: auto;
		display: block;
	}
	&-date {
		margin-bottom: 30px;
		.blog-date {
			padding-left: 5px;
			margin-left: 5px;
			border-left: 1px solid $c-text;
		}
	}
	&-social {
		margin: 30px auto 0;
		display: table;
		ul {
			margin: 0;
			padding: 0;
		}
		li {
			@include inline(middle);
			margin:0 10px;
		}
		a, li.pin-share span {
			display: block;
			width: 30px !important;
			height: 30px !important;
			opacity: 0.3;
			@include transition();
			font-size: 0;
			&:hover {
				opacity: 1;
			}
		}
		li.facebook-share a {
			background: url('/assets/workfiles/project/css/icons/SVG/Facebook.svg') no-repeat center center;
			background-size: 30px !important;
		}
		li.yt-share a {
			background: url('/assets/workfiles/project/css/icons/SVG/Youtube.svg') no-repeat center center;
			background-size: 30px !important;
		}
		li.insta-share a {
			background: url('/assets/workfiles/project/css/icons/SVG/Instagram.svg') no-repeat center center;
			background-size: 30px !important;
		}
		li.pin-share span, li.pin-share a {
			background: url('/assets/workfiles/project/css/icons/SVG/Pinterest.svg') no-repeat center center;
			background-size: 30px !important;
		}
		li.pin-share a {
// 			display: none;
		}
	}
}

p.blog-image-wrapper {
	position: relative;
	display: inline-block;
	margin: 10px 0;
	cursor: pointer;
	width: 100%;
}

.blog-image-social {
	display: none;
	margin: 0;
	position:absolute;
	bottom:15px;
	right:15px; 
	z-index: 1;
	ul {
		margin-bottom: 0;		
	}
	li {
		@include inline(middle);
		margin-left: 20px;
	}
	li a, li.pin-share span {
		display: block;
		width:40px !important; 
		height:40px !important; 
		opacity: 0.7;
		@include transition();
		font-size: 0;
		&:hover {
			opacity: 1;
		}
	}
	li.facebook-share a {
		background: url('/assets/workfiles/project/css/icons/SVG/Facebook-white.svg') no-repeat center center;
		background-size: 40px !important;
	}
	li.pin-share span, li.pin-share a {
		background: url('/assets/workfiles/project/css/icons/SVG/Pinterest-white.svg') no-repeat center center;
		background-size: 40px !important;
	}
	li.pin-share a {
// 		display: none;
	}
}

.similar-posts-list {
	max-width: 1100px;
	margin-left: 10px;
	margin-right: 10px;	
	@extend .inner-row;
	justify-content: center;
	@include breakpoint(xmedium) {
		.blog-list-item {
			margin-left: 1%;
			margin-right: 1%;
		}
		margin-left: $headernav-width; //header width + 100px
		margin-right: 10px;
	}
	@include breakpoint(large) {
		margin-left: $headernav-width;
		margin-right: auto;
	}
}

.add-new-comment-wrapper, .root-comment {
	max-width: 1050px;
	margin-left: 10px;
	margin-right: 10px;	
	@include breakpoint(xmedium) {
		margin-left: $headernav-width; //header width + 100px
		margin-right: 10px;
	}
	@include breakpoint(large) {
		margin-left: $headernav-width;
		margin-right: auto;
	}
	h4 {
		font-family: $f-special;
		position: relative;
		padding-bottom: 15px;
		margin-bottom: 15px;
		font-size: 30px;
		text-transform: uppercase;
		@include breakpoint(medium) {
			margin-bottom: 40px;
		}
		@include line-after;
	}
}
.blog-teaserlist-headline {
	@extend .indent-content;
	padding-left: 10px;
	h2 {
		font-family: $f-special;
		position: relative;
		padding-bottom: 15px;
		margin-bottom: 15px;
		font-size: 30px;
		text-transform: uppercase;
		@include breakpoint(medium) {
			margin-bottom: 40px;
		}
		@include line-after;
	}
}
.add-new-comment-wrapper {
	padding-top: 50px;
	border-top: 1px solid $black;
	.newComment {
		margin-bottom: 80px;
	}
}
.child-comment {
	&-form {
	    display: none;
	    margin-bottom: 30px;
    }
	&-cancel {
		display: none;
		.active & {
			display: block;
		}
	}
	&-trigger {
		color: $pspink;
		float: right;
		&:hover, &:active, &:visited {
			color: $pspink;	
		}
	}
}

.comment-message {
	font-style: italic;
	margin-bottom: 5px;
	&-data {
		font-family: $f-special;
		font-size: 16px;
	}
	&-date {
		font-size: 14px;
	}
	&-wrapper {
		background-color: $c-lightergray;
		border: 1px solid $c-border;
		padding: 5px 20px 20px;	
		@include breakpoint(medium) {
			padding: 5px 35px 20px;	
		}
		margin-bottom: 30px;	
	}
}