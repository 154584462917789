.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.ic-name {
  font-size: 32px;
  color: red;
}
========================================== */
.ic-account {
  width: 14px;
  height: 14px;
}

.ic-wishlist {
  width: 14px;
  height: 14px;
}

.ic-arrow-cart {
  width: 1.466796875em;
}

.ic-arrow {
  width: 15px;
  height: 14px;
}

.ic-arrow-cta {
  width: 38px;
  height: 7px;
}

.ic-calculator {
  width: 0.9375em;
}

.ic-cart {
  width: 12px;
  height: 16px;
}

.ic-delivery {
  width: 16px;
  height: 12px;
}

.ic-info {
  width: 0.2587890625em;
}

.ic-minus-toggle {
  width: 14em;
}

.ic-playment {
  width: 15px;
  height: 16px;
}

.ic-return {
  width: 16px;
  height: 16px;
}

.ic-premium {
  width: 1.0419921875em;
}

.ic-quotes {
  width: 39px;
  height: 32px;
}

.ic-required {
  width: 1.05859375em;
}

.ic-search-store {
  width: 16px;
  height: 15px;
}

.ic-select {
  width: 1.7138671875em;
}

.ic-share {
  width: 0.7998046875em;
}

.ic-trash {
  width: 0.8125em;
}

.ic-tutorial {
  width: 7px;
  height: 12px;
}

.ic-Facebook, .ic-Instagram, .ic-Pinterest {
  width: 18px;
  height: 18px;
}

.ic-Twitter {
  width: 18px;
  height: 15px;
}

.ic-Youtube {
  width: 18px;
  height: 13px;
}

.ic-zoom {
  width: 14px;
  height: 14px;
}

.ic-quote-fill {
  width: 27px;
  height: 22px;
}

.ic-plus-toggle {
  width: 14px;
  height: 14px;
}

.ic-close {
  width: 15px;
  height: 15px;
}

.ic-arrow-20 {
  width: 20px;
  height: 11px;
}

.ic-arrow-40 {
  width: 40px;
  height: 22px;
}

.ic-arrow-60 {
  width: 60px;
  height: 30px;
}

.ic-heart {
  width: 27px;
  height: 24px;
}

.ic-heart-filled {
  width: 27px;
  height: 24px;
}

.ic-heart-top {
  width: 20px;
  height: 14px;

  @include from($desktop) {
    width: 19px;
    height: 17px;
  }
}

.product-detail {
  .ic-cart-top {
    width: 19px;
    height: 21px;
  }
}

.ic-cart-top {
  width: 19px;
  height: 21px;

  @include from($desktop) {
    width: 18px;
    height: 17px;
  }

}

.ic-account-top {
  width: 22px;
  height: 22px;

  @include from($desktop) {
    width: 17px;
    height: 17px;
  }
}

.ic-search-top {
  width: 19px;
  height: 20px;

  @include from($desktop) {
    width: 17px;
    height: 18px;
  }
}

@media only screen and (max-width: map-get($breakpoints, xxmedium)) {
  .header-links-item {
    .ic-heart-top,
    .ic-cart-top,
    .ic-account-top,
    .ic-search-top {
      height: 22px;
      filter: invert(90%) sepia(100%) saturate(0%) hue-rotate(322deg) brightness(104%) contrast(104%);
    }

    a.open {
      .ic-heart-top,
      .ic-cart-top,
      .ic-account-top,
      .ic-search-top {
        filter: none;
      }
    }
  }
}