.btn {
	display: inline-block;
	background: $black;
	color: $white;
	font-size: inherit;
	text-align: center;
	padding: 8px 25px 9px;
	@include breakpoint(991px) {
		padding: 8px 63px 9px;
	}
	border: none;
	border-radius: 7px;
	appearance: none;
	font-weight: 300;
	@include transition;	
	cursor: pointer;
	text-decoration: none;
	&:hover {
		background: lighten($black,20%); 
		color: $white;
	}
	&:visited,
	&:active {
		color: $white;
	}
}
.btn-arrow {
	@extend .btn;
	border: none;
	padding: 3px 25px 4px;
}
.btn-white {
	@extend .btn;
	background: $white;
	border: 1px solid $black;
	color: $black;
	&:hover {
		background: darken($white,10%); 		
		color: $black;
	}
	&:visited,
	&:active {
		color: $black;
	}
}
.btn-arrow-white {
	@extend .btn-white;
	border: none;
	padding: 3px 25px 4px;
}
.btn-arrow-transp {
	@extend .btn;
	background: transparent;
	border: 1px solid $white;
	padding: 3px 25px 4px;
	&:hover {
		background: darken($white,10%); 		
		color: $black;
	}
	&:visited,
	&:active {
		color: $black;
	}
}
.btn-gray {
	@extend .btn;
	text-transform: uppercase;
	background: $c-gray;
	padding: 5px 43px 5px;	
}
.btn-large,
input[type=submit].btn-large {
	@extend .btn;
	font-size: 16px;
	text-transform: uppercase;
	padding: 18px 35px 19px;
	@include breakpoint(991px) {
		padding: 18px 48px 19px;
	}
	.ic-cart {
		width: 19px;
		height: 19px;
		vertical-align: text-top;
		margin-right: 15px;
	}
	.ic-wishlist {
		width: 19px;
		height: 19px;
		vertical-align: text-top;
		margin-right: 15px;
	}
}
.delete-link-gray {
	@include transition;
	display: inline-block;
	width: 10px;
	height: 10px;	
	background: url('/assets/workfiles/project/css/icons/SVG/close-gray.svg') no-repeat center center;
	background-size: 10px;
	text-decoration: none;
}
.btn-black-border {
	@include transition;
	display: inline-block;
	border: 3px solid $black;
	padding: 5px 15px;
	appearance: none;
	cursor: pointer;
	text-decoration: none;
	.icon {
		margin-right: 0;		
		margin-left: 10px;
	}
	&:hover {
		background: $black; 		
		color: $white;
	}
	&:visited,
	&:active {
		color: $black;
		&:hover {
			color: $white;	
		}
	}
}
.btn-white-border {
	color:$white;
	@include transition;
	display: inline-block;
	border: 3px solid $white;
	padding: 5px 15px;
	appearance: none;
	cursor: pointer;
	text-decoration: none;
	.icon {
		margin-right: 0;
		margin-left: 10px;
	}
	&:hover {
		text-decoration: none;
		border-color: $black;
		color: $black;
	}
	&:visited,
	&:active {
		color: $white;
		&:hover {
			color: $black;
		}
	}
}
.btn-no-border {
	@include transition;
	display: inline-block;
	padding: 5px 15px 5px 0;
	appearance: none;
	cursor: pointer;
	text-decoration: none;
	color: $black;
	.icon {
		@include transition;
		margin-right: 0;		
		margin-left: 5px;
		vertical-align: middle;
	}
	&:hover {
		.icon {
			margin-left: 10px;
		}
	}
}
.btn-middle {
	@extend .btn;
	padding: 5px 50px;
	min-width: 230px;
	text-transform: uppercase;
	@include breakpoint(xxmedium) {
		padding: 10px 60px;
		min-width: 275px;
		font-size: 14px;
	}
}
.btn-small, input[type=submit].btn-small {
	@extend .btn;
	padding: 8px 15px 9px;
	@include breakpoint(991px) {
		padding: 8px 42px 9px;
	}
}
.btn-fullwidth {
	@extend .btn;
	text-transform: uppercase;
	padding: 3px 63px 3px;
	width: 100%;
	max-width: 100%;
}
.detail-link {
	font-size: 11px;
	text-decoration: none;
	@include border-transform($black, 1, 1.1);
	.icon {
		width: 14px;
		height: 10px;
		margin-left: 10px;
	}
}
.btn-search {
	display: block;
	width: 40px;
	height: 35px;
	@include breakpoint(xmedium) {
		height: 40px;
	}
	background: $white;
	color: $black;
	text-align: center;
	border: none;
	border-radius: 0px;
	appearance: none;
	cursor: pointer;
	text-decoration: none;
	.icon {
		@include breakpoint(xmedium) {
			width: 20px;
			height: 20px;
		}
	}
}