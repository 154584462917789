.ce_404 {
  &__wrapper {
    @include intend-fullwidth;
    text-align: center;
  }

  &__container {
    display: grid;

  }

  &__inner {

  }

  &__headline {
    font-family: $font-headline;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 50px;
    @include breakpoint(xmedium down) {
      padding: 0 20px;
      line-height: 46px;
    }
  }

  &__picture {
    width: 100%;
    height: auto;
  }

  &__divider {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 134px;
    height: 4px;
    background-color: $black;
  }

  &__search-form {
    width: 550px;
    position: relative;
    margin: 0 auto;

    // @include breakpoint(xmedium) ---> max 768
    @include breakpoint(xmedium down) {
      padding: 0 10px;
      width: 375px;
      margin-bottom: 50px !important;
    }

    // @include breakpoint(xxsmall) ---> max 375
    @include breakpoint(xxsmall down) {
      width: 315px;
      margin-bottom: 50px !important;
    }
  }

  &__search-form-input {
    padding: 17px 0;
    width: 100%;
    font-family: $f-krub;
    font-size: 20px;
    color: $c-gray;
    border: none;
    border-bottom: 1px solid $c-gray;
    font-weight: 300;
    border-radius: unset !important;
  }

  &__search-icon {
    color: $black;
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      height: 18px;
    }

    background: transparent;
    border: none;
  }
}

